import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTag} from '@fortawesome/free-solid-svg-icons';

class ButtonDropdownComponent extends React.Component{
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);

        this.state = {
          dropdownOpen: false
        };
    }

    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    close(){
      this.setState({
        dropdownOpen: false
      });
    }

    render(){

        return(
            <Dropdown 
              direction={this.props.direction ? this.props.direction : null}
              className={this.props.className ? this.props.className : null}
              style={this.props.style}
              isOpen={this.state.dropdownOpen}
              toggle={this.props.btnName == "Columns " ? ()=>{ } : this.toggle}
              disabled={this.props.disabled}
            >
              <DropdownToggle onClick={this.toggle} caret={!this.props.btnIcon} color='primary'  style={{ marginLeft : "1rem"}}>
              {this.props.btnName ? this.props.btnName : null}
              {this.props.btnIcon == "tag" ? <FontAwesomeIcon icon={faTag}/> : null}
              </DropdownToggle>

              {this.props.btnName == "Columns " ? <DropdownMenu>

                {this.props.children}
                <DropdownItem onClick={this.close} className="dropdownClose">
                  Close <FontAwesomeIcon icon={faTimesCircle}/>
                </DropdownItem>
              </DropdownMenu> : <DropdownMenu onClick={this.toggle}>
                {this.props.children}
              </DropdownMenu> }
            </Dropdown>
        );
    }

}

export default ButtonDropdownComponent;
