import axios from 'axios';
import { getAuthHeaders } from '../../utils/index';
import { logout } from '../../utils/httpErrors';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

const API_URL = pathname + 'api/v1';

export function fetchDirectoryEditors(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_DIRECTORY_EDITORS" });
      var url = API_URL + '/admin/directory-editors';
      axios({
        url,
        method: 'get',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({
          type: "FETCH_DIRECTORY_EDITORS_FULFILLED", payload: response.data
        })
        resolve()
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "FETCH_DIRECTORY_EDITORS_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function updateDirectoryEditors(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "UPDATE_DIRECTORY_EDITORS" });
      var url = API_URL + '/admin/directory-editors';
      axios({
        url,
        data,
        method: 'put',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "UPDATE_DIRECTORY_EDITORS_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        dispatch({ type: "UPDATE_DIRECTORY_EDITORS_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
  }
}
