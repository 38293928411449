var logger = {};

// Using a similar interface to Winston logger
// Winston is used for the backend
logger.error   = console.log;
logger.warn    = console.log;
logger.info    = console.log;
logger.verbose = console.log;
logger.debug   = console.log;
logger.silly   = console.log;

export default logger;
