import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { matchPath } from 'react-router';
import CompanyListView from '../CompanyView/CompanyListView';
import { withRouter } from 'react-router-dom';
import { getLoggedUser } from '../../utils';
import { getURLPrefix } from '../../utils/baseURLService.js';

import { fetchPeople, fetchCompanies } from '../ODD/action';

import CompanyDetailView from './CompanyDetailView';
import PanedViewport from '../PanedViewport/PanedViewport';

class CompanyView extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      companies : props.ldap.companies,
      people: props.ldap.people,
      loadingOverlayCb : props.loadingOverlayCb,
      textLoadingOverlay : props.textLoadingOverlay,
      current_user: getLoggedUser()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { companies } = nextProps.ldap;
    if (companies && companies !== prevState.companies){
      return { companies: companies }
    }
    return null;
  }

  componentDidMount(){
    this.props.onFetchCompanies();
  }
  render() {

    const { location  } = this.props;
    var pathname = getURLPrefix().pathname;

    var detailsMatch = matchPath(location.pathname, {
      "path": pathname + "company/details/:id",
      "exact": true,
      "strict": true
    });
    var createMatch = matchPath(location.pathname, {
      "path": pathname + "company/new",
      "exact": true,
      "strict": true
    });

    var editMatch = matchPath(location.pathname, {
      "path": pathname + "company/edit/:id",
      "exact": true,
      "strict": true
    });

    var deleteMatch = matchPath(location.pathname, {
      "path": pathname + "company/delete/:id",
      "exact": true,
      "strict": true
    });

    var current_user = this.state.current_user;

    var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

    var isDetails = (detailsMatch !== null);
    var isEdit = (editMatch !== null && isAllowedToEdit);
    var isDelete = (deleteMatch !== null && isAllowedToEdit);
    var isCreate = (createMatch !== null && isAllowedToEdit);

    var requestedCompanyId = "";

    var company = {};

    if (isDetails) {
      requestedCompanyId = decodeURIComponent(detailsMatch.params.id);
      company = this.state.companies.filter(company => company.id == requestedCompanyId)[0];
    }
    else if (isEdit) {
      requestedCompanyId = decodeURIComponent(editMatch.params.id);
      company = this.state.companies.filter(company => company.id == requestedCompanyId)[0];
    }


    var modeView = isDetails || isEdit || isDelete ? 'compacted' : 'extended';
    var children = [<CompanyListView key='companyList'
                                     modeView={modeView}
                                     requestedCompanyId={requestedCompanyId}
                                     companies={this.state.companies}
                                     isEditModalOpen={isCreate}
                                     loadingOverlayCb={this.state.loadingOverlayCb}
                                     textLoadingOverlay={this.state.textLoadingOverlay}/>];

    if (isDetails) {
      children.push(<CompanyDetailView  key='companyDetail' company={company} isEditModalOpen={false}/>);
    }
    else if (isEdit) {
      children.push(<CompanyDetailView key={'edit-' + editMatch.params.id}
                                       company={company}
                                       isEditModalOpen={true}
                                       companies={this.state.companies}
                                       loadingOverlayCb={this.state.loadingOverlayCb}
                                       textLoadingOverlay={this.state.textLoadingOverlay}/>);
    }
    else if (isDelete){
      var requestedCompanyId = decodeURIComponent(deleteMatch.params.id);
      var company = this.state.companies.filter(company => company.id == requestedCompanyId)[0];
      children.push(<CompanyDetailView key={'delete-' + deleteMatch.params.id}
                                       company={company}
                                       isDeleteModalOpen={true}
                                       loadingOverlayCb={this.state.loadingOverlayCb}
                                       textLoadingOverlay={this.state.textLoadingOverlay}/>);
    }

    return (
      <div>
        <PanedViewport>
          {children}
        </PanedViewport>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onFetchPeople: () => dispatch(fetchPeople()),
  onFetchCompanies: () => dispatch(fetchCompanies())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyView);
