import React from 'react';
import {
  Input,
  FormText,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BoxDeleteComponent from '../DeleteSubComponents/BoxDeleteComponent';

class DeleteConfirmationView extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state= {
      inputValue : '',
      groupIsMatch: false
    }

  }

  componentDidMount(){
    this.setState({
      inputValue: '',
      groupIsMatch: false
    });
  }

  handleChange(event){
    var value = event.target.value;
    var groupIsMatch = this.groupIsMatch(this.props.groupId, value);
    this.setState({
      inputValue : value,
      groupIsMatch : groupIsMatch
    });

    if(!this.props.orphanContacts && groupIsMatch){
      this.props.setGroupIdConfirmed(value);
    }
  }

  groupIsMatch(groupId, value){

    value = value ? value : '';

    return groupId === value;

  }


  render() {

    var orphanContacts = this.props.orphanContacts;

    return (
      <div>
        <ModalBody>
          <BoxDeleteComponent boxModule={this.props.boxModule}/>
          {orphanContacts ? <h2>Yes delete {orphanContacts.length} orphan contacts<br/>and {this.props.groupId} group.</h2> : <h4>To delete this group please enter its abbreviation<br/>({this.props.groupId}) below</h4>}
          {orphanContacts ? <FormText>Please enter its abbreviation ({this.props.groupId})</FormText> : null}
          <Input autoComplete="noAutoComplete"
                   type="text"
                   name="groupToRemove"
                   valid={this.state.groupIsMatch}
                   value={this.state.inputValue}
                   onPaste={e => e.preventDefault()}
                   onChange={this.handleChange}/>
          <FormText><FontAwesomeIcon icon={faInfoCircle}/> Copy / paste is not allowed</FormText>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}
          <Button color="danger" disabled={!this.state.groupIsMatch} onClick={this.props.deleteGroupAndOrphans}>Delete</Button>
        </ModalFooter>
      </div>
    );
  }
}

export default DeleteConfirmationView;
