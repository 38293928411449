import React from 'react';

import { compose } from 'redux';
import { connect } from "react-redux";
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';
import { prepareDataFromApi, directoryEditorsGroup } from '../../utils';

import {
  fetchDirectoryEditors
} from './action';

import EditorsDetail from './EditorsDetail';
import EditorsEditView from './EditorsEditView';
import {
  Spinner
} from 'reactstrap';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class DirectotyEditorsView extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      people: props.people,
      directoryEditors: props.admin.directoryEditors,
      groups: props.groups
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { directoryEditors } = nextProps.admin;

    if (Object.keys(directoryEditors) && Object.keys(directoryEditors).length !== 0 && directoryEditors !== prevState.directoryEditors){
      return { directoryEditors: directoryEditors }
    }
    return null;
  }



isEmpty(obj) {

  var hasOwnProperty = Object.prototype.hasOwnProperty;

    if (obj == null) return true;

    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    if (typeof obj !== "object") return true;

    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

  componentDidMount(){
    this.props.onFetchDirectoryEditors();
  }

  render() {

    var directoryEditors = this.state.directoryEditors;

    if (this.isEmpty(directoryEditors)) {
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

    const { location } = this.props;
    var detailsMatch = matchPath(location.pathname, {
      "path": pathname + "directoryEditors/",
      "exact": true,
      "strict": true
    });

    var editMatch = matchPath(location.pathname, {
      "path": pathname + "directoryEditors/edit",
      "exact": true,
      "strict": true
    });

    var isDetails = (detailsMatch !== null && detailsMatch.params);
    var isEdit = (editMatch !== null && editMatch.params);

    var children = <EditorsDetail directoryEditors={directoryEditors}/>;

    if(isDetails)
      children = <EditorsDetail directoryEditors={directoryEditors}/>;

    if(isEdit){
      children = <EditorsEditView group={directoryEditors}
                                  people={this.state.people}
                                  groups={this.state.groups}
                                  loadingOverlayCb={this.props.loadingOverlayCb}
                                  textLoadingOverlay={this.props.textLoadingOverlay}/>;
    }

    return children ;
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user,
  admin: state.admin
});

const mapDispatchToProps = dispatch => ({
  onFetchDirectoryEditors: () => dispatch(fetchDirectoryEditors())
});

export default compose(
  withRouter, connect(mapStateToProps, mapDispatchToProps)
)(DirectotyEditorsView);
