import React from 'react';
import { ListGroup, Button, ListGroupItem } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';


import CollapseItem from '../CollapseComponent/CollapseItem';


class CollapseComponent extends React.Component {
  constructor(props){
    super(props);

    this.displayAllItems = this.displayAllItems.bind(this);

    this.state = {
      isShowAll : false,
      collapseHeadTitle : props.collapseHeadTitle,
      items : []
    }
  }

    static getDerivedStateFromProps(nextProps, prevState) {

      const { items } = nextProps;

      if (items && items !== prevState.items) {
        return { items : items }
      }

      return null;
    }

  displayAllItems(){
    this.setState({
      isShowAll : !this.state.isShowAll
    });
  }

  generateItems(items, showAll){
    var itemsGenerated = [];
    var itemTodisplay = this.props.itemTodisplay ? this.props.itemTodisplay : 3 ;

    items.forEach((item, index) => {

      if(showAll){
        itemsGenerated.push(<CollapseItem key={"item-" + index} item={item}/>);
      }
      else if(!showAll && index < itemTodisplay) {
        itemsGenerated.push(<CollapseItem key={"item-" + index} item={item}/>);
      }

    });

    if(showAll || items.length == 0){
      itemsGenerated.push(<ListGroupItem key="item-log" className="collapseItem-notification">Previous logs are not available.</ListGroupItem>);
    }

    return itemsGenerated;
  }

  render(){

    var items = this.generateItems(this.state.items, this.state.isShowAll);

    var nomberOfItems = this.state.items.length;

    var itemTodisplay = this.props.itemTodisplay ? this.props.itemTodisplay : 3 ;

    return(
      <div className="collapseComponent" style={this.props.style}>
        <div className="collapseComponentHead">
          {this.state.collapseHeadTitle ? <h4>{this.state.collapseHeadTitle}</h4> : null}
          {nomberOfItems > itemTodisplay ? <Button onClick={this.displayAllItems} color="primary">
          {this.state.isShowAll ? "Hide logs " : "More logs "}
          <FontAwesomeIcon icon={!this.state.isShowAll ? faAngleDown : faAngleUp} />
          </Button> : null}
        </div>
        <ListGroup className="w-100">
          {items}
        </ListGroup>
      </div>
    );
  }

}

export default CollapseComponent;
