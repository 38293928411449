import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import { toast } from 'react-toastify';

import {
  fetchGroups,
  fetchPeople,
  fetchCompanies
} from '../ODD/action';

import {
  updateDirectoryEditors,
  fetchDirectoryEditors
} from './action';

import { Link } from 'react-router-dom';

import {
  Container,
  Form,
  Spinner
 } from 'reactstrap';

import CloseComponent from '../SubComponent/CloseComponent';
import GroupTitleHeader from '../SubComponent/GroupTitleHeader';

import MembershipsWidget from '../MembershipsWidget/MembershipsWidget';
import Header from '../GroupEditView/SubComponents/Header';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class EditorsEditView extends React.Component {
  constructor(props){
    super(props);
    this.cancel = this.cancel.bind(this);
    this.unselectPersonAction = this.unselectPersonAction.bind(this);
    this.unselectPersonAction = this.unselectPersonAction.bind(this);
    this.selectPersonAction = this.selectPersonAction.bind(this);


    this.state = {
      people: props.ldap.people,
      groups: props.ldap.groups,
      group: props.group,
      formIsUsed: false,
      peopleInGroup: []
    }
  }

  componentDidMount() {
    if (this.props.ldap.people && this.props.ldap.people.length > 0 &&
      this.props.ldap.groups && this.props.ldap.groups.length > 0 ) {
      const groupId  = this.props.groupId;
      var groupSelected = this.props.group;

      var people = this.preparePeopleData(this.props.ldap.people, groupSelected);

      this.setState({
        people: people,
        peopleInGroup : this.getPeopleSelected(people),
        group : {
          name : groupSelected.name,
          members : groupSelected.members
        },
        groups : this.props.ldap.groups
      });
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const { group } = nextProps;
    const { people } = nextProps.ldap;

    if (group && people && people.length > 0) {

      group.members = group.members.map(contact => {

        if(typeof(contact) === "string"){
          contact = people.filter(person => person.id === contact)[0];
        }
        return contact;
      });

      return { group: group }
    }
    return null;
  }

  preparePeopleData(people, groupToEdit){
   var peopleFormatted = people.map(person => {
       person.fullname = person.lastname + " " + person.firstname;
       person.newContactSelected = false;

       person.isSelected = groupToEdit.members.filter(personInGroup => personInGroup.id == person.id).length > 0;

       return person;
     });

    return peopleFormatted;
  }

  formAction(event){

    var groupId = this.props.group.id;
    var members = this.state.peopleInGroup.map(contact => contact.id);

    var dataToSend = {
      id : groupId,
      data : { members : members }
    };

    this.props.textLoadingOverlay("Updating " + groupId + "...");
    this.props.loadingOverlayCb(true);

    this.props.onUpdateDirectoryEditors(dataToSend)
    .then(this.props.onFetchDirectoryEditors)
    .then(this.props.onFetchPeople)
    .then(this.props.onFetchGroups)
    .then(() => {
      toast( "Directory editors have been saved !", {type:"success"});

      this.props.history.push(pathname + 'directoryEditors');
    })
    .catch(err => toast(err, {type:"error",autoClose: false}) )
    .finally(() => {
      this.props.loadingOverlayCb(false);
    });
    if(event){
      event.preventDefault();
    }

  }

  cancel() {
    this.props.history.goBack();
  }

  unselectPersonAction(contact){
    contact.isSelected = false;
    contact.newContactSelected = false;

    var newState = { formIsUsed: true };
    var people = this.updatePeopleSelected(this.props.people, contact);

    newState.peopleInGroup = this.getPeopleSelected(people);

    this.setState( newState );
  }

  updatePeopleSelected(people, contactSelected){
    people.forEach((contact)=> {
      if(contact.id == contactSelected.id){
        contact.isSelected = contactSelected.isSelected ;
        contact.newContactSelected = contactSelected.newContactSelected;
      }
    });
    return people;
  }

  selectPersonAction(contact){

    contact.isSelected = true;
    contact.newContactSelected = true;

    var newState = { formIsUsed: true };
    var people = this.updatePeopleSelected(this.props.people, contact);

    newState.peopleInGroup = this.getPeopleSelected(people);

    this.setState( newState );

    setTimeout(() => {
      var elmnt = document.getElementById(contact.id);
      elmnt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }, 100);

  }

  getPeopleSelected(people){
    return people.filter( function (person) {
      return person.isSelected;
    });
  }

  render() {

    if ( !this.props.group ) {
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

    var group = this.props.group;

    var formCanBeSubmit = this.state.formIsUsed && this.state.peopleInGroup.length > 0;

    return (
      <Container fluid={true} className="directoryEditorsContainer">
        <Form onSubmit={(event) => this.formAction(event)} className="h-100 m-0 overflow-auto">
           <Header mode={"EDIT"}
                   groupName={group.name}
                   formCanBeSubmit={formCanBeSubmit}
                   formIsUsed={this.state.formIsUsed}
                   saveAction={(event) => this.formAction(event)}
                   groupId={group.id}
                   groupCanNotBeDelete={true}
                   closeComponentTarget={pathname + "contact"}
                   closeCb={this.cancel}/>

          <MembershipsWidget people={this.state.people}
                             groups={this.state.groups}
                             companies={this.props.ldap.companies}
                             group={group}
                             peopleInGroup={this.state.peopleInGroup}
                             action={this.unselectPersonAction}
                             selectGroupMembersAction={this.selectGroupMembersAction}
                             personAction={this.selectPersonAction}
                             displayGroupStaff
                             />
        </Form>

      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onFetchGroups: () => dispatch(fetchGroups()),
  onFetchPeople: () => dispatch(fetchPeople()),
  onFetchDirectoryEditors: () => dispatch(fetchDirectoryEditors()),
  onUpdateDirectoryEditors: (data) => dispatch(updateDirectoryEditors(data))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditorsEditView);
