import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Timer from './Timer';

class ModalKeepAlive extends React.Component {
  constructor(props) {
    super(props);
    this.disconnect = this.disconnect.bind(this);

    this.state = {
      displayTimer: false,
      disconnect: false
    }
  }
  componentDidMount(){
    this.setState({
      displayTimer: !this.props.disconnectedSession
    });
  }

  disconnect(){
    this.setState({
      displayTimer: false,
      disconnect: true
    });
    this.props.disconnect();
  }

  componentWillUnmount(){
    this.setState({
      displayTimer: false,
      disconnect: false
    });
  }

  render() {
    var modalHeader = this.state.displayTimer
           ? (<ModalHeader className="bg-orange" ><FontAwesomeIcon icon={faExclamationTriangle}/>You will be disconnected in</ModalHeader>)
           : (<ModalHeader className="bg-orange" ></ModalHeader>);

    return (
      <Modal isOpen={this.props.isOpen} className="panelModal">
        {modalHeader}
        <ModalBody>
          {this.state.displayTimer
            ? <Timer expiratedTimestamp={this.props.expiratedTimestamp}
                     secBeforeModalWarningExpiredSession={this.props.secBeforeModalWarningExpiredSession}
                     disconnect={this.disconnect}/>
            : <>
                <h2>Your session has expired.</h2>
                <p className="p-0">
                  <small>Due to lack of activity and in order to secure your data,<br/>you have been automatically disconnected.</small>
                  <strong>You can now close this window.</strong></p>
               </>
          }
          {this.props.isLoading && this.state.displayTimer
            ? <p className="text-center p-2">Loading</p>
            : null
          }
        </ModalBody>
        <ModalFooter>
          <Button outline color="secondary" disabled={this.state.disconnect} onClick={this.disconnect}>Disconnect</Button>
          <Button 
            color="success" 
            disabled={this.state.disconnect || this.props.isLoading || !this.props.isOpen} 
            onClick={this.props.restartSession}>
            Continue session
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalKeepAlive;
