import React from 'react';

import { DropdownItem, Button } from 'reactstrap';
import ButtonDropdownComponent from '../ButtonDropdownComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { faTag } from '@fortawesome/free-solid-svg-icons';

class LabelWidget extends React.Component {
  constructor(props){
    super(props);

  }

  generateItems(items){
    var itemsGenerated = [];
    items.forEach((item, index) => {

      itemsGenerated.push(<DropdownItem key={index}
                                        className="labelItem"
                                        style={{height: item.feedBack ? "70px" : "50px"}}>
                                        <span className={!item.labelDisabled ? "labelItemAdd" : "labelItemAdd disabled" }
                                              onClick={!item.labelDisabled ? () => item.addAction(this.props.currentItem, item.name) : null}
                                              style={{backgroundColor: item.labelColor }}>{item.name}</span>
                                        <span className="labelItemRemove" onClick={() => item.removeAction(this.props.currentItem, item.name)}>
                                          <FontAwesomeIcon icon={faTimes}/>
                                        </span>
                                        {item.feedBack ? <small className="form-text text-muted colorRed">{item.feedBack}</small> : null}
                          </DropdownItem>);
    });
    return itemsGenerated;
  }



  render(){
    var items = this.generateItems(this.props.items);

    return(
      <div className="ml-0" title="Click this tag to promote the user as chair or vice-chair.">
        <ButtonDropdownComponent direction="right" className="labelWidget" btnIcon="tag">
          {items}
        </ButtonDropdownComponent>
      </div>
    );
  }
}

export default LabelWidget;
