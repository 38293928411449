import React from 'react';
import { ModalHeader} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const BoxModalHeaderComponent = () => {

  return (
      <ModalHeader className="bg-success p-4 justify-content-center font-size-40 text-white">
        LPO Box Sync <FontAwesomeIcon icon={faSync}/>
      </ModalHeader>
  );
}

export default BoxModalHeaderComponent;
