export function logout(err){
  if ((typeof err === 'string' && err.includes('401')) || (err && err.code === 401) ||
    (err && err.message && err.message.includes('401'))) {
    sessionStorage.removeItem('efpia-ldap-user');
    window.location.reload()
  } else if ( (typeof err === 'string' && (err.includes('504') || err.includes('Network Error'))) ||
              (typeof err === 'string' && (err.includes('503')) || err.message && (err.message.includes('503'))   ) ||
             (err.message && (err.message.includes('504') || err.message.includes('Network Error'))) ) {
    setTimeout(() => {
      // out of the react context scope... => using simple alert()
      alert("The server is down, contact the administrator.");
    }, 3000);
    setTimeout(() => {
      // The server is stopped so let's remove the token and clear the javascript code loaded in the browser
      sessionStorage.removeItem('efpia-ldap-user');
      window.location.reload()
    }, 10000);
  }
}
