import React from 'react';
import { matchAlphaNum, matchInvalidCharsWhithPonctuation } from "../../../SubComponent/fieldsValidators";
import { getOverridedCountries } from "../../../SubComponent/Country-List/countriesOverrided";
const MAX_CHARS = 50;
const TEXTAREA_MAX_CHARS = 200;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';

class ContactEditAddress extends React.Component{
  constructor(props){
    super(props);
    this.onfieldsChange = this.onfieldsChange.bind(this);

    this.state = {
      departmentNumber: "",
      postalCode : "",
      city : "",
      country : "",
      postalAddress : ""
    }
  }

  componentDidMount(){
    if(this.props.mode === "EDIT" || this.props.mode === "DUPLICATE"){
      this.props.boxCanBeSubmit(true);
      const {
        departmentNumber,
        postalCode,
        city,
        country,
        postalAddress
      } = this.props;

      this.setState({
        departmentNumber: departmentNumber,
        postalCode: postalCode,
        city : city,
        country : country,
        postalAddress : postalAddress
      })
    }
  }

  onfieldsChange(event){
    const {
      handleChange,
      boxCanBeSubmit
    } = this.props;

    const {
      departmentNumber,
      postalCode,
      city,
      country,
      postalAddress
    } = this.state;


    var departmentNumberValue = event.target.name === "departmentNumber" ? event.target.value : departmentNumber;
    var postalCodeValue = event.target.name === "postalCode" ? event.target.value : postalCode;
    var postalAddressValue = event.target.name === "postalAddress" ? event.target.value : postalAddress;
    var cityValue = event.target.name === "city" ? event.target.value : city;
    var countryValue = event.target.name === "country" ? event.target.value : country;

    var departmentNumberIsValid = !matchInvalidCharsWhithPonctuation(departmentNumberValue) && !(departmentNumberValue.length > MAX_CHARS);
    var postalAddressIsValid = !matchInvalidCharsWhithPonctuation(postalAddressValue) && !(postalAddressValue.length > TEXTAREA_MAX_CHARS);
    var postalCodeIsValid = !matchAlphaNum(postalCodeValue) && !(postalCodeValue.length > MAX_CHARS);
    var cityIsValid = !matchAlphaNum(cityValue) && !(cityValue.length > MAX_CHARS);


    this.setState({
      departmentNumber: departmentNumberValue,
      postalCode: postalCodeValue,
      city: cityValue,
      country : countryValue,
      postalAddress : postalAddressValue
    })

    var contactAddressIsValid = countryValue.length !== 0 && departmentNumberIsValid && postalAddressIsValid && postalCodeIsValid && cityIsValid;
    boxCanBeSubmit(contactAddressIsValid);

    event.preventDefault();
    handleChange(event);

  }

  render(){

    const {
      formIsUsed
    } = this.props;

    const {
      departmentNumber,
      postalCode,
      city,
      country,
      postalAddress
    } = this.state;

    const countryList = getOverridedCountries();
    var optionCountryItems = countryList.map((item) =>
       <option key={item.code} value={item.code}>{item.name}</option>
     );
    optionCountryItems.unshift(<option key="country-selector" value="">Select a country...</option>)

    var addressPlaceholder = "Address line 1\nAddress line 2".replace(/\\n/g, '\n');

    var departmentNumberIsInvalid = matchInvalidCharsWhithPonctuation(departmentNumber) ?  matchInvalidCharsWhithPonctuation(departmentNumber).length > 0 : false;
    var cityIsInvalid = matchAlphaNum(city) ?  matchAlphaNum(city).length > 0 : false;
    var postalAddressIsInvalid = matchInvalidCharsWhithPonctuation(postalAddress) ? matchInvalidCharsWhithPonctuation(postalAddress).length > 0 : false;
    var postalCodeIsInvalid = matchAlphaNum(postalCode) ? matchAlphaNum(postalCode).length > 0 : false;

    return (
      <div className="panelBoxSection" >
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faMapMarkerAlt}/>
          </div>
          <h2>Address</h2>
        </div>
        <div className="panelBoxBody">

          <Row form>
            <Col>
              <FormGroup>
                <Label>Department</Label>
                <Input autoComplete="noautoComplete"
                       type="text"
                       name="departmentNumber"
                       value={departmentNumber}
                       invalid={departmentNumberIsInvalid || departmentNumber.length > MAX_CHARS}
                       onChange={this.onfieldsChange}/>
                 <FormFeedback>
                   {departmentNumber.length > MAX_CHARS ? "Department is too long. Max " + MAX_CHARS + " allowed. " : null}
                   {departmentNumberIsInvalid ? "Characters not allowed " + matchInvalidCharsWhithPonctuation(departmentNumber).join(" ") : null}
                 </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <FormGroup>
                <Label>Street address</Label>
                <Input type="textarea"
                       invalid={postalAddressIsInvalid || postalAddress.length > TEXTAREA_MAX_CHARS}
                       autoComplete="noautoComplet"
                       name="postalAddress"
                       value={postalAddress}
                       placeholder={addressPlaceholder}
                       onChange={this.onfieldsChange}/>
                <FormFeedback>
                  {postalAddress.length > TEXTAREA_MAX_CHARS ? "Street address is too long. Max " + TEXTAREA_MAX_CHARS + " allowed. " : null}
                  {postalAddressIsInvalid ? "Characters not allowed " + matchInvalidCharsWhithPonctuation(postalAddress).join(" ") : null}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col xs={3}>
                <FormGroup>
                  <Label>Postal code</Label>
                  <Input autoComplete="noautoComplete"
                         type="text"
                         name="postalCode"
                         value={postalCode}
                         invalid={postalCodeIsInvalid || postalCode.length > MAX_CHARS}
                         onChange={this.onfieldsChange}/>
                   <FormFeedback>
                     {postalCode.length > MAX_CHARS ? "Postal code is too long. Max " + MAX_CHARS + " allowed. " : null}
                     {postalCodeIsInvalid ? "Characters not allowed " + matchAlphaNum(postalCode).join(" ") : null}
                   </FormFeedback>
                </FormGroup>
            </Col>
            <Col xs={9}>
                <FormGroup>
                  <Label>City</Label>
                  <Input autoComplete="noautoComplete"
                         invalid={cityIsInvalid || city.length > MAX_CHARS}
                         type="text"
                         name="city"
                         value={city}
                         onChange={this.onfieldsChange}/>
                  <FormFeedback>
                    {city.length > MAX_CHARS ? "City is too long. Max " + MAX_CHARS + " allowed. " : null}
                    {cityIsInvalid ? "Characters not allowed " + matchAlphaNum(city).join(" ") : null}
                  </FormFeedback>
                </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="country" className="required">Country</Label>
                <Input autoComplete="noautoComplete"
                       type="select"
                       name="country"
                       value={country}
                       onChange={this.onfieldsChange}
                       invalid={country.length === 0 && formIsUsed}
                       required={true}>
                  {optionCountryItems}
                </Input>
              </FormGroup>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}
export default ContactEditAddress;
