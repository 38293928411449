import axios from "axios";
import { getAuthHeaders } from './index';

import { getURLPrefix } from '../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

import { logout } from '../utils/httpErrors';

var getEnpoint = (ep) => {
  if (['lock'].includes(ep)) {
    return pathname + `api/v1/${ep}/`;
  }
  return pathname + 'api/v1/';
}


export function getLockStatus(resourceID) {
  return new Promise((resolve, reject) => {
    var url = getEnpoint('lock') + '?resource='+resourceID;
    axios({
      url,
      method: 'get',
      headers: getAuthHeaders(false)
    }).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      console.log('ERROR! : ', err);
      reject(err)
      logout(err)
    })
  })
}

export function getLockListStatus(resourceIDS) {
  var data = resourceIDS;
  return new Promise((resolve, reject) => {
    var url = getEnpoint('lock') + 'status';
    axios({
      url,
      data,
      method: 'post',
      headers: getAuthHeaders(false)
    }).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      console.log('ERROR! : ', err);
      reject(err)
      logout(err)
    })
  })
}

export function lockResource(resourceID) {
  return new Promise((resolve, reject) => {
    var url = getEnpoint('lock') + '?resource='+resourceID;
    axios({
      url,
      method: 'post',
      headers: getAuthHeaders(false)
    }).then((response) => {
      resolve()
    }).catch((err) => {
      console.log('ERROR! : ', err);
      reject(err)
      logout(err)
    })
  })
}

export function unlockResource(resourceID) {
  return new Promise((resolve, reject) => {
    var url = getEnpoint('lock') + '?resource='+resourceID;
    axios({
      url,
      method: 'delete',
      headers: getAuthHeaders(false)
    }).then((response) => {
      resolve()
    }).catch((err) => {
      console.log('ERROR! : ', err);
      reject(err)
      logout(err)
    })
  })
}

export function refreshAllUserResources() {
  return new Promise((resolve, reject) => {
    var url = getEnpoint('lock');
    axios({
      url,
      method: 'put',
      headers: getAuthHeaders(true)
    }).then((response) => {
      resolve()
    }).catch((err) => {
      console.log('ERROR! : ', err);
      reject(err)
      logout(err)
    })
  })
}

export function unlockAllResources() {
  return new Promise((resolve, reject) => {
    var url = getEnpoint('lock') + 'unlock-user-resources';
    axios({
      url,
      method: 'delete',
      headers: getAuthHeaders(true)
    }).then((response) => {
      resolve()
    }).catch((err) => {
      console.log('ERROR! : ', err);
      reject(err)
      logout(err)
    })
  })
}
