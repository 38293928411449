import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faPlusCircle,
  faEdit,
  faUsers,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  Badge,
  FormText
} from 'reactstrap';

import SearchComponent from '../../../Search/SearchComponent';
import  EditList from '../../../EditList';

import { matchStringInObject } from '../../../../utils';

const ContactMembershipWidget = (props) => {

  const {
    groupsSelected,
    otherGroups,
    selectGroupAction,
    unselectGroupAction,
    modeView
  } = props;

  const [searchByGroupSelected, setSearchString] = useState('');
  const onSearchInGroupSelected = (searchString) => setSearchString(searchString);

  const [searchByGroupNotSelected, setSearchStringInFullGroup] = useState('');
  const onSearchInGroupNotSelected = (searchString) => setSearchStringInFullGroup(searchString);

  var filterGroupBySearch = (groups, searchString) => {
    return groups.filter((group) => {

    });
  };

  var groupsSelectedfiltered = groupsSelected.filter((group) => { return matchStringInObject(searchByGroupSelected, ['name'], group); });

  var groupNotSelected = otherGroups.filter((group) => { return matchStringInObject(searchByGroupNotSelected, ['name'], group); });

  var noGroupsSelectedMsg = groupsSelected.length == 0 ? "You need to select at least one group" : "";

  return (
    <div className="panelBoxSection">
    <div className="panelBoxTitle">
      <div className="panelBoxTitle-icon">
        <FontAwesomeIcon icon={faUsers}/>
      </div>
      <h2 style={{marginBottom: noGroupsSelectedMsg ? "-8px" : "8px"}}>Memberships</h2>
      {noGroupsSelectedMsg ? <small className="form-text text-muted colorRed"><FontAwesomeIcon icon={faExclamationTriangle}/> {noGroupsSelectedMsg}</small> : null}
    </div>
    <div className="panelBoxBody">

      <Row className="editMembershipsWidget"  style={{ height: noGroupsSelectedMsg ? "calc(100% - 62px)" : "calc(100% - 43px)"}}>
        <Col xs={6} className='contact-edit-memberShips'>
          <div className="membershipsWidgetDescription" style={{height: "180px"}}>
            <p>Contact's groups <Badge color="secondary">{groupsSelected.length > 0 ? groupsSelected.length : "0"}</Badge></p>
            <small className="form-text text-muted">Click on <FontAwesomeIcon icon={faMinusCircle}/> to remove the group from the contact (group is NOT deleted from LPO). This icon <FontAwesomeIcon icon={faMinusCircle} style={{color : "rgba(108, 117, 125, 0.9)"}}/> tells you that you are not authorized to edit this group.</small>
            <SearchComponent onSearchChanged={onSearchInGroupSelected}/>

          </div>
          <div  className="membershipsWidgetList">
            <EditList mode="GROUP"
                      listType="select"
                      items={groupsSelectedfiltered}
                      sortKey="name"
                      icon={faMinusCircle}
                      modeView={modeView}
                      action={(item)=>{ unselectGroupAction(item) }} />
          </div>
        </Col>
        <Col xs={6} className='contact-edit-memberShips'>
          <div className="membershipsWidgetDescription">
            <p>Search in the groups directory</p>
            <small className="form-text text-muted">Click on <FontAwesomeIcon icon={faPlusCircle} style={{color : "#9ACA3C"}}/> to add the group to the contact. This icon <FontAwesomeIcon icon={faPlusCircle} style={{color : "rgba(108, 117, 125, 0.9)"}}/> tells you that you are not authorized to edit this group.</small>
            <SearchComponent onSearchChanged={onSearchInGroupNotSelected}/>
          </div>
          <div  className="membershipsWidgetList">
            <EditList mode="GROUP"
                      items={groupNotSelected}
                      icon={faPlusCircle}
                      hiddeItem={true}
                      sortKey="name"
                      action={(item)=>{ selectGroupAction(item) }}/>
          </div>
        </Col>
      </Row>
      <FormText><span style={{color : "#dc3545"}}>*</span> Required fields</FormText>
      </div>
    </div>
  );
}

export default ContactMembershipWidget;
