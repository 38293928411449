import axios from 'axios';
import { toast } from 'react-toastify';
import { logout } from '../../utils/httpErrors';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

const API_URL = pathname + 'api/v1/';

export function getCred(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_USER" });
      var url = API_URL +'user/login';
      axios({
        url,
        data,
        method: 'post',
      }).then((response) => {
        dispatch( fetchUser(response) )
        resolve()
      }).catch((err) => {
        dispatch({ type: "FETCH_USER_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        // function is wrapped by the react context... => we can use toast
        logout(err);
        reject(err)
      })
    })
  }
}

export function setGoogleCred(data) {
  const response = {};
  response.data = data;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_USER" });
      dispatch( fetchUser(response) )
      resolve()
    });
  }
}

export function getStatusAuth() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_STATUS_AUTH" });
      var url = API_URL +'user/status-auth';

      axios({
        url,
        method: 'get',
      }).then((response) => {
        dispatch({
          type: "FETCH_STATUS_AUTH_FULFILLED", payload: response.data
        })
        resolve()
      }).catch((err) => {
        dispatch({ type: "FETCH_STATUS_AUTH_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        // function is wrapped by the react context... => we can use toast
        logout(err);
        reject(err)
      })
    })
  }
}

export function getToken(token, refreshToken, uid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "REFRESH_USER_TOKEN" });
      var url = API_URL + 'tokens/refresh-token';
      axios({
        url,
        method: 'get',
        headers: {'authorization': token,
                  'user': uid,
                  'refreshtoken': refreshToken}
      }).then((response) => {
        dispatch({
          type: "REFRESH_USER_TOKEN_FULFILLED", payload: response.data
        })
        resolve()
      }).catch((err) => {
        dispatch({ type: "REFRESH_USER_TOKEN_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        // function is wrapped by the react context... => we can use toast
        logout(err);
        reject(err)
      })
    })
  }
}

const fetchUser = (response) => {
  return { type: "FETCH_USER_FULFILLED",
           payload: {
              uid: response.data.uid,
              firstname: response.data.firstname,
              lastname: response.data.lastname,
              token:  response.data.refreshToken,
              perm: response.data.role,
              refreshToken: response.data.token,
              timestamp: response.data.timestamp
            }
        };
};
