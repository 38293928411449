import React from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge
 } from 'reactstrap';
 import { sortRows } from '../../utils/sortUtils';
 import {MultiSelect} from "react-multi-select-component";

class InputMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      searchString: [],
      advancedSearch: [],
      value : ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { initField, advancedSearch, id } = nextProps;
    var advancedSearchConfirm = advancedSearch && Object.keys(advancedSearch).length > 0 && Object.keys(advancedSearch).length !== Object.keys(prevState.advancedSearch).length;

    if(initField || advancedSearchConfirm){
      var searchString = [];

      if(!initField){
        if(Object.keys(advancedSearch).includes(id)){
          advancedSearch[id].forEach(option => searchString.push({label: option, value: option}));
        } else {
          searchString = [];
        }
      }

      return { searchString : searchString }
    }
    return null;
  }

  handleChange(options){

    var result = options.map(option => option.value);

    this.props.handleChangeCb(result, this.props.id);

    this.setState({
      searchString : options
    });

  }

  getOptions(items){
    const options = [];
    if(this.props.id === "fullname"){
      sortRows(items, 'id', 'asc').forEach((item) => {
        var value = item.id;
        options.push({label: value, value: value});
      });
    } else {
      sortRows(items, 'name', 'asc').forEach((item) => {
        var value = item.name;
        options.push({label: value, value: value});
      });
    }



    return options;
  }

  render(){
    const { handleChangeCb, id, advancedSearch, items, initField, label} = this.props;

    const options = this.getOptions(items);

    var divClassName = "InputSelectWithLabel advancedSearchField col-6 input-group";

    const customValueRenderer = (selected, _options) => {
      return selected.length
        ? selected.map(({ label }) => <Badge key={label} className="badge-valueMultiSelect" >{label}</Badge>)
        : <span className="color-primary">*</span>;
    };

    return(
      <div className={this.state.searchString.length > 0 ? divClassName + " active" : divClassName}>
        <div className="input-group-prepend">
          <span class="input-group-text">{label}</span>
        </div>
        <MultiSelect options={options}
                     value={this.state.searchString}
                     onChange={this.handleChange}
                     hasSelectAll={false}
                     valueRenderer={customValueRenderer}
                     labelledBy={"Select"}/>
      </div>
    );
  }
}

export default InputMultiSelect;
