import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux"
import { 
  Container, 
  Row, 
  Nav, 
  NavItem, 
  Tooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import { Route, Link, withRouter } from 'react-router-dom';
import Moment from 'moment';

import { faUsers, faUser, faBuilding, faSignOutAlt, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import appInfo from '../../../appVersion.json';

import {
  fetchPeople,
  fetchGroups,
  fetchConfiguration
} from '../ODD/action';

import { getToken } from '../Login/action';

import Login from '../Login/Login';
import logo from '../../assets/images/efpia-logo.png';
import KeepAliveComponent from '../KeepAliveComponent/KeepAliveComponent';
import GroupView from '../GroupView/GroupView';
import GroupEditView from '../GroupEditView/GroupEditView';
import ContactView from '../ContactView/ContactView';
import ContactEditView from '../ContactView/ContactEditView';
import CompanyView from '../CompanyView/CompanyView';
import DirectotyEditorsView from '../DirectoryAdminTools/DirectotyEditorsView';
import TransfertEFPIAContactView from '../DirectoryAdminTools/TransfertEFPIAContactView';


import { getLoggedUser } from '../../utils';
import { unlockAllResources } from '../../utils/lockService';
import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class ProtectedApp extends React.Component {

  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      people: props.ldap.people,
      groups: props.ldap.groups,
      current_user: getLoggedUser(),
      tooltipIsOpen : false
    }
  }

  logout() {
    unlockAllResources()
    .then(() => {
      sessionStorage.removeItem('efpia-ldap-user');
      sessionStorage.removeItem('group-redirect');
      window.location.reload();
    })
    .finally();
  }

  refreshApiData () {
    var current_user = getLoggedUser();

    var self = this;

    if (current_user){
      this.props.displayLoadingOverlay(true);
      this.props.onFetchPeople()
      .then(this.props.onFetchGroups)
      .then(this.props.onFetchConfiguration)
      .then(() => {
          this.props.displayLoadingOverlay(false);
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { people, groups } = nextProps.ldap;
    if (people.length !== prevState.people.length ||
      groups.length !== prevState.groups.length) {
      return { people, groups, current_user: getLoggedUser() }
    }
    return null;
  }

  componentDidMount() {
    const { people, groups } = this.props.ldap;
    if (people.length !== this.state.people ||
        groups.length !== this.state.groups) {
      this.refreshApiData();
    }
  }

  preparePeopleWithRouteCbs(peopleInProps, history){
    return peopleInProps.map( function (person) {
      var personWithRouteCbs = person;
      personWithRouteCbs.detailLinkCb = function(){
        history.push( pathname + 'contact/details/' + encodeURIComponent(person.id));
      };
      personWithRouteCbs.editLinkCb = function(){
        history.push( pathname + 'contact/edit/' + encodeURIComponent(person.id));
      };
      personWithRouteCbs.duplicateLinkCb = function(){
        history.push( pathname + 'contact/duplicate/' + encodeURIComponent(person.id));
      };
      return personWithRouteCbs;
    });
  }

  prepareGroupsWithRouteCbs(groupsInProps, history){
  return groupsInProps.map( function (group) {
    var groupWithRouteCbs = group;
    groupWithRouteCbs.detailLinkCb = function(){
      history.push( pathname + 'group/details/' + encodeURIComponent(group.id));
    };
    groupWithRouteCbs.editLinkCb = function(){
      history.push( pathname + 'group/edit/' + encodeURIComponent(group.id));
    };
    groupWithRouteCbs.duplicateLinkCb = function(){
      history.push( pathname + 'group/duplicate/' + encodeURIComponent(group.id));
    };
    return groupWithRouteCbs;
    });
  }

  toggle(){
    this.setState({
      tooltipIsOpen : !this.state.tooltipIsOpen
    });
  }

  render() {

    const { ldap } = this.props;
    const { current_user } = this.state;

    if (!getLoggedUser()) return <Login displayLoadingOverlay={this.props.displayLoadingOverlay} textLoadingOverlay={this.props.textLoadingOverlay}/>

    if (ldap.groups.length == 0 || ldap.people.length == 0 || ldap.configuration.staffGroupId == undefined) {
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

    const peopleWithRouteCbs = this.preparePeopleWithRouteCbs(ldap.people, this.props.history);
    const groupsWithRouteCbs = this.prepareGroupsWithRouteCbs(ldap.groups, this.props.history);

    const userCurrent = `${current_user.firstname.charAt(0)}${current_user.lastname.charAt(0)}`;

    const displayLoadingOverlay = this.props.displayLoadingOverlay;
    const textLoadingOverlay = this.props.textLoadingOverlay;

    var locationPathname = this.props.location.pathname;

    var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

    var accessToken = this.props.user.refreshToken ? this.props.user.refreshToken.token : current_user.refreshToken;
    var refreshToken = current_user.token;
    var refreshTokenTimestamp = this.props.user.refreshToken ? this.props.user.refreshToken.timestamp : this.state.current_user.timestamp;
    var secBetweenTwoRefreshToken = this.props.ldap.configuration.secBetweenTwoRefreshToken;
    var secBeforeModalWarningExpiredSession = this.props.ldap.configuration.secBeforeModalWarningExpiredSession;

    return (
        <Container fluid={true} className="globalContainer h-100">
          <KeepAliveComponent
            SESSION_STORAGE_ITEM_NAME='efpia-ldap-user'
            getRefreshToken={() => this.props.onRefreshToken(accessToken, refreshToken, current_user.uid)}
            refreshTokenTimestamp={refreshTokenTimestamp}
            logout={this.logout}
            secBeforeModalWarningExpiredSession={secBeforeModalWarningExpiredSession}
            secBetweenTwoRefreshToken={secBetweenTwoRefreshToken}>
            <Row className="h-100">
                <div className="left-nav h-100">
                    <Link to={pathname + "contact"}><img src={logo}/></Link>
                    <Nav vertical className="navBar-left">
                        <NavItem>
                            <Link to={pathname + "contact"} className={locationPathname.includes("/contact") || locationPathname.includes("/new-contact") || locationPathname === "/" ? "nav-link-active" : null}>
                              <span className="navIcon"><FontAwesomeIcon icon={faUser}/></span>
                              <span>Contacts</span>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to={pathname + "group"} className={locationPathname.includes("/group") || locationPathname.includes("/new-group") ? "nav-link-active" : null}>
                              <span className="navIcon"><FontAwesomeIcon icon={faUsers}/></span>
                              <span> Groups</span>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to={pathname + "company"} className={locationPathname.includes("/company") ? "nav-link-active" : null}>
                              <span className="navIcon"><FontAwesomeIcon icon={faBuilding}/></span>
                              <span>Companies</span>
                            </Link>
                        </NavItem>
                    </Nav>
                    <div className="alignBottom">

                      {current_user.perm === "ADMIN" 
                        ? <UncontrolledDropdown nav inNavbar direction="right">
                            <DropdownToggle nav className={locationPathname.includes("/directoryEditors") || locationPathname.includes("/transfertEFPIAContact") ? "nav-link-active" : null}>
                              <span className="navIcon"><FontAwesomeIcon icon={faUserEdit}/></span>
                              <span>Admin<br/>tools</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="border-bottom p-0">
                                <Link to={pathname + "directoryEditors"} className="p-3 d-block">
                                  <span>Directory Editors</span>
                                </Link>
                              </DropdownItem>
                              <DropdownItem className="border-bottom p-0">
                                <Link to={pathname + "transfertEFPIAContact"} className="p-3 d-block">
                                  <span>Transfert EFPIA contact</span>
                                </Link>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        : null
                      }

                      <div className="userCurrent" id="userCurrent">
                        <p>{userCurrent}</p>
                      </div>
                      <Tooltip placement="right" isOpen={this.state.tooltipIsOpen} target="userCurrent" toggle={this.toggle}>
                        {current_user.firstname} {current_user.lastname}
                      </Tooltip>
                      <Link to="#" onClick={this.logout} className="fontSize-14">
                      Log out <FontAwesomeIcon icon={faSignOutAlt}/>
                      </Link>

                      <p className="appVersion">LPO v{appInfo.version}</p>
                    </div>
                </div>
                <div className="right-panel h-100">
                  { isAllowedToEdit ? <Route path={pathname + "new-group/"} render={function (props) {
                    return <GroupEditView
                      mode="CREATE"
                      loadingOverlayCb={displayLoadingOverlay}
                      textLoadingOverlay={textLoadingOverlay}
                    />}} /> : null }

                  <Route path={pathname} exact={true} render={function (props) {
                    return <ContactView {...props}
                      people={peopleWithRouteCbs}
                      groups={groupsWithRouteCbs}
                      loadingOverlayCb={displayLoadingOverlay}
                      textLoadingOverlay={textLoadingOverlay}
                    />}} />

                  <Route path={pathname + "group/"} render={function (props) {
                    return <GroupView {...props}
                      people={peopleWithRouteCbs}
                      groups={groupsWithRouteCbs}
                      loadingOverlayCb={displayLoadingOverlay}
                      textLoadingOverlay={textLoadingOverlay}
                    />}} />

                  {isAllowedToEdit ? <Route path={pathname + "new-contact/"} render={function (props) {
                    return <ContactEditView {...props}
                      mode="CREATE"
                      people={peopleWithRouteCbs}
                      groups={groupsWithRouteCbs}
                      loadingOverlayCb={displayLoadingOverlay}
                      textLoadingOverlay={textLoadingOverlay}
                    />}} /> : null }

                  <Route path={pathname + "contact/"} render={function (props) {
                    return <ContactView {...props}
                      people={peopleWithRouteCbs}
                      groups={groupsWithRouteCbs}
                      loadingOverlayCb={displayLoadingOverlay}
                      textLoadingOverlay={textLoadingOverlay}
                    />}} />

                  {isAllowedToEdit ? <Route path="company/new" render={function (props) {
                    return <CompanyView {...props}
                      people={peopleWithRouteCbs}
                      loadingOverlayCb={displayLoadingOverlay}
                      textLoadingOverlay={textLoadingOverlay}/>}}
                  /> : null }

                  <Route path={pathname + "company/"} render={function (props) {
                    return <CompanyView {...props}
                      people={peopleWithRouteCbs}
                      loadingOverlayCb={displayLoadingOverlay}
                      textLoadingOverlay={textLoadingOverlay}/>}}
                  />

                  {current_user.perm === "ADMIN" 
                    ? <>
                        <Route 
                          path={pathname + "directoryEditors/"} 
                          render={function (props) {
                                    return <DirectotyEditorsView 
                                              people={peopleWithRouteCbs}
                                              groups={groupsWithRouteCbs}
                                              loadingOverlayCb={displayLoadingOverlay}
                                              textLoadingOverlay={textLoadingOverlay}
                                            />
                                  }}
                        />
                        <Route 
                          path={pathname + "transfertEFPIAContact/"} 
                          render={function (props) {
                                    return <TransfertEFPIAContactView staffGroupId={ldap.configuration.staffGroupId}/>
                                  }}
                        />
                      </>
                    : null
                  }
                </div>
            </Row>
          </KeepAliveComponent>
        </Container>
    );
  };
};

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user,
  admin: state.admin
});

const mapDispatchToProps = dispatch => ({
  onFetchPeople: () => dispatch(fetchPeople()),
  onFetchGroups: () => dispatch(fetchGroups()),
  onFetchConfiguration: () => dispatch(fetchConfiguration()),
  onRefreshToken: (token, refreshToken, uid) => dispatch(getToken(token, refreshToken, uid))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProtectedApp);
