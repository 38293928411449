import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const BoxDeletetComponent = (props) => {
  const {
    boxModule
  } = props;

  let domainUrl = boxModule ? 'https://' + boxModule.domain : '';
  let domain = boxModule ? boxModule.domain : '';

  return (

      <p className="p-3 text-md-left">
        <FontAwesomeIcon icon={faSync} className="d-inline"/> <strong className="d-inline">LPO Box Sync info :</strong> Folders shared for collaboration with the group in  Box are <strong className="d-inline">not</strong> automaticaly unshared.<br/>
        <br/>
        The former members will keep their individual access to the folder(s) in their BOX account, unless the collaborations are removed in <a target="_blank" href={domainUrl}>{domain}</a> <strong className="d-inline"><em className="d-inline">before</em></strong> deleting the group.<br/>
        <br/>
        Content shared <strong className="d-inline">only</strong> by link is <strong className="d-inline">not</strong> impacted.<br/>
      </p>

  );
}

export default BoxDeletetComponent;
