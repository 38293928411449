import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, ListGroupItem } from 'reactstrap';

const ContactToolsAccess = (props) => {
  const {
    toolsAccess
  } = props;

  return (
    <div className="panelBoxSection">
      <div className="panelBoxTitle">
        <div className="panelBoxTitle-icon">
          <FontAwesomeIcon icon={faTools}/>
        </div>
        <h2>Tools access</h2>
      </div>
      <div className="panelBoxBody">
        <ListGroup>
          <ListGroupItem className="toolsAccess-li">
            <span className="toolsAccess-tool">KMS access</span>
            <span className={toolsAccess.includes("KMS") ? "toolsAccess-icon" : "toolsNotAccess-icon"}>{toolsAccess.includes("KMS") ? "  Yes" : "  No"}</span>
          </ListGroupItem>
        </ListGroup>
      </div>
    </div>
  );
}

export default ContactToolsAccess;
