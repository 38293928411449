import countries from '../../../../server/countriesChecker/preferred-countries';
import { sortRows } from '../../../utils/sortUtils';

export const getOverridedCountries = () => {

  return sortRows(countries, 'name', 'asc');
};

export const getCountry = (countryCode) => {

  var country = countries.filter(country =>  country.code === countryCode )[0];

  if(country){
    return country.name;
  }

  return countryCode;
};
