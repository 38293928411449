import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { matchFullnameInvalidChars } from "../../SubComponent/fieldsValidators";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faEdit
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  FormFeedback
} from 'reactstrap';

import GroupValidationSchema from '../../../../server/jsonSchema/groupCreate.schema.json';

import ModalGroupNameValidator from './ModalComponent/ModalGroupNameValidator';
import ModalGroupEmailValidator from './ModalComponent/ModalGroupEmailValidator';

const NAME_MAX_CHARS = GroupValidationSchema.properties.name.maxLength;

class GroupFormDetail extends React.Component{
  constructor(props){
    super(props);
    this.displayNameModal = this.displayNameModal.bind(this);
    this.displayEmailModal = this.displayEmailModal.bind(this);
    this.handleDetailChange = this.handleDetailChange.bind(this);
    this.successNameCb = this.successNameCb.bind(this);
    this.successEmailCb = this.successEmailCb.bind(this);

    this.state = {
      modalNameIsOpen: false,
      modalEmailIsOpen: false,
      nameIsValid: false,
      emailIsValid: false,
      groupEmailDomain: ""
    }

  }
  componentDidMount(){
    if(this.props.mode === "EDIT"){
      this.props.boxCanBeSubmit(true);
      this.setState({
        nameIsValid: true,
        emailIsValid: true
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){

    const { ldap } = nextProps;

    if(ldap.configuration && ldap.configuration.groupEmailDomain){
      var groupEmailDomain = "@" + ldap.configuration.groupEmailDomain;

        return { groupEmailDomain: groupEmailDomain};
    }
    return null;
  }

  displayNameModal(){
    this.setState({
      modalNameIsOpen : !this.state.modalNameIsOpen,
      nameIsValid: this.props.group.name.length > 0
    });
  }

  displayEmailModal(){
    this.setState({
      modalEmailIsOpen : !this.state.modalEmailIsOpen,
      emailIsValid: this.props.group.email.length > 0
    });
  }

  handleDetailChange(event){
    this.props.handleChange(event);

    const EMAIL_MAX_LENGTH = 28 + this.state.groupEmailDomain.length;

    var typeValue = event.target.name === "type" ? event.target.value : this.props.group.type;
    var nameIsValid = this.state.nameIsValid && !(this.props.group.name.length >  NAME_MAX_CHARS);
    var emailIsValid = this.state.emailIsValid && this.props.group.email.length <= EMAIL_MAX_LENGTH;
    var efpiaContactValue = event.target.name === "efpiaContact" ? event.target.value : this.props.group.efpiaContact;

    var groupFormDetailIsValid = typeValue && typeValue.length !== 0 && nameIsValid && emailIsValid && efpiaContactValue.length > 0;

    this.props.boxCanBeSubmit(groupFormDetailIsValid);
  }

  successNameCb(name){
    const EMAIL_MAX_LENGTH = 28 + this.state.groupEmailDomain.length;
    this.props.nameValidCb(name);

    var typeValue = this.props.group.type ? this.props.group.type.length !== 0 : false;
    var nameIsValid = name.length > 0 && !(name.length >  NAME_MAX_CHARS);
    var emailIsValid = this.state.emailIsValid && this.props.group.email.length <= EMAIL_MAX_LENGTH;
    var efpiaContactValue = this.props.group.efpiaContact.length > 0;

    var groupFormDetailIsValid = typeValue && nameIsValid && emailIsValid && efpiaContactValue;
    this.props.boxCanBeSubmit(groupFormDetailIsValid);

    this.setState({
      nameIsValid: nameIsValid,
      modalNameIsOpen: !this.state.modalNameIsOpen
    });

  }

  successEmailCb(email){
    const EMAIL_MAX_LENGTH = 28 + this.state.groupEmailDomain.length;
    this.props.emailValidCb(email);

    var typeValue = this.props.group.type ? this.props.group.type.length !== 0 : false;
    var nameIsValid = this.state.nameIsValid && !(this.props.group.name.length >  NAME_MAX_CHARS);
    var emailIsValid = email.length > 0 && email.length <= EMAIL_MAX_LENGTH;
    var efpiaContactValue = this.props.group.efpiaContact.length > 0;

    var groupFormDetailIsValid = typeValue && nameIsValid && emailIsValid && efpiaContactValue;
    this.props.boxCanBeSubmit(groupFormDetailIsValid);

    this.setState({
      emailIsValid: emailIsValid,
      modalEmailIsOpen: !this.state.modalEmailIsOpen
    });
  }

  render(){
    const EMAIL_MAX_LENGTH = 28 + this.state.groupEmailDomain.length;

    const {
      mode,
      group,
      nameValidCb,
      emailValidCb,
      efpiaContacts,
      current_user,
      formIsUsed
    } = this.props;

    var modalNameIsOpen = this.state.modalNameIsOpen;
    var modalEmailIsOpen = this.state.modalEmailIsOpen;

    var optionsEfpiaContactsItems = efpiaContacts.map((item, index) =>

       <option key={"ec"+index} value={item}>{item}</option>
     );
    optionsEfpiaContactsItems.unshift(<option key="efpiaContact-selector" value="">...</option>);

    var groupType = group.type;
    var groupTypeIsValid = groupType && groupType.length > 0 && GroupValidationSchema.properties.type.enum.some(SchemaType => { return SchemaType === groupType});

    var emailIsInvalid = mode === "EDIT" && (group.email.length > EMAIL_MAX_LENGTH
                                         || !group.email.includes(this.props.groupEmailDomain)
                                         || group.email.includes("+") || group.email.includes("="));

    return (
      <div className="panelBoxSection" >
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faUsers}/>
          </div>
          <h2>Group</h2>
        </div>
        <div className="panelBoxBody">
          <Row>
            <Col>
            <FormGroup onClick={this.displayNameModal}>
              <Label for="name" className="required">Group's name</Label>
              <InputGroup>
                <Input autoComplete="off"
                       type="text"
                       name="name"
                       value={group.name}
                       invalid={group.name.length >  NAME_MAX_CHARS}
                       placeholder="Group name"
                       required
                       disabled/>
                 <InputGroupAddon addonType="prepend">
                   <InputGroupText>
                    <FontAwesomeIcon icon={faEdit}/>
                   </InputGroupText>
                 </InputGroupAddon>
                 <FormFeedback>
                   Group name is too long. Max 73 characters.
                 </FormFeedback>
              </InputGroup>
              <ModalGroupNameValidator isOpen={modalNameIsOpen}
                                       cancelCb={this.displayNameModal}
                                       mode={mode}
                                       label="Group's name"
                                       inputValue={group.name}
                                       successCb={this.successNameCb}
                                       titleModal={group.name ? "Edit " + group.name + " group name" : "New group name"}/>
            </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
            <FormGroup>
              <Label for="type" className="required">Group's type</Label>
              <Input autoComplete="noautoComplete"
                     type="select"
                     name="type"
                     value={group.type}
                     invalid={formIsUsed && !groupTypeIsValid}
                     required
                     onChange={this.handleDetailChange}>
                <option value="">Select a type...</option>
                <option value="experts">Experts</option>
                <option value="network">Network</option>
              </Input>
            </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup onClick={this.displayEmailModal}>
                <Label for="Email" className="required">Email</Label>
                <InputGroup>
                  <Input autoComplete="noautoComplete"
                         type="email"
                         name="email"
                         value={group.email}
                         invalid={emailIsInvalid}
                         required
                         placeholder={"group" + this.props.groupEmailDomain}
                         disabled/>
                   <InputGroupAddon addonType="prepend">
                     <InputGroupText>
                       <FontAwesomeIcon icon={faEdit}/>
                     </InputGroupText>
                   </InputGroupAddon>
                   <FormFeedback>
                     {group.email.length > EMAIL_MAX_LENGTH ? "The left part of the group email is too long. Max 28 characters. " : null}
                     {!group.email.includes(this.props.groupEmailDomain) ? "Email domain is invalid. " : null}
                     {group.email.includes("+") || group.email.includes("=") ? "Characters not allowed." : null}
                   </FormFeedback>
                </InputGroup>
                 <ModalGroupEmailValidator isOpen={modalEmailIsOpen}
                                           label="Email"
                                           email={group.email}
                                           cancelCb={this.displayEmailModal}
                                           successCb={this.successEmailCb}
                                           titleModal={group.name ? "Edit " + group.name + " group email"  : "New group email"}/>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="efpiaContact" className="required">EFPIA contact</Label>
                <Input autoComplete="noautoComplete"
                       type="select"
                       name="efpiaContact"
                       value={group.efpiaContact}
                       onChange={this.handleDetailChange}
                       invalid={formIsUsed && group.efpiaContact.length === 0}
                       required>
                  {optionsEfpiaContactsItems}
                </Input>
              </FormGroup>
            </Col>
          </Row>

        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  ldap: state.ldap
});

export default compose(
  connect(mapStateToProps)
)(GroupFormDetail);
