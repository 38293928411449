import React from 'react';
import { Input } from 'reactstrap';

class SearchComponent extends React.Component{

    constructor(props){
      super(props);
      this.state = {
        search: '',
        disabled : false
      }
    }

    handleChange(event) {
      const search = event.target.value;
      this.setState({search});
      this.props.onSearchChanged(search);

      event.preventDefault();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { disabled, searchString } = nextProps;
      if (disabled !== prevState.disabled || searchString !== prevState.searchString) {
        return { disabled : disabled, search : searchString }
      }
      return null;
    }

    render (){

        return (
          <Input
            autoComplete="noAutoComplete"
            value={this.state.search}
            type="search"
            name="search"
            placeholder={this.props.placeholder ? this.props.placeholder : "Filter"}
            onChange={this.handleChange.bind(this)}
            disabled={this.state.disabled}
            style={{background : this.state.disabled ? '#cbcccf' : '#ffffff'}}
          />
        );
    }
}

export default SearchComponent;
