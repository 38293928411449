import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTrashAlt, faSync } from '@fortawesome/free-solid-svg-icons';

import { getLoggedUser } from '../../utils';

class ModalContactDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen : props.isOpen,
      contact : props.contact,
      current_user: getLoggedUser()
    };

    this.toggle = this.toggle.bind(this);
    this.saveAction = this.saveAction.bind(this);
  }

  toggle() {
    this.props.toggle()
  }

  saveAction(){
    this.props.successCb(this.state.contact.id);
  }

  getContactGroupsOrphan(contactGroups){
    var groupsOrphan = [];

    contactGroups.forEach((group) => {
      if(group.members.length === 1){
        groupsOrphan.push(group.name)
      }
    });

    return groupsOrphan;
  }

  getGroupNoAuthorized(groups){
    var current_user = this.state.current_user;

    var loggedUserIsEditor = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

    if(current_user.perm !== "ADMIN"){
      var groupsWithEditors = [];

      groups.forEach(group => {

        if(group.editors && !group.editors.filter(editor => editor.id === current_user.uid).length > 0){
          loggedUserIsEditor = false;

          groupsWithEditors.push(group);

        }
      });
    }

    return {loggedUserIsEditor, groupsWithEditors};
  }

  authorizedEditors(groups){
    var authorizedEditorsToContact = '';
    if(groups.length === 1){
      authorizedEditorsToContact = [];

      groups.forEach(group => {
        group.editors.forEach((editor, i) => {
          authorizedEditorsToContact.push(<span key={i +"-editors"}>
                                            <span className="cell-lastname font-weight-bold">{editor.lastname}</span> <span className="cell-firstname">{editor.firstname}</span>{group.editors.length === i+1 ? "" : ", "}
                                          </span>);
        });
        authorizedEditorsToContact.push()
      });
      return <> Ask {authorizedEditorsToContact} to remove the contact from the group {groups[0].name}.</>

    } else {
      return <> Ask to the administrator to remove the contact.</>;
    }
  }


  render() {

    var contact = this.state.contact;

    if(!contact || typeof(contact) !== "object" || !contact.groups){
      return null
    }

    var groupsOrphan = this.getContactGroupsOrphan(contact.groups);

    var isStaffMember = this.props.staffGroupId ? contact.groups.filter(group => group.id === this.props.staffGroupId).length > 0 : false;

    var getGroupNoAuthorized = this.getGroupNoAuthorized(contact.groups);

    var loggedUserIsEditor = getGroupNoAuthorized.loggedUserIsEditor;

    if(!loggedUserIsEditor){
      var authorizedEditors = this.authorizedEditors(getGroupNoAuthorized.groupsWithEditors);
    }


    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.toggle} className="panelModal">
          <ModalHeader className="bg-danger" toggle={this.toggle}>
            <FontAwesomeIcon icon={faTrashAlt} style={{fontSize : "40px"}}/>
            <span>You are about to remove<br/>{contact.fullname} from LPO</span>
          </ModalHeader>

          <ModalBody>

            {isStaffMember && groupsOrphan.length === 0
              ? <p className="p-3 text-md-left"><strong className="d-inline">Warning :</strong> this contact will be removed from the staff group, the directory editors and all groups of editors. In every groups, where (s)he is EFPIA contact, the EFPIA contact will become the directory administrator.</p>
              : null
            }

            {groupsOrphan.length > 0 && loggedUserIsEditor
              ? <>
                  <h2>You cannot delete this contact</h2>
                  <p className="p-0">because it will empty the group{groupsOrphan.length > 1 ? "s" : null} {groupsOrphan.join(', ')}.<br/>Delete the group instead</p>
                </>
              : null
            }

            {groupsOrphan.length === 0 && loggedUserIsEditor
              ? <>
                  <p className="p-3 text-md-left">
                    <FontAwesomeIcon icon={faSync} className="d-inline"/> <strong className="d-inline">LPO Box Sync info :</strong> Editing rights for this contact on folders shared with LPO group(s) in BOX will be removed.<br/>
                    <br/>
                    Content shared <strong className="d-inline">only</strong> by link is <strong className="d-inline">not</strong> impacted.<br/>
                    <br/>
                    This operation will take some time, around {this.props.boxModule.minBeforeExecutingSync} minutes.
                  </p>

                  <h2>Do you really want to continue ?</h2>
                </>
              : null
            }

            {!loggedUserIsEditor
              ? <>
                  <h2>This contact is in a group you are not allowed to edit.</h2>
                  <p className="p-0 pt-3">The contact should be removed from the group first. <br/>
                  {authorizedEditors ? authorizedEditors : null}
                  </p>
                </>
              : null
            }

          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>{' '}
            <Button color="danger" 
                    disabled={this.props.successCb && (groupsOrphan.length > 0 || !loggedUserIsEditor)} 
                    onClick={this.saveAction}>
            Delete
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalContactDelete;
