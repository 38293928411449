import React from 'react';
import { sortRows } from '../utils/sortUtils';

import { compose } from 'redux';
import { connect } from "react-redux";
import { getLoggedUser } from '../utils';

import { getLockListStatus } from '../utils/lockService';

import {
  ListGroup,
  ListGroupItem,
  Badge
} from 'reactstrap';

import InfiniteScrollWrapper from './QodScroller';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser, faUserCheck, faCheck, faInfoCircle, faUserLock, faLock } from '@fortawesome/free-solid-svg-icons';
import ChairsComponent from './LabelComponent/ChairsComponent';
import CustumDropdownComponent from './SubComponent/CustumDropdownComponent';

const BUCKET = 50;

class EditList extends React.Component {

  constructor(props){
    super(props);
    this.itemAction = this.itemAction.bind(this);
    this.reactRef = React.createRef();
    this.getThemItems = this.getThemItems.bind(this);

    this.state = {
      icon : props.icon,
      mode : props.mode,
      current_user: getLoggedUser(),
      labelSelected : '',
      hasMore: false,
      rowsToDisplay: [],
      itemsLocked : []
    }
  }
  componentDidMount() {
    if(this.props.items && this.props.items.length > 0){

      this.checkLockStatus(this.props.items);
      var configSecBetweenTwoGetRessourcesStatus = this.props.ldap.configuration.secBetweenTwoGetRessourcesStatus;
      var secBetweenTwoGetRessourcesStatus = configSecBetweenTwoGetRessourcesStatus ? configSecBetweenTwoGetRessourcesStatus*1000 : 3000000;
      this.timer = setInterval(() => {
        this.checkLockStatus(this.props.items);
      }, secBetweenTwoGetRessourcesStatus);

    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.getThemItems(0);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }


  checkLockStatus(items){
    var resourceIDS = items.map(item => { return item.id });
    var self = this ;
    getLockListStatus(resourceIDS)
    .then((listStatus) => {
      self.setState({
        itemsLocked : listStatus
      });
    })
    .then(() => {
      this.getThemItems(0);
    });

  }

  getThemItems(page){
    const { items } = this.props;

    const itemsSorted = sortRows(items, this.props.sortKey, 'asc');

    var start_bucket = page * BUCKET;
    var end_bucket = start_bucket + BUCKET;

    const current_rows = itemsSorted.slice(0, end_bucket);

    const hasMore = end_bucket < itemsSorted.length;

    this.setState({
      rowsToDisplay: this.generateHtrmlItemsList(current_rows, this.state.mode),
      hasMore: hasMore
    })
  };

  generateHtrmlItemsList(itemsSorted, mode){
    var itemsList = [];
    var iconList = this.props.icon;
    var isItemSelected = false;
    var disabled = false;
    var logged_user = this.state.current_user;
    var title = '';
    itemsSorted.forEach((item, index) => {

      var itemName = <span className="text-truncate" title={item.name} >{item.name}</span>;
      var itemAction = <span className="iconList" onClick={ () => this.itemAction(item) }><FontAwesomeIcon icon={iconList}/></span>;

      var itemIsLocked = this.state.itemsLocked.length > 0 && this.state.itemsLocked.filter(itemLocked => itemLocked.resource === item.id && itemLocked.isLocked ).length > 0;

      if(mode == "CONTACT"){
        itemName = <span className="text-truncate">{item.lastname} <span className="cell-firstname">{item.firstname}</span></span>;

        isItemSelected = item.newContactSelected && this.props.listType == "select";

        var labelWidget = null;
        var displayTagLabelWidget = this.props.listType == "select" && this.props.viceChairs && this.props.chairs;

        disabled = (this.props.listType !== "select" && item.isSelected);

        var isOrphanContact = this.props.modeView === "EDIT" && item.groups.length === 1 && !item.newContactSelected && this.props.listType === "select";

        if(isOrphanContact || itemIsLocked){
          itemAction = <CustumDropdownComponent content={isOrphanContact
                                                          ? "This contact is only part of this group. If you want to remove the contact from the group members, you have to delete or to re-assign that contact to another group through the contact's profile view."
                                                          : "This contact is being edited by another user."
                                                        }
                                                toggleContent={<span className="iconList"><FontAwesomeIcon className="text-muted" icon={faUserLock}/></span>}/>
        }

        if(displayTagLabelWidget) {

          labelWidget = <ChairsComponent currentItem={item.id}
                                         chairs={this.props.chairs}
                                         viceChairs={this.props.viceChairs}
                                         addLabelAction={this.props.addLabelAction}
                                         removeLabelAction={this.props.removeLabelAction}/>;
        }
      }

      if(mode == "GROUP"){
        isItemSelected = item.newGroupSelected && this.props.listType == "select";
        var staffGroupId = this.props.ldap.configuration.staffGroupId;

        var loggedUserIsEditor = false;

        if(logged_user.perm !== "ADMIN"){

          if(item.editors){
            loggedUserIsEditor = item.editors.filter(editor => editor.id === logged_user.uid).length === 0;
          }
          disabled = item.id === staffGroupId;
        }

        var isOrphanGroup = this.props.modeView === "EDIT" && !item.newGroupSelected && !disabled && item.members.length === 1 && this.props.listType === "select";

        if(isOrphanGroup || itemIsLocked || loggedUserIsEditor){
          var itemActionContent = "You are not authorized to edit this group.";

          if(isOrphanGroup){
            itemActionContent = "To remove this membership, add another contact to the group first or delete the group."
          }

          if(itemIsLocked){
            itemActionContent = "This group is being edited by another user.";
          }

          itemAction = <CustumDropdownComponent
                          content={itemActionContent}
                          toggleContent={<span className="iconList"><FontAwesomeIcon className="text-muted" icon={faLock}/></span>}
                        />
        }

      }

      itemsList.push(<ListGroupItem
                        title={title ? title : null}
                        style={{backgroundColor : isItemSelected ? "#f8f9fa": "rgba(190, 192, 194, 0.25)"}}
                        className={this.props.listType !== "select" && item.isSelected ? "listItemdisabled" : null }
                        key={item.id}
                        id={item.id}
                        disabled={disabled}
                        action>
                          {itemAction}
                          {itemName}
                          {!this.props.hiddeItem && item.role === "Chair" ? <Badge color="primary" className="ml-2 h-18">{item.role}</Badge>: null}
                          {!this.props.hiddeItem && item.role === "Vice-chair" ? <Badge color="warning" className="text-white ml-2 h-18">{item.role}</Badge>: null}
                          {isItemSelected ? <span className="mr-3 ml-auto"><FontAwesomeIcon className="m-0 text-success" icon={faCheck}/></span> : null}
                          {mode == "CONTACT"
                            ? <CustumDropdownComponent
                                content={'Company : ' + item.company + '.'}
                                toggleContent={<span className="mr-3 ml-auto"><FontAwesomeIcon className="m-0 text-muted" icon={faInfoCircle}/></span>}
                                className="ml-auto"/>
                            : null
                          }
                          {labelWidget}
                 </ListGroupItem>);
    });
    return itemsList;
  }

  itemAction(item){
    this.props.action(item);
  }

  render(){
    const { rowsToDisplay } = this.state;

    return (
      <div  className="qod-scroller-wrapper h-100" ref={this.reactRef}>
        <InfiniteScrollWrapper
                isReverse={false}
                type="LIST"
                items={rowsToDisplay}
                hasMore={this.state.hasMore}
                getNewData={this.getThemItems}
                loaderComponent={<p key={0}><span className="lds-ellipsis"><span></span><span></span><span></span><span></span></span></p>}
            />
      </div>


    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

export default compose(
  connect(mapStateToProps)
)(EditList);
