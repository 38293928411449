import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware'
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from './rootReducer';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 10 });

console.log(process.env.NODE_ENV)

const middleware = process.env.NODE_ENV !== 'production' ?
  composeEnhancers(applyMiddleware(promise, thunk, createLogger())) :
  applyMiddleware(promise, thunk);

const store = createStore(rootReducer, middleware);

export default store;
