import { combineReducers } from 'redux';
import ldap from '../ODD/reducer';
import user from '../Login/reducer';
import admin from '../DirectoryAdminTools/reducer';

export default combineReducers({
  ldap,
  user,
  admin
})
