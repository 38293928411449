import sortArray from "sort-array";
var normalizeStrings = require('normalize-strings');

export const simpleSort = (collection)=>{
  return collection.sort((itemA, itemB) => {

    if (normalizeStrings(itemA.toLowerCase()) < normalizeStrings(itemB.toLowerCase()) )
     return -1;
    if (normalizeStrings(itemA.toLowerCase()) > normalizeStrings(itemB.toLowerCase()) )
       return 1;
    return 0;
  });
};

export const sortRows = (rows, sortKey, order) => {
  if (!Array.isArray(rows)) {
    return false;
  }

  var sorted_rows = [];

  if(sortKey == "chairs"){
    sorted_rows = sortArray(rows, {
      by: "fullname",
      order: order,
      computed: {
        fullname: row => row.chairs && row.chairs.length && typeof(row.chairs[0].fullname) === "string" ? normalizeStrings(row.chairs[0].fullname.toLowerCase()) : row[sortKey]
      },
      nullRank: -1,
      undefinedRank: -1
    });
  }
  else if(sortKey === "efpiaContact"){
    sorted_rows = sortArray(rows, {
      by: "fullname",
      order: order,
      computed: {
        fullname: row => row.efpiaContact && row.efpiaContact.fullname && typeof(row.efpiaContact.fullname) === "string" ? normalizeStrings(row.efpiaContact.fullname.toLowerCase()) : row[sortKey]
      },
      nullRank: -1,
      undefinedRank: -1
    });
  }
  else if(sortKey === "companyMembership"){
    sorted_rows = sortArray(rows, {
      by: "test",
      order: order,
      computed: {
        test: row => row.companyMembership && row.companyMembership.length && typeof(row.companyMembership[0].lastname) === "string" ? normalizeStrings(row.companyMembership[0].lastname) : row[sortKey]
      },
      nullRank: -1,
      undefinedRank: -1
    });
  }
  else {
    sorted_rows = sortArray(rows, {
      by: "sortValue",
      order: order,
      computed: {
        sortValue: row => typeof(row[sortKey]) === "string" ? normalizeStrings(row[sortKey].toLowerCase()) : row[sortKey]
      },
      nullRank: -1,
      undefinedRank: -1
    });
  }


  return sorted_rows;
};
