import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import {
  Container,
  Form,
  Spinner
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faUser,
  faInfo
} from '@fortawesome/free-solid-svg-icons';

import ModalGroupDelete from './SubComponents/ModalComponent/ModalGroupDelete';
import MembershipsWidget from '../MembershipsWidget/MembershipsWidget';
import Header from './SubComponents/Header';
import GroupFormDetail from './SubComponents/GroupFormDetail';
import GroupFormEditors from './SubComponents/GroupFormEditors';
import BoxModalComponent from './SubComponents/ModalComponent/BoxModalComponent';

import { getLockStatus, lockResource, unlockResource, getLockListStatus } from '../../utils/lockService';
import { getLoggedUser } from '../../utils';

import GroupValidationSchema from '../../../server/jsonSchema/groupCreate.schema.json';

import {
  updateGroup,
  fetchGroups,
  fetchPeople,
  fetchCompanies,
  createGroup,
  deleteGroup
} from '../ODD/action';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

const POLLING_LOCK_TIMER = 240000;

class GroupEditView extends React.Component{

  constructor(props){
    super(props);
    this.onFormSubmitEditGroup = this.onFormSubmitEditGroup.bind(this);
    this.onFormSubmitCreateGroup = this.onFormSubmitCreateGroup.bind(this);
    this.preparePeopleData = this.preparePeopleData.bind(this);
    this.updateContactIsSelectedInGroups = this.updateContactIsSelectedInGroups.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.addChairsInPeople = this.addChairsInPeople.bind(this);
    this.addChairLabel = this.addChairLabel.bind(this);
    this.removeChairLabel = this.removeChairLabel.bind(this);

    this.turnContactSelectedInPeopleList = this.turnContactSelectedInPeopleList.bind(this);
    this.selectGroupMembersAction = this.selectGroupMembersAction.bind(this);
    this.unselectPersonAction = this.unselectPersonAction.bind(this);
    this.selectPersonAction = this.selectPersonAction.bind(this);
    this.cancel = this.cancel.bind(this);

    this.nameValidCb = this.nameValidCb.bind(this);
    this.emailValidCb = this.emailValidCb.bind(this);
    this.modaleDelete = this.modaleDelete.bind(this);
    this.groupFormDetailCanBeSubmit = this.groupFormDetailCanBeSubmit.bind(this);
    this.groupFormEditorsCanBeSubmit = this.groupFormEditorsCanBeSubmit.bind(this);

    this.displayBoxInfoModal = this.displayBoxInfoModal.bind(this);

    this.state = {
      modalDeleteIsOpen : false,
      formIsUsed: false,
      isLoading: false,
      modal: false,
      groupFormDetailIsValid: false,
      groupFormEditorsIsValid: false,
      people: props.ldap.people,
      groups: props.ldap.groups,
      companies: props.ldap.companies,
      peopleInGroup : [],
      groupName: '',
      groupId: '',
      group: {
        name: '',
        type: '',
        email: '',
        efpiaContact: '',
        abbreviation: '',
        chairs : [],
        viceChairs : [],
        description: '',
        members: '',
        viceChair: ''
      },
      efpiaContacts: [],
      configuration: props.ldap.configuration,
      peopleInGroup: this.getPeopleInGroup(props.ldap.people, ''),
      current_user: getLoggedUser(),
      lock : { isLocked : false },
      displayBoxInfoModal: false,
      haveContactDeleted: false,
      haveContactAdded: false,
      itemsLocked: [],
      currentlySaving: false
    }

  }

  groupFormDetailCanBeSubmit(groupFormDetailIsValid){
    this.setState({
      groupFormDetailIsValid: groupFormDetailIsValid
    });
  }

  groupFormEditorsCanBeSubmit(formIsUsed, groupFormEditorsCanBeSubmit, editors){

    if(groupFormEditorsCanBeSubmit){
      this.setState({
        formIsUsed: formIsUsed,
        groupFormEditorsIsValid: groupFormEditorsCanBeSubmit,
        editors: editors
      });
    } else {
      this.setState({
        formIsUsed: formIsUsed,
        groupFormEditorsIsValid: groupFormEditorsCanBeSubmit
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.groupId !== this.props.groupId){
      clearInterval(this.lockTimer);
      this.lockTimer = null;
      var resourceID = this.props.groupId;
      lockResource(resourceID);
      this.getItemsLockListStatus();
      this.lockTimer = setInterval(() => {
        lockResource(resourceID);
        this.getItemsLockListStatus();
      }, POLLING_LOCK_TIMER);
    }
  }

  componentDidMount() {
    this.props.onFetchCompanies();

    this.blockNavigation = this.props.history.block((location) => {
      const promptMessage = "Do you really want to leave this page ? You will lost all the current edition.";
      
      if (this.state.formIsUsed && !this.state.currentlySaving) {
        if(window.confirm(promptMessage)){
          if(this.props.mode === "EDIT"){
            unlockResource(this.props.groupId);
          }
          return true;

        } else {
          
          return false;
        }
      } else {
        if(this.props.mode === "EDIT")
          unlockResource(this.props.groupId);
      }
      return true;
    });

    if (this.props.ldap.people && this.props.ldap.people.length > 0 &&
      this.props.ldap.groups && this.props.ldap.groups.length > 0 &&
      this.props.ldap.configuration &&
      this.props.ldap.configuration.staffGroupId &&
      this.props.mode) {
      const groupId  = this.props.groupId;
      var groupSelected = null;

      if (groupId) {
        groupSelected = this.props.ldap.groups.filter(group => group.id == groupId)[0];
        this.checkLockStatus(groupSelected.id);
        this.getItemsLockListStatus();
      }

      var people = this.preparePeopleData(this.props.ldap.people, this.props.mode, groupSelected);

      var efpiaContacts = [];
      var efpiaContactGroup = this.props.ldap.groups.filter(group => group.id == this.props.ldap.configuration.staffGroupId)[0];
      efpiaContacts = efpiaContactGroup.members.map(person => person.id);

      var chairIds = groupSelected && groupSelected.chairs ? groupSelected.chairs.map(chair =>  chair.id ): [];

      var viceChairIds = groupSelected && groupSelected.viceChairs ? groupSelected.viceChairs.map(chair =>  chair.id ): [];

      var editors = groupSelected && groupSelected.editors ? groupSelected.editors : [];

      var groupEmailDomain = "@" + this.props.ldap.configuration.groupEmailDomain;

      this.setState({
        people: people,
        peopleInGroup : this.getPeopleInGroup(people, chairIds, viceChairIds),
        groupName : groupSelected ? groupSelected.name : '',
        groupId : groupSelected ? groupSelected.id : '',
        group : {
          name : groupSelected ? groupSelected.name : '',
          type : groupSelected ? groupSelected.type : '',
          email : groupSelected ? groupSelected.email : '',
          abbreviation : groupSelected ? groupSelected.email.toLowerCase().replace(groupEmailDomain, '') : '',
          efpiaContact : groupSelected && groupSelected.efpiaContact ? groupSelected.efpiaContact.id : '',
          description : groupSelected && groupSelected.description ? groupSelected.description : '',
          chairs : chairIds,
          viceChairs : viceChairIds,
          members : groupSelected ? groupSelected.members : '',
          editors : editors
        },
        groups : this.props.ldap.groups,
        efpiaContacts :  efpiaContacts,
        configuration : this.props.ldap.configuration,
        currentMembersIds: groupSelected && groupSelected.members ? groupSelected.members.map(contact => contact.id) : []

      });

      if(this.props.mode === "EDIT"){
        clearInterval(this.lockTimer);
        this.lockTimer = null;
        lockResource(groupId);
        this.getItemsLockListStatus();
        this.lockTimer = setInterval(() => {
          lockResource(groupId);
        }, POLLING_LOCK_TIMER);
      }
    }
  }

  componentWillUnmount() {
    this.blockNavigation();
    this.clearLockTimer();
  }

  clearLockTimer(){
    clearInterval(this.lockTimer);
    this.lockTimer = null;
  }

  checkLockStatus(resourceID){
    getLockStatus(resourceID)
    .then((status) => {
      if(status.isLocked && this.state.current_user.uid !== status.authUsername){
        toast(resourceID + " is currently edited by another user", {type:"error"});
        this.props.history.push(pathname + 'group/details/' + encodeURIComponent(resourceID));
      }
      else{
        lockResource(resourceID);
      }
    });
  }

  getItemsLockListStatus(){
    var members = this.props.ldap.groups.filter(group => group.id == this.props.groupId)[0].members;
    var resourceIDS = members.map(item => item.id );
    var self = this ;
    getLockListStatus(resourceIDS)
    .then((listStatus) => {
      self.setState({
        itemsLocked : listStatus
      });
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { people, groups, configuration, companies } = nextProps.ldap;

    if (groups && people && configuration && companies && (groups !== prevState.groups || people !== prevState.people || configuration !== prevState.configuration)) {
      companies.forEach(company => {
        company.members = [];
        people.forEach(contact => {
          if(contact.company === company.id){
            company.members.push(contact);
          }
        });
      });

      return { people, groups, configuration, companies }
    }
    return null;
  }

  preparePeopleData(people, mode, groupToEdit){
   var self = this;
   var peopleFormatted = people.map(person => {

     person.fullname = person.lastname + " " + person.firstname;
     person.newContactSelected = false;

     if(mode == "EDIT"){
       person.isSelected = groupToEdit.members.filter(personInGroup => personInGroup.id == person.id).length > 0;
     } else {
       person.isSelected = false;

     }
      return person;
    });

    return peopleFormatted;
  }

  updateContactIsSelectedInGroups(groups, personSelected){

    var personSelectedGroupIDs = personSelected.groups.map(group => {return group.id});

    groups.forEach(group => {
      if(personSelectedGroupIDs.includes(group.id)){
        group.members.forEach((contact) => {
          if(contact.id == personSelected.id){
            contact.isSelected = personSelected.isSelected;
          }
        });
      }
    });
    return groups;
  }

  onFormSubmitEditGroup(event) {
    this.checkLockStatus(this.state.groupId);
    this.props.loadingOverlayCb(true);
    this.setState({isLoading : true, currentlySaving: true});

    var group = {};
    group = Object.assign(group, this.state.group);
    group.members = this.state.peopleInGroup.map(person => person.id);

    var groupFormEditorsIsValid = Array.isArray(this.state.editors);
    if(groupFormEditorsIsValid){
      group.editors = this.state.editors.length > 0 ? this.state.editors.map(contact => { return contact.id }) : [];
    } else {
      group.editors = group.editors && group.editors.length && this.state.current_user.perm === "ADMIN" ? group.editors.map(contact => { return contact.id }) : null;
    }

    group.description = this.state.group.description ? this.state.group.description : group.name;

    var dataToSend = {
      id : this.state.groupId,
      data : group
    };

    this.props.textLoadingOverlay("Updating " + this.state.groupId + "...");
    this.props.loadingOverlayCb(true);

    this.props.onUpdateGroup(dataToSend)
    .then(this.props.onFetchGroups)
    .then(this.props.onFetchPeople)
    .then(() => {
      toast(group.name + " has been saved !", {type:"success"});
      unlockResource(dataToSend.id);
      this.props.history.push( pathname + 'group/details/'+ encodeURIComponent(group.abbreviation));
    })
    .catch(err => toast(err.response.data ? err.response.data : err.message, {type:"error",autoClose: false})  )
    .finally(() => {
      this.setState({isLoading : false});
      this.props.loadingOverlayCb(false);
    });

    if(event){
      event.preventDefault();
    }
  }

  onFormSubmitCreateGroup(event) {

    this.setState({isLoading : true, currentlySaving: true});

    var group = {};
    group = Object.assign(group, this.state.group);
    group.members = this.state.peopleInGroup.map(person=>person.id);
    group.description = group.name;

    this.props.textLoadingOverlay("Creating " + group.name + '...');
    this.props.loadingOverlayCb(true);

    this.props.onCreateGroup(group)
    .then(this.props.onFetchGroups)
    .then(this.props.onFetchPeople)
    .then(() => {
      toast(group.name + " has been created !", {type:"success"});
      this.props.history.push( pathname + 'group/details/'+ encodeURIComponent(group.abbreviation));
    })
    .catch(err => toast(err.response.data ? err.response.data : err.message, {type:"error", autoClose: false}))
    .finally(() => {
        this.setState({isLoading : false});
        this.props.loadingOverlayCb(false);
    });

    event.preventDefault();
  }

  deleteGroup = (group) => {

    this.modaleDelete();
    this.setState({isLoading : true, currentlySaving: true});

    this.props.textLoadingOverlay("Deleting " + group + "...");
    this.props.loadingOverlayCb(true);

    const data = {
      id: group
    };

    this.props.onDeleteGroup(data)
    .then(this.props.onFetchPeople)
    .then(this.props.onFetchGroups)
    .then(() => {
          toast(`${data.id} has been successfully removed from directory`, { type:"success" });
          this.props.history.push( pathname + 'group');
    })
    .catch(err => toast(err.response.data ? err.response.data : err.message, {type:"error", autoClose: false}))
    .finally(() => {
      this.setState({isLoading : false});
      this.props.loadingOverlayCb(false);
    });
  }

  getPeopleSelected(people){
    return people.filter( function (person) {
      return person.isSelected;
    });
  }

  handleChange(event) {

    var evtValue = event.target.value.replace(/[\s]{2,}/g," ");

    var newState = {
        ...this.state,
        formIsUsed: true,
        group : {
          name: this.state.group.name,
          abbreviation: event.target.name === 'email' ? evtValue.toLowerCase().replace(groupEmailDomain, '') : this.state.group.abbreviation,
          type: event.target.name === 'type' ? evtValue : this.state.group.type,
          email: this.state.group.email,
          efpiaContact: event.target.name === 'efpiaContact' ? evtValue : this.state.group.efpiaContact,
          chairs: this.state.group.chairs,
          viceChairs: this.state.group.viceChairs,
          editors : this.state.group.editors ? this.state.group.editors : []
        }
    };

    if(event.target.name === 'efpiaContact'){
      var peopleUpdated = this.turnContactSelectedInPeopleList(this.state.people, evtValue, true);
      newState.peopleInGroup = this.getPeopleInGroup(peopleUpdated, this.state.group.chairs, this.state.group.viceChairs);
    }
    this.setState(newState);
    event.preventDefault();
  }

  turnContactSelectedInPeopleList(people, contactId, isEFPIAContact){
    if(contactId){
      var contact = people.filter(contact => contact.id == contactId)[0];
      if(contact) { contact.isSelected = true; }

      if(isEFPIAContact){ contact.newContactSelected = true; }
    }

    return people;
  }

  emailValidCb(email){
    var prevGroupUdated = this.state.group;
    prevGroupUdated.email = email;
    var groupEmailDomain = "@" + this.props.ldap.configuration.groupEmailDomain;
    prevGroupUdated.abbreviation = email.replace(groupEmailDomain, '');
    this.setState({group: prevGroupUdated,
                   modal:false,
                   formIsUsed : true});
  }

  nameValidCb(groupName){
    var prevGroupWithNewName = this.state.group;
    prevGroupWithNewName.name = groupName;

    this.setState({
      group : prevGroupWithNewName,
      formIsUsed : true
    });
  }

  getPeopleInGroup(people, chairs, viceChairs){
    var peopleInGroup = this.getPeopleSelected(people);
    return this.addChairsInPeople(peopleInGroup, chairs, viceChairs);
  }

  addChairsInPeople(people, chairs, viceChairs){
    if(!chairs && !viceChairs){
      return people;
    }

    return people.map(function (person){
      person.role = '';

      if(chairs.some(chair => chair === person.id)){
        person.role = "Chair";
      }

      if(viceChairs.some(viceChair => viceChair === person.id)){
        person.role = "Vice-chair";
      }

      return person;
    });
  }

  modaleDelete(){
    this.setState({
      modalDeleteIsOpen : !this.state.modalDeleteIsOpen
    });
  }

  formAction = () => {
    if(!this.state.isLoading){
      switch(this.props.mode) {
       case "CREATE":    return this.onFormSubmitCreateGroup;
       case "EDIT":      return this.onFormSubmitEditGroup;
       default:          return null;
      }
    }
  }

  displayBoxInfoModal(){
    this.setState({
      displayBoxInfoModal : !this.state.displayBoxInfoModal
    });
  }

  formCanBeSubmit = () => {
   var peopleInGroup = this.state.peopleInGroup;
   var groupFormDetailIsValid = this.state.groupFormDetailIsValid;
   var efpiaContactIsValid = this.state.group.efpiaContact.length > 0;

   var groupType = this.state.group.type;

   var groupTypeIsValid = groupType && groupType.length > 0 && GroupValidationSchema.properties.type.enum.some(SchemaType => { return SchemaType === groupType});

   var groupFormEditorsIsValid = this.state.editors ? this.state.groupFormEditorsIsValid : true;
   var emailIsValid = this.state.group.email.includes(this.props.ldap.configuration.groupEmailDomain) && !this.state.group.email.includes("+") && !this.state.group.email.includes("=");

   var defaultCheck = this.state.formIsUsed && groupFormDetailIsValid && groupTypeIsValid
                                            && peopleInGroup && peopleInGroup.length > 0 && efpiaContactIsValid
                                            && groupFormEditorsIsValid && emailIsValid;

   return defaultCheck;
  }

  getGroupsWithPeopleUnselected(groups, groupId){

    var groupFilter = groups.filter((group) => {

      return group.id != groupId
    });
    return groupFilter;
  }

  selectGroupMembersAction(groupSelected){

    var contactIdsToSelect = groupSelected.members.map((contact) => { return contact.id });

    var people = this.state.people;
    people.forEach((contact) => {

      if(contact.isSelected == false){
        contact.isSelected = contactIdsToSelect.includes(contact.id);
        contact.newContactSelected = contactIdsToSelect.includes(contact.id);
      }

    });

    this.setState({
      formIsUsed : true,
      people: people,
      peopleInGroup : this.getPeopleInGroup(people, this.state.group.chairs, this.state.group.viceChairs),
      people : people
    });
  }

  checkMemberStatus(contactId, addedInGroup, removedInGroup ){

    var contactRemoved = false;
    var contactAdded = false;

    if(this.props.mode === "EDIT"){

      var currentMembersIds = this.state.currentMembersIds;

      if(currentMembersIds.indexOf(contactId) > -1){
        contactRemoved = !addedInGroup || removedInGroup;
        contactAdded = this.state.haveContactAdded;
      }

      if(currentMembersIds.indexOf(contactId) <= -1){
        contactRemoved = this.state.haveContactDeleted;
        contactAdded = addedInGroup || !removedInGroup;
      }
    }

    return { contactRemoved, contactAdded }
  }

  unselectPersonAction(contact){

     contact.isSelected = false;
     contact.newContactSelected = false;

     var newState = { formIsUsed: true };
     var people = this.updatePeopleSelected(this.state.people, contact);
     var groups = this.state.groups;

     if(!contact.isSelected){
       var groupUpdated = this.state.group;

       if(groupUpdated.chairs.some(chairId => chairId === contact.id) ){
         groupUpdated.chairs = groupUpdated.chairs.filter(chair => chair !== contact.id);
       }

       if(groupUpdated.viceChairs.some(chairId => chairId === contact.id) ){
         groupUpdated.viceChairs = groupUpdated.viceChairs.filter(chair => chair !== contact.id);
       }
       newState.group = groupUpdated;
     }

     newState.peopleInGroup = this.getPeopleInGroup(people, groupUpdated.chairs, groupUpdated.viceChairs);
     newState.groups = this.updateContactIsSelectedInGroups(groups, contact);
     newState.people = people;

     var contactStatus = this.checkMemberStatus(contact.id, false, true);
     newState.haveContactDeleted = contactStatus.contactRemoved;
     newState.haveContactAdded = contactStatus.contactAdded;


     this.setState( newState );
   }

  selectPersonAction(contact){

   contact.isSelected = true;
   contact.newContactSelected = true;

   var people = this.updatePeopleSelected(this.state.people, contact);
   var groups = this.state.groups;

   var newState = { formIsUsed: true };


   newState.peopleInGroup = this.getPeopleInGroup(people, this.state.group.chairs, this.state.group.viceChairs);
   newState.groups = this.updateContactIsSelectedInGroups(groups, contact);
   newState.people = people;


   var contactStatus = this.checkMemberStatus(contact.id, true, false);
   newState.haveContactDeleted = contactStatus.contactRemoved;
   newState.haveContactAdded = contactStatus.contactAdded;


   this.setState( newState );

   setTimeout(() => {
     var elmnt = document.getElementById(contact.id);
     elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
   }, 100);

 }

  updatePeopleSelected(people, contactSelected){
    people.forEach((contact)=> {
      if(contact.id == contactSelected.id){
        contact.isSelected = contactSelected.isSelected ;
        contact.newContactSelected = contactSelected.newContactSelected;
      }
    });
    return people;
  }

  cancel() {
    this.props.history.goBack();
  }

  addChairLabel(contactSelectedId, label){

    var group = this.state.group;

    var contactAlreadyChair = false;

    switch (label) {
      case "Chair": contactAlreadyChair = !group.chairs.some(chairId => chairId === contactSelectedId);
                    if(contactAlreadyChair) group.chairs.push(contactSelectedId);
        break;
      case "Vice-chair": contactAlreadyChair = !group.viceChairs.some(chairId => chairId === contactSelectedId);
                         if(contactAlreadyChair) group.viceChairs.push(contactSelectedId);
        break;
      default:
    }

    var peopleUpdated = this.state.people;
    var peopleInGroup = this.state.peopleInGroup;

    if(contactAlreadyChair){
       peopleUpdated = this.turnContactSelectedInPeopleList(this.state.people, group.chairs);
       peopleInGroup = this.getPeopleInGroup(peopleUpdated, group.chairs, group.viceChairs);

       this.setState({
         formIsUsed : true,
         group : group,
         peopleInGroup : peopleInGroup
       });
     }
  }

  removeChairLabel(contactSelectedId, label){

    var group = this.state.group;

    var canRemoveContactChair = false;

    switch (label) {
      case "Chair": canRemoveContactChair = group.chairs.some(chairId => chairId === contactSelectedId);
                    if(canRemoveContactChair) group.chairs = group.chairs.filter(chairId =>  chairId !== contactSelectedId);
        break;
      case "Vice-chair": canRemoveContactChair = group.viceChairs.some(chairId => chairId === contactSelectedId);
                         if(canRemoveContactChair) group.viceChairs = group.viceChairs.filter(chairId =>  chairId !== contactSelectedId);
        break;
      default:
    }

    var peopleUpdated = this.state.people;
    var peopleInGroup = this.state.peopleInGroup;

    if(canRemoveContactChair){

      peopleUpdated = this.turnContactSelectedInPeopleList(this.state.people, group.chairs);
      peopleInGroup = this.getPeopleInGroup(peopleUpdated, group.chairs, group.viceChairs);

      this.setState({
        formIsUsed : true,
        group : group,
        peopleInGroup : peopleInGroup
      });
    }
  }

  render (){
    if (!this.state.people || !this.state.groups || !this.state.efpiaContacts || !this.state.configuration) {
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

    var mode = this.props.mode;

    var peopleInGroup = this.state.peopleInGroup;

    var displayGroupFormEditors = this.props.ldap.configuration.staffGroupId !== this.props.groupId && this.state.current_user.perm === "ADMIN" && mode === "EDIT" && this.state.group.editors;

    var boxModule = this.props.ldap.configuration.boxModule;

    return (
      <Container fluid={true}>

      <Form onSubmit={this.formAction()} className="h-100 m-0 overflow-auto">
        <Header 
          mode={mode}
          groupName={this.state.groupName}
          formCanBeSubmit={this.formCanBeSubmit()}
          formIsUsed={this.state.formIsUsed}
          groupId={this.props.groupId}
          saveAction={this.displayBoxInfoModal}
          defaultSaveAction={!this.state.haveContactDeleted && !this.state.haveContactAdded}
          modaleDelete={this.modaleDelete}
          closeCb={this.cancel}
          isLoading={this.state.isLoading}
          itemsLocked={this.state.itemsLocked}
        />

         <div className="displayFlex" style={{height: "calc(100% - 130px)"}}>
           <div className="col-xxl-5">
             <GroupFormDetail mode={mode}
                              group={this.state.group}
                              current_user={this.state.current_user}
                              efpiaContacts={this.state.efpiaContacts}
                              nameValidCb={this.nameValidCb}
                              formIsUsed={this.state.formIsUsed}
                              handleChange={this.handleChange}
                              groupEmailDomain={"@" + this.props.ldap.configuration.groupEmailDomain}
                              boxCanBeSubmit={this.groupFormDetailCanBeSubmit}
                              emailValidCb={this.emailValidCb} />

             {displayGroupFormEditors
               ? <GroupFormEditors editors={this.state.group.editors}
                                   boxCanBeSubmit={this.groupFormEditorsCanBeSubmit}
                                   staffGroup={this.state.groups.filter(group => group.id === this.props.ldap.configuration.staffGroupId)[0]}/>
               : null
             }

           </div>

           <div className="col-xxl-7 h-100">
             <MembershipsWidget people={this.state.people}
                                group={this.state.group}
                                peopleInGroup={peopleInGroup}
                                addLabelAction={this.addChairLabel}
                                removeLabelAction={this.removeChairLabel}
                                action={this.unselectPersonAction}
                                labelAction={this.addLabel}
                                modeView={this.props.mode}
                                companies={this.props.ldap.companies}
                                selectGroupMembersAction={this.selectGroupMembersAction}
                                personAction={this.selectPersonAction}
                                groups={this.getGroupsWithPeopleUnselected(this.state.groups, this.props.groupId)}/>
             </div>
           </div>

           {this.props.mode == "EDIT"
            ? <BoxModalComponent 
                isOpen={this.state.displayBoxInfoModal && (this.state.haveContactAdded || this.state.haveContactDeleted)}
                boxModule={boxModule}
                successCb={this.onFormSubmitEditGroup} 
                isLoading={this.state.isLoading}
              />
            : null
          }
      </Form>
      {this.props.mode == "EDIT" 
        ? <ModalGroupDelete 
            isOpen={this.state.modalDeleteIsOpen}
            groupId={this.props.groupId}
            toggle={this.modaleDelete}
            successCb={!this.state.isLoading ? this.deleteGroup : null} 
            isLoading={this.state.isLoading}
          /> 
        : null
      }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onUpdateGroup: (data) => dispatch(updateGroup(data)),
  onDeleteGroup: (data) => dispatch(deleteGroup(data)),
  onFetchGroups: () => dispatch(fetchGroups()),
  onFetchPeople: () => dispatch(fetchPeople()),
  onFetchCompanies: () => dispatch(fetchCompanies()),
  onCreateGroup : (data) => dispatch(createGroup(data))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupEditView);
