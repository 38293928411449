import * as React from "react";
import { Table, ListGroup } from 'reactstrap';
import InfiniteScroll from "react-infinite-scroller";

const InfiniteScrollWrapper = React.forwardRef((props, ref) => {

    var contentToDisplay;
    if(props.type === "LIST"){
      contentToDisplay = (<ListGroup className="listEdit" style={{height : "100%"}}>
        {props.items}
      </ListGroup>);
    }
    else {
      contentToDisplay = (<Table hover className={props.displayHead ? "stickyHeaderTable table-striped" : "table-striped"}>
        {props.displayHead ? (<thead className="thead-dark"><tr>{props.tableHeaderCells}</tr></thead>): null}
        <tbody>
          {props.items}
        </tbody>
      </Table>);
    }
    return (
        <InfiniteScroll
            className="infinite-scroll-container"
            threshold={500}
            initialLoad={false}
            pageStart={0}
            isReverse={props.isReverse}
            loadMore={props.getNewData}
            hasMore={props.hasMore}
            loader={props.loaderComponent}
            useWindow={false}
            getScrollParent={ref}
        >
            {contentToDisplay}
        </InfiniteScroll>
    );
});

export default InfiniteScrollWrapper;
