import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormText } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEdit } from '@fortawesome/free-solid-svg-icons';

import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

const MAX_CHARS = 150;
import { matchCompanynameInvalidChars } from "../SubComponent/fieldsValidators";
import AutosuggestComponent from '../SubComponent/AutosuggestComponent';

class ModalCompanyEditView extends React.Component {
  constructor(props) {
    super(props);
    this.saveAction = this.saveAction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
    this.EditCompany = this.EditCompany.bind(this);

    this.state = {
      isOpen : props.isOpen,
      company : props.company,
      companies : props.ldap.companies,
      mode : props.mode,
      formIsUsed : false,
      canBeEditField : false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var isOpen = nextProps.isOpen;
    const {companies} = nextProps.ldap;

    if (isOpen && companies && (isOpen !== prevState.isOpen || companies !== prevState.companies ) ) {
      return {companies : companies, isOpen : isOpen, canBeEditField: false }
    }
    return null;
  }

  componentDidMount(){
    this.setState({
      canBeEditField : false
    })
  }

  handleChange(value) {
     this.setState({
           company: value ? value.replace(/[\s]{2,}/g," ") : '',
           formIsUsed : true
     });
  }

  companyAlreadyExistInDb(comanies, companyValue){

    var isExistInDb = false;

    companyValue = companyValue ? companyValue.toLowerCase().replace(/[^a-z0-9]/gi, '') : '';

    var companyExistInDb = comanies.filter(function(company){
      return company.name.toLowerCase().replace(/[^a-z0-9]/gi, '') == companyValue;
    });

    if(this.props.mode === "EDIT" && this.props.company.toLowerCase().replace(/[^a-z0-9]/gi, '') === companyValue.toLowerCase().replace(/[^a-z0-9]/gi, '')){
      isExistInDb = false;
    }
    else {
      isExistInDb = companyExistInDb.length > 0;
    }

    return isExistInDb;
  }

  resetCompanyField(){
    this.setState({company:'', });
  }

  cancelAction(){
    this.resetCompanyField();
    this.props.cancelCb();
    this.setState({canBeEditField: false})
  }

  saveAction(){
    if(!this.props.isLoading){
      this.resetCompanyField();
      this.props.successCb(this.state.company);
    }
  }

  EditCompany(){
    this.setState({
      canBeEditField : true
    });
  }

  render() {
    var companyAlreadyExist = this.companyAlreadyExistInDb(this.state.companies, this.state.company);

    var menuStyle = {
                      zIndex : '99',
                      borderRadius: '3px',
                      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                      background: 'rgba(255, 255, 255, 0.9)',
                      padding: '2px 0',
                      fontSize: '90%',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: '60%',
                      top: '0px',
                      left: '0px'
                    };

    var companyHaveInvalidChars = this.state.company && matchCompanynameInvalidChars(this.state.company) ?  matchCompanynameInvalidChars(this.state.company).length > 0 : false;
    var companyIsToLong = this.state.company.length > MAX_CHARS;

    var defaultValidator = this.state.formIsUsed && !companyAlreadyExist && !companyHaveInvalidChars && !companyIsToLong && this.state.company.length > 0;

    var formCanBeSubmit = defaultValidator;
    var mode = this.state.mode;

    if(this.props.company && mode === "EDIT"){
      formCanBeSubmit = defaultValidator && this.state.company.trim() !== this.props.company.trim();
    }
    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.cancelAction} className="panelModal emailModal">
          <ModalHeader toggle={this.cancelAction} ><FontAwesomeIcon icon={faEdit} className="font-size-40"/> <span>{this.props.titleModal}</span></ModalHeader>
          <ModalBody className="companyEdit">

          {!this.state.canBeEditField
            ? <h4>Are you sure you want to {this.state.company ? "edit this company " : "create new company"} ?</h4>
            : <>
                <Label for="Email" className="required">Company name</Label>


                  <AutosuggestComponent
                    items={this.state.companies}
                    placeholder={'Company name...'}
                    onChange={this.handleChange}
                    inputValue={this.state.company}
                    isInvalid={(companyAlreadyExist && this.state.company !== this.props.company) || companyHaveInvalidChars || companyIsToLong}
                  />




                  {(companyAlreadyExist && this.state.company !== this.props.company) || companyHaveInvalidChars || companyIsToLong
                    ? <FormText className="pb-3 text-left">
                        {companyAlreadyExist && this.state.company !== this.props.company ? <span className="d-block">{this.state.company} already exist </span> : null}
                        {companyHaveInvalidChars ? <span className="d-block">Characters not allowed {matchCompanynameInvalidChars(this.state.company).join(" ")}</span> : null}
                        {companyIsToLong ? <span className="d-block">Company name is too long. Max {MAX_CHARS} allowed.</span> : null}
                      </FormText>
                    : null
                  }
              </> }

            <p className="colorRed"><FontAwesomeIcon icon={faExclamationTriangle}/> Do not create derivative name of a company<br/>(like Firm. inc. instead of Firm), use existing ones !</p>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.cancelAction}>Cancel</Button>
            {!this.state.canBeEditField 
              ? <Button 
                  color="primary" 
                  onClick={this.EditCompany}>
                  Continue
                </Button> 
              : null
            }
            {this.state.canBeEditField 
              ? <Button 
                  color="primary" 
                  disabled={!formCanBeSubmit || this.props.isLoading} 
                  onClick={this.saveAction}>
                  Save
                </Button> 
              : null
            }
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ModalCompanyEditView);
