import React from 'react';

import { ListGroup, ListGroupItem } from 'reactstrap';
import TooltipComponent from '../TooltipComponent';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faEnvelope, faPhoneAlt, faMobileAlt, faBriefcase, faBuilding, faMapMarkerAlt, faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { getCountry } from './Country-List/countriesOverrided';

import { getURLPrefix } from '../../utils/baseURLService.js';
var pathname = getURLPrefix().pathname;

class ContactAddressCard extends React.Component{
  constructor(props){
    super(props);

    this.truncateItem = React.createRef();

    this.state = {
      addressCardWidth : ""
    }

  }

  componentDidMount () {
    this.setState({
      addressCardWidth: this.truncateItem.current.offsetWidth
    });
  }

  render(){
    const {
      contact,
      className
    } = this.props;

    var addressCardWidth = this.state.addressCardWidth > 450 ? "panelBoxBody" : "panelBoxBody addressCardSmallSize";

    var postalAddress = contact.postalAddress ? contact.postalAddress.replace(/\\n/g, '\n').replace(/\$/g, '\n'): '';

    var countryName = getCountry(contact.country);


    var departmentNumber = contact.departmentNumber ? contact.departmentNumber + ' ' : '';

    return (
      <div className={className + " panelBoxSection"} style={{height: "calc(100% - 3rem)"}}>
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faAddressCard}/>
          </div>
          <h2>Address Card</h2>
        </div>
        <div ref={this.truncateItem} className={addressCardWidth}>
          <ListGroup flush className="contactDetailList">

              <ListGroupItem className="infoBox">
                  <div className="infoBox-contentList" style={{width: "100%"}}>
                    <TooltipComponent icon={faEnvelope} text="Contact email" id="email"/>
                    <p className="text-truncate"><a href={"mailto:" + contact.email} target="_blank">{contact.email}</a></p>
                  </div>
              </ListGroupItem>

              {contact.phone || contact.mobile ? <ListGroupItem className="infoBox">

                {contact.phone ? <div className="infoBox-contentList">
                  <TooltipComponent icon={faPhoneAlt} text="Phone" id="faPhoneAlt"/>
                  <p><a href={"tel:" + contact.phone}>{contact.phone}</a></p>
                </div> : null}

                {contact.mobile ? <div className="infoBox-contentList">
                  <TooltipComponent icon={faMobileAlt} text="Mobile" id="faMobileAlt"/>
                  <p><a href={"tel:" + contact.mobile}>{contact.mobile}</a></p>
                </div> : null}

              </ListGroupItem> : null}


              <ListGroupItem className="infoBox">
                {contact.employeeType ? <div className="infoBox-contentList">
                  <TooltipComponent icon={faBriefcase} text="Job Title" id="jobTitle"/>
                  <p className="text-truncate">{contact.employeeType}</p>
                </div> : null}

                <div className="infoBox-contentList">
                  <TooltipComponent icon={faBuilding} text="Company" id="conmpany"/>
                  <p className={className === "contactAddressCard-groupMembers" ? "contactAddressCard-groupMembers-company" : null}><Link to={ pathname + "company/details/" + contact.company}>{contact.company}</Link></p>
                </div>
              </ListGroupItem>

              <ListGroupItem className="infoBox">
                {postalAddress || contact.departmentNumber || contact.postalCode || contact.city ?
                <div className="infoBox-contentList">
                  <TooltipComponent icon={faMapMarkerAlt} text="Postal Adress" id="postalAddress"/>
                  <p>{contact.departmentNumber ?
                    <span>{contact.departmentNumber}<br/></span> : null }
                    {postalAddress ? postalAddress : null}
                    {postalAddress ? <br/> : null}{contact.postalCode} {contact.city}</p>
                </div> : null}

                <div className="infoBox-contentList">
                  <TooltipComponent icon={faGlobeEurope} text="Country" id="country"/>
                  <p>{countryName}</p>
                </div>
              </ListGroupItem>

          </ListGroup>
        </div>
      </div>
    );
  }
}

export default ContactAddressCard;
