import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  ButtonToggle,
  ButtonGroup,
  Badge,
  FormText
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faUserPlus, faUserMinus, faUser, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import List from '../List/ListFactory';
import SearchComponent from '../Search/SearchComponent';
import ListWithDropdown from './ListWithDropdown';
import  EditList from '../EditList';
import { matchStringInObject } from '../../utils';


class MembershipsWidget extends React.Component {

  constructor(props){
    super(props);

    this.changeMode = this.changeMode.bind(this);
    this.onGroupsSearch = this.onGroupsSearch.bind(this);
    this.onPeopleSearch = this.onPeopleSearch.bind(this);
    this.onCompaniesSearch = this.onCompaniesSearch.bind(this);
    this.onSearchContactInGroup = this.onSearchContactInGroup.bind(this);
    this.personAction = this.personAction.bind(this);
    this.action = this.action.bind(this);

    this.state = {
      mode : 'CONTACT',
      people : props.people,
      groups : props.groups,
      companies: props.companies,
      peopleInGroup: props.peopleInGroup,
      groupStaffMembers: this.props.displayGroupStaff ? this.getGroupStaffMembers() : [],
      groupStaffMembersWithPeopleUnselected:  this.props.displayGroupStaff ? this.getGroupStaffMembers() : [],
      groupsWithPeopleUnselected : props.groups,
      peopleWithContactUnselected : props.people,
      companiesWithContactUnselected : props.companies,
      searchString : ''
    }

  }

  componentDidMount() {
    if (this.props.displayGroupStaff && this.props.people && this.props.people.length > 0 &&
      this.props.groups && this.props.groups.length > 0 &&
      this.props.ldap.configuration &&
      this.props.ldap.configuration.staffGroupId && this.props.group){
        this.setState({
          groupStaffMembers: this.getGroupStaffMembers(this.props.group.members)
        })
      }
  }

  changeMode(event){
    this.setState({
      mode : event.target.name
    });
  }

  onGroupsSearch ( searchString ) {
    this.setState({ groups: this.filterGroupBySearch(this.state.groupsWithPeopleUnselected, searchString)});
  }

  onPeopleSearch(searchString){
    this.setState({
      people : this.filterContactBySearch(this.state.peopleWithContactUnselected, searchString)
    });
  }

  onCompaniesSearch(searchString){
    this.setState({ companies: this.filterCompanyBySearch(this.state.companiesWithContactUnselected, searchString)});
  }

  filterContactBySearch(people, searchString){
    return people.filter((person) => {
      return matchStringInObject(searchString, ['fullname'], person);
    });

  }

  filterGroupBySearch(groups, searchString){

    return groups.filter((group) => {
      return matchStringInObject(searchString, ['name'], group);
    });
  }

  filterCompanyBySearch(companies, searchString){
    return companies.filter((company) => {
      return matchStringInObject(searchString, ['name'], company);
    });
  }

  onGroupsStaffSearch(searchString){
    this.setState({
      groupStaffMembers : this.filterContactBySearch(this.state.groupStaffMembersWithPeopleUnselected, searchString)
    });
  }

  onSearchChanged = (searchString) => {
    if(!this.props.displayGroupStaff){
     switch(this.state.mode) {

       case "CONTACT":    this.onPeopleSearch(searchString);
       break;
       case "GROUP":      this.onGroupsSearch(searchString);
       break;
       case "COMPANY":    this.onCompaniesSearch(searchString);
       break;
       default:          return null;
     }
   } else {
     this.onGroupsStaffSearch(searchString);
   }
  }

  getGroupStaffMembers(peopleInGroup){

    var groupStaff = this.props.groups.filter(group => group.id === this.props.ldap.configuration.staffGroupId)[0];

    groupStaff.members = groupStaff.members.map(contact => {

      if(typeof(contact) === "string"){
        contact = this.props.people.filter(person => person.id === contact)[0];
      }

      if(typeof(contact) === "object" && peopleInGroup && peopleInGroup.length){
        contact.newContactSelected = false;
        contact.isSelected = false;

        contact.isSelected = peopleInGroup.some(person => person.id === contact.id);
      }

      return contact;
    });

    return groupStaff.members ;
  }

  onSearchContactInGroup(searchString){
    this.setState({
      searchString: searchString
    });
  }

  personAction(contactSelected){
    this.props.personAction(contactSelected);

    if(this.props.displayGroupStaff){
      var peopleInGroup = this.props.peopleInGroup;
      peopleInGroup.push(contactSelected);

      this.setState({
        groupStaffMembers: this.getGroupStaffMembers(peopleInGroup)
      });
    }
  }

  action(contactSelected){
    this.props.action(contactSelected);

    if(this.props.displayGroupStaff){
      var peopleInGroup = this.props.peopleInGroup.filter(person => person.id !== contactSelected.id);

      this.setState({
        groupStaffMembers: this.getGroupStaffMembers(peopleInGroup)
      });
    }
  }

  render() {
    var searchString = this.state.searchString;
    var peopleNotInGroup = this.state.people.filter(contact => !contact.isSelected);
    var peopleInGroup = this.filterContactBySearch(this.props.peopleInGroup, searchString);

    var groupStaffMembers = this.state.groupStaffMembers;

    var viewList = () => {
      if(!this.props.displayGroupStaff){
        switch(this.state.mode) {
          case "CONTACT":    return <EditList mode="CONTACT"
                                              style={{padding: "0", height : "100%"}}
                                              items={peopleNotInGroup}
                                              hiddeItem={true}
                                              sortKey="lastname"
                                              icon={faUserPlus}
                                              action={this.props.personAction} />;
          break;
          case "GROUP":      return <ListWithDropdown groups={this.state.groups}
                                                                  personAction={this.props.personAction}
                                                                  selectGroupMembersAction={this.props.selectGroupMembersAction}
                                                                  sortGroupKey="name"
                                                                  sortContactKey="lastname"/>;
          break;
          case "COMPANY":   return <ListWithDropdown  groups={this.state.companies}
                                                      personAction={this.props.personAction}
                                                      selectGroupMembersAction={this.props.selectGroupMembersAction}
                                                      sortGroupKey="name"
                                                      sortContactKey="lastname"/>;
          break;

          default:          return <EditList mode="CONTACT"
                                              style={{padding: "0", height : "100%"}}
                                              items={peopleNotInGroup}
                                              hiddeItem={true}
                                              sortKey="lastname"
                                              icon={faUserPlus}
                                              action={this.props.personAction} />;
        }
      } else {
        return <EditList mode="CONTACT"
                        style={{padding: "0", height : "100%"}}
                        items={groupStaffMembers}
                        hiddeItem={true}
                        sortKey="lastname"
                        icon={faUserPlus}
                        action={this.personAction} />;
      }
    }

    var noPeopleSelectedMsg = this.props.peopleInGroup.length == 0 ? "You need to select at least one person" : "";

    return(
      <div className="panelBoxSection">
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faUser}/>
          </div>
          <h2 style={{marginBottom: noPeopleSelectedMsg ? "-8px" : "8px"}}>Group Memberships</h2>
          {noPeopleSelectedMsg ? <small className="form-text text-muted colorRed"><FontAwesomeIcon icon={faExclamationTriangle}/> {noPeopleSelectedMsg}</small> : null}
        </div>


        <div className="panelBoxBody">

          <Row className="editMembershipsWidget" style={{ height: noPeopleSelectedMsg ? "calc(100% - 62px)" : "calc(100% - 43px)"}}>

            <Col xs={6} className="group-edit-memberShips">
              <div className="membershipsWidgetDescription">
                <p>Group's member <Badge color="secondary">{this.props.peopleInGroup.length > 0 ? this.props.peopleInGroup.length : "0"}</Badge></p>
                <small className="form-text text-muted">Click on <FontAwesomeIcon icon={faUserMinus}/> to remove the contact from the group (contact is NOT deleted from LPO).</small>
                <Row>
                  <Col>
                    <SearchComponent onSearchChanged={this.onSearchContactInGroup}/>
                  </Col>
                </Row>
              </div>
              <div className="membershipsWidgetList">
              { this.props.addLabelAction
                ? <>
                    {(this.props.modeView === "EDIT" && peopleInGroup.length !== 0) || this.props.modeView === "CREATE"
                      ? <EditList
                                items={peopleInGroup}
                                listType="select"
                                icon={faUserMinus}
                                sortKey="lastname"
                                mode="CONTACT"
                                modeView={this.props.modeView}
                                chairs={this.props.group.chairs}
                                viceChairs={this.props.group.viceChairs}
                                addLabelAction={this.props.addLabelAction}
                                removeLabelAction={this.props.removeLabelAction}
                                action={this.props.action}
                                labelAction={this.props.labelAction}
                              />
                      : <p>Loading...</p>
                     }
                 </>
                : <EditList
                    items={peopleInGroup}
                    listType="select"
                    icon={faUserMinus}
                    sortKey="lastname"
                    mode="CONTACT"
                    action={this.action}
                  />
              }
             </div>
            </Col>

            <Col xs={6} className="group-edit-memberShips otherGroup text-white pl-3">
              <div className={!this.props.displayGroupStaff ? "membershipsWidgetDescription h-220" : "membershipsWidgetDescription"}>
                <p>Search in {!this.props.displayGroupStaff ? "the contacts directory" : "staff group"}</p>
                <small className="form-text text-muted">Click on <FontAwesomeIcon icon={faUserPlus} style={{color : "#9ACA3C"}}/> to add contact{this.props.displayGroupStaff
                  ? "."
                  : <> or click on <FontAwesomeIcon icon={faPlusCircle} style={{color : "#9ACA3C"}}/> all contacts of a group.</>
                }</small>
                <Row className="m-0">
                  <Col xs={12} className="p-0">
                    <SearchComponent onSearchChanged={this.onSearchChanged}/>
                  </Col>
                {!this.props.displayGroupStaff
                  ?  <Col xs={12} className="p-0">
                        <ButtonGroup className="mt-3 w-100">
                          <Button color={this.state.mode == 'CONTACT' ? "primary" : "secondary"} onClick={this.changeMode} name='CONTACT' active={this.state.mode == 'CONTACT'}>Contacts</Button>
                          <Button color={this.state.mode == 'GROUP' ? "primary" : "secondary"} onClick={this.changeMode} name='GROUP' active={this.state.mode == 'GROUP'}>Groups</Button>
                          <Button color={this.state.mode == 'COMPANY' ? "primary" : "secondary"} onClick={this.changeMode} name='COMPANY' active={this.state.mode == 'COMPANY'}>Companies</Button>
                        </ButtonGroup>
                      </Col>
                  : null}

                </Row>
              </div>
              <div className={!this.props.displayGroupStaff ? "membershipsWidgetList h-100-220" : "membershipsWidgetList"}>
              {viewList()}
              </div>
            </Col>
          </Row>

          <FormText><span className="text-danger">*</span> Required fields</FormText>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap
});


export default compose(
  connect(mapStateToProps)
)(MembershipsWidget);
