import React from 'react';
import {
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';



class OrphanContactComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var orphanContacts = this.props.orphanContacts;

    var orphanContactsItems = orphanContacts.map((item) =>
       <ListGroupItem key={item.id}>
         <div class="d-flex justify-content-between">
           <span className="w-60 text-truncate">
             <FontAwesomeIcon icon={faUser}/> <span className="cell-lastname">{item.lastname}</span> <span className="cell-firstname">{item.firstname}</span>
           </span>
           <span className="w-40 text-truncate">
             <FontAwesomeIcon icon={faBuilding}/> {item.company}
           </span>
         </div>
       </ListGroupItem>
     );
    return (
      <div className="orphanContactComponent">
          <h4>Deleting this group will produce {orphanContacts.length} contacts without any group membership (orphan contacts) :</h4>

          <ListGroup flush className="orphanContactsList">
            {orphanContactsItems}
          </ListGroup>
          <h4 className="titleWithLine">Orphan contacts are not allowed.</h4>
          <p>Please add the contacts you want to keep to other groups before deleting the group {this.props.groupId}. You can export the list of related contacts in your clipboard below or delete all the would be orphan contacts.</p>
          <h2>What do you want to do ?</h2>
      </div>
    );
  }
}

export default OrphanContactComponent;
