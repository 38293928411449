import React from 'react';

import ErrorView from '../ErrorBoundary/ErrorView';

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

      componentDidCatch(error, errorInfo) {
        this.setState({
          error: error,
          errorInfo: errorInfo
        });
      }

      render() {
        if (this.state.errorInfo) {
          return (
            <ErrorView error={this.state.error} errorInfo={this.state.errorInfo}/>
          );
        }

    return this.props.children;
  }
}

export default ErrorBoundary;
