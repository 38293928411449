import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faEdit
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  Button,
  DropdownToggle,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import CloseComponent from '../../../SubComponent/CloseComponent';

import { getURLPrefix } from '../../../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

const Header = (props) => {

  const {
    mode,
    contactToEdit,
    formCanBeSubmit,
    formIsUsed,
    closeCb,
    current_user,
    modaleDelete,
    staffGroupId,
    saveAction,
    defaultSaveAction,
    isLoading,
    itemsLocked
  } = props;


  const viewTitle = () => {
   switch(mode) {
     case "CREATE":    return <h1 className="pannelTitle"><FontAwesomeIcon icon={faEdit}/> New contact</h1>;
     case "EDIT":      return <h1 className="pannelTitle"><FontAwesomeIcon icon={faEdit}/> {contactToEdit.title ? contactToEdit.title + " ": null}{contactToEdit.firstname + " " + contactToEdit.lastname}</h1>;
     default:          return <h1 className="pannelTitle"><FontAwesomeIcon icon={faEdit}/> New contact</h1>;
   }
 }


 var contactIsInStaffGroup = false;
 var itemsIsLocked = false;

 if(mode === 'EDIT'){
   contactIsInStaffGroup = contactToEdit.groups.some(group => group.id === staffGroupId);
   itemsIsLocked = itemsLocked && itemsLocked.length > 0 && itemsLocked.filter(itemLocked => itemLocked.isLocked ).length > 0;
 }

 var canBeDelete = current_user.perm === "ADMIN" || (current_user.perm === "EDITOR") && !contactIsInStaffGroup;

  return (
    <Row className="descriptionRow stickyRow editDescriptionRow" form>
      <Col>
        {viewTitle()}
      </Col>
      <Col style={{textAlign: "right"}}>
        <Col xs={12} className="btnCloseBox">
          <CloseComponent target={pathname + "contact"} />
        </Col>
          <Button onClick={closeCb} color="danger">
            Cancel
          </Button>
          {defaultSaveAction
            ? <Button 
                className={!formCanBeSubmit ? "btnSubmit" : null} 
                color="primary" 
                disabled={!formCanBeSubmit || isLoading}>
                Save
              </Button>
            : <Button 
                onClick={() => saveAction()} 
                color="primary" 
                disabled={!formCanBeSubmit || isLoading}>
                Save
              </Button>
          }
          {mode == "EDIT" 
            ? <UncontrolledButtonDropdown>
                <DropdownToggle  className="dropdownToggleBtn" color="primary">
                  <FontAwesomeIcon icon={faEllipsisV}/>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="m-0 p-3" style={{borderBottom: canBeDelete ? "1px solid #e9ecef" : "none" }} onClick={contactToEdit.duplicateLinkCb}>Duplicate</DropdownItem>
                  {canBeDelete 
                    ? <DropdownItem disabled={itemsIsLocked} className="m-0 p-3" onClick={modaleDelete}>Delete</DropdownItem> 
                    : null
                  }
                </DropdownMenu>
              </UncontrolledButtonDropdown> 
            : null 
          }

      </Col>
      {formIsUsed && !formCanBeSubmit ? <Col xs={12}><p className="fieldsMissing">You cannot save because some required fields are missing</p></Col> : null}
    </Row>
  );
}

export default Header;
