import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { Button, Row, Col, Spinner } from 'reactstrap';
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus} from '@fortawesome/free-solid-svg-icons';
import ListView from '../ListView/ListView';
import { prepareCountry } from '../../utils';
import { toast } from 'react-toastify';
import { getLoggedUser } from '../../utils';

import { getURLPrefix } from '../../utils/baseURLService.js';
var pathname = getURLPrefix().pathname;

class ContactListView extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        people: prepareCountry(props.ldap.people),
        modeView: props.modeView,
        current_user: getLoggedUser()
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.modeView !== prevProps.modeView) {
          this.setState({
            modeView : this.props.modeView
          });
      }
    }

    componentDidMount() {
      this.setState({
        modeView : this.props.modeView,
        people: prepareCountry(this.props.ldap.people)
      })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { people } = nextProps.ldap;
      const { modeView } = nextProps;
      if (people && (people !== prevState.people || modeView !== prevState.modeView)) {
        return {
          people: prepareCountry(people),
          modeView
        }
      }
      return null;
    }

    render(){

      if (!this.state.people){
        return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
      };

      const { modeView } = this.state;

      var contactSelectedId = this.props.requestedContactId;

      var cols = [
          {key : 'fullname', type: 'fullname', title : 'Contact name', displayedInCompact : true},
          {key : 'company', type : 'link', title : 'Company', target: pathname + "company/details/"},
          {key : 'phone', type : 'tel', title : 'Phone'},
          {key : 'mobile', type : 'tel', title : 'Mobile'},
          {key : 'email', type : 'mailto', title : 'Email'},
          {key : 'employeeType', type : 'text', title : 'Job'},
          {key : 'country', type : 'text', title : 'Country'},
          {key : 'city', type : 'text', title : 'City'},
          {type : 'toolsAccess', key: 'toolsAccess', title:'KMS access', tool: "KMS"}
      ];

      var defaultCols =['fullname','employeeType', 'company', 'country', 'mobile', 'email', "toolsAccess"];
      var listOptions = {rowClickHandlerKey:"detailLinkCb"};

      var current_user = this.state.current_user;
      var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

      return(
          <div className='container-fluid h-100 pt-3'>
              <Row>
                <Col className="pannelTitleList">
                  <Link to={pathname + "contact"} className="text-decoration-none"><h1>Contacts</h1></Link>
                  {isAllowedToEdit ? <Link to={pathname + 'new-contact'}>
                      <Button color='primary' className="addBtn"><FontAwesomeIcon icon={faPlus}/></Button>
                  </Link> : null }
                </Col>
              </Row>
              <ListView cols={cols}
                        rows={this.state.people}
                        rowSelectedId={contactSelectedId}
                        defaultCols={defaultCols}
                        listOptions={listOptions}
                        modeView={modeView}
                        targetLink={pathname + "contact"}
                        sortKey='fullname'
                        advancedSearchType="CONTACT"
                        isClipboard={false} />
          </div>
      );

    }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(ContactListView);
