import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import {
  updateCompany,
  deleteCompany,
  fetchCompanies,
  fetchGroups,
  fetchPeople
} from '../ODD/action';

import { toast } from 'react-toastify';
import { getLoggedUser } from '../../utils';


import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Spinner } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faEllipsisV,
  faLock,
  faMinusCircle,
  faUsers,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';

import ModalCompanyEditView from './ModalCompanyEditView';
import ModalCompanyDeleteView from './ModalCompanyDeleteView';

import GroupTitleHeader from '../SubComponent/GroupTitleHeader';
import CloseComponent from '../SubComponent/CloseComponent';
import ButtonExport from '../SubComponent/ButtonExport';
import GroupMembersView from '../GroupMembersView/GroupMembersView';
import TooltipComponent from '../TooltipComponent';

toast.configure({
  position: toast.POSITION.TOP_CENTER
});

import { getURLPrefix } from '../../utils/baseURLService.js';

class CompanyDetailView extends React.Component {
  constructor(props){
    super(props);

    this.closeModalCb = this.closeModalCb.bind(this);
    this.companyValidCb = this.companyValidCb.bind(this);
    this.companyDeleteCb = this.companyDeleteCb.bind(this);

    this.state = {
      company : props.company,
      isEditModalOpen : props.isEditModalOpen,
      isDeleteModalOpen : props.isDeleteModalOpen,
      isPolling : false,
      current_user: getLoggedUser(),
      isLoading : false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { company, history } = nextProps;
    const { people, groups } = nextProps.ldap;

    if (company && people && people.length > 0 ) {
      company.companyMembership = people.filter(contact => contact.company === company.id);
      company.groupsOfCompanyMembers = [];

      if(company.companyMembership.length){
        company.companyMembership.map(contact => {

          contact.groupsName = contact.groups.map(group => group.name);

          contact.groups.forEach(group => {
            if(company.groupsOfCompanyMembers.indexOf(group.name) <= -1 ){
              company.groupsOfCompanyMembers.push(group.name)
            }
          });

          return contact;
        });
      }
      var pathname = getURLPrefix().pathname;

      company.editLinkCb = function(){
        history.push( pathname + 'company/edit/' + encodeURIComponent(company.id));
      };
      company.deleteLinkCb = function(){
        history.push( pathname + 'company/delete/' + encodeURIComponent(company.id));
      };

      return { company }
    }
    return null;
  }

  closeModalCb() {
    this.setState({
      isEditModalOpen: false
    });
    this.props.history.push( getURLPrefix().pathname + 'company/details/' + encodeURIComponent(this.props.company.name));
  }

  companyValidCb(company){
    company = company.trim();

    this.setState({
      isEditModalOpen: false,
      isLoading:true
    });

    this.props.textLoadingOverlay("Updating " + company + "...");
    this.props.loadingOverlayCb(true);

    var data = {
          id:this.state.company.id  ,
          data : { name : company }
        };

    this.props.onUpdateCompany(data)
    .then(this.props.onFetchPeople)
    .then(this.props.onFetchGroups)
    .then(this.props.onFetchCompanies)
    .then(() => {
      this.props.history.push( getURLPrefix().pathname + 'company/details/' + encodeURIComponent(company));
      toast(data.data.name + " has been saved !", {type:"success"});
    })
    .catch(err => toast(err.response.data ? err.response.data : err.message, {type:"error",autoClose: false}))
    .finally(() => {
      this.setState({isLoading : false});
      this.props.loadingOverlayCb(false);
    });
  }

  companyDeleteCb(company){
    this.setState({
      isEditModalOpen: false,
      isLoading:true
    });

    this.props.textLoadingOverlay("Removing " + this.state.company.id + "...");
    this.props.loadingOverlayCb(true);

    var data = {id:this.state.company.id};

    this.props.loadingOverlayCb(true);

    this.props.onDeleteCompany(data)
    .then(this.props.onFetchCompanies)
    .then(this.props.onFetchPeople)
    .then(this.props.onFetchGroups)
    .then(() => {
      this.props.history.push( getURLPrefix().pathname + 'company');
      toast(data.id + " removed !", {type:"success"});
    })
    .catch(err => toast(err.response.data ? err.response.data : err.message, {type:"error",autoClose: false}))
    .finally(() => {
      this.setState({isLoading : false});
      this.props.loadingOverlayCb(false);
    });
  }

  render(){
    var company = this.state.company;

    if(!company ){
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

    var current_user = this.state.current_user;
    var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

    return(
      <Container fluid={true} className="h-100">
        <Row className="descriptionRow">

          <Col xs={11}>
            <GroupTitleHeader company={company} badgeDisabled />
          </Col>

          <Col xs={1} className="text-right">
            <CloseComponent target={getURLPrefix().pathname + "company"} />
          </Col>


          <Col xs={12}  className="text-right">
            <ButtonExport data={company} exportTo="COMPANY"/>

            {isAllowedToEdit ? <Button disabled={this.state.isPolling} style={{ marginRight : "0.4rem"}} onClick={company.editLinkCb} color="primary">
              {this.state.isPolling ? <FontAwesomeIcon icon={faLock} /> : null} Edit
            </Button> : null }

            {isAllowedToEdit ? <UncontrolledButtonDropdown>
              <DropdownToggle style={{ borderRadius: "0.3rem", height: "38px"}} color="primary">
                <FontAwesomeIcon icon={faEllipsisV}/>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="p-3" onClick={company.deleteLinkCb}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown> : null }

            {this.state.isPolling ? <p className="text-right" style={{width: "100%", color: "rgba(255, 255, 255, 0.91)"}}>
              <strong><FontAwesomeIcon icon={faMinusCircle} /> Someone is already editing this contact</strong>
            </p> : null}
          </Col>
        </Row>

        <Row className="infoBox mb-5">
          <Col>
              <Col className="min-width-37 p-0" xs={1}>
                <TooltipComponent icon={faUserTie} text="Employees number" id="employee"/>
              </Col>

              <Col xs="11">
                <p><strong>{company.companyMembership.length}</strong> employee{company.companyMembership.length <= 1 ? "" : "s"}</p>
              </Col>
          </Col>

          <Col>
              <Col className="min-width-37 p-0" xs={1}>
                <TooltipComponent icon={faUsers} text="Group(s)" id="group"/>
              </Col>

              <Col xs="11">
                <p><strong>{company.groupsOfCompanyMembers.length}</strong> group{company.groupsOfCompanyMembers.length <= 1 ? "" : "s"}</p>
              </Col>
          </Col>
        </Row>

        <GroupMembersView hiddeCompanyCol members={company.companyMembership} style={{height: "calc(100% - 265px)"}}/>

        <ModalCompanyEditView 
          isOpen={this.state.isEditModalOpen}
          company={this.state.company.name}
          companies={this.state.companies}
          cancelCb={this.closeModalCb}
          successCb={this.companyValidCb}
          mode='EDIT'
          titleModal={'Edit ' + this.state.company.name}
          isLoading={this.state.isLoading}
        />

        <ModalCompanyDeleteView 
          isOpen={this.state.isDeleteModalOpen}
          company={this.state.company.name}
          cancelCb={this.closeModalCb}
          titleModal={this.state.company.name}
          successCb={this.companyDeleteCb}
          loadingOverlayCb={this.props.loadingOverlayCb}
          textLoadingOverlay={this.props.textLoadingOverlay}
          isLoading={this.state.isLoading}
        />
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onFetchCompanies: () => dispatch(fetchCompanies()),
  onUpdateCompany : (data) => dispatch(updateCompany(data)),
  onDeleteCompany : (data) => dispatch(deleteCompany(data)),
  onFetchPeople: () => dispatch(fetchPeople()),
  onFetchGroups: () => dispatch(fetchGroups())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyDetailView);
