import React from 'react';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  FormGroup,
  DropdownItem,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faUndoAlt ,
  faEllipsisV,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ButtonExport from '../SubComponent/ButtonExport';

import List from '../List/ListFactory';
import { matchStringInObject, filterAdvancedSearch, filterAdvancedSearchCompany } from '../../utils';
import SearchComponent from '../Search/SearchComponent';
import FiltersView from '../Search/FiltersView';
import AdvancedSearch from '../Search/AdvancedSearch';
import ButtonDropdownComponent from '../ButtonDropdownComponent';

class ListView extends React.Component{
    constructor(props) {
        super(props);
        this.filtersView = React.createRef();
        this.searchSectionRef = React.createRef();

        this.resetDefaultCols = this.resetDefaultCols.bind(this);
        this.toggleAdvancedSearchPanel = this.toggleAdvancedSearchPanel.bind(this);
        this.clearAllFilter = this.clearAllFilter.bind(this);

        this.state = {
            cols: [],
            viewMode: props.modeView,
            displayedCols: [],
            searchString: '',
            advancedSearch: [],
            simpleSearchKeys: [],
            isAdvencedSearchOpen : false,
            advancedSearchType : props.advancedSearchType,
            initAdvancedSearchFields: false
        };
    }

    setCols = (initDefaultCols) => {
      this.setState({
        cols: this.state.viewMode === 'extended' ? this.prepareColsExtendView(this.props.cols, this.props.defaultCols, initDefaultCols) : this.prepareColsCompactedView(this.props.cols),
        displayedCols: this.updateDisplayedCols(this.props.cols)
      });
    }

    updateDisplayedCols = (cols) => {
      return cols.filter((col) => {
        if (col.isDisplayed === true && this.state.viewMode === 'compacted' && col.displayedInCompact === true) {
            return true;
        } else if (col.isDisplayed === true && this.state.viewMode === 'extended') {
            return true;
        }
      });
    }

    componentDidUpdate(prevProps) {
      if (this.props.modeView !== prevProps.modeView || this.props.cols.length !== prevProps.cols.length) {
          this.setCols();
      }
    }

    componentDidMount() {
      this.setCols();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { modeView } = nextProps;

      if (modeView !== prevState.viewMode) {

        return {  viewMode: modeView,
                  isAdvencedSearchOpen : false};
      }
      return null;
    }

    onSearch = ( searchString ) => {
      this.setState({
        searchString : searchString,
        advancedSearch : []
      });
    }

    onAdvancedsearchChanged = ( fields ) => {

      var fieldKeys = Object.keys(fields);
      var simpleSearchKeys = this.state.simpleSearchKeys;

      this.state.cols.forEach((col) => {
        if(fieldKeys.length > 0 && fieldKeys.some(fieldKey => fieldKey === col.key)){
          col.isDisplayed = true;
          simpleSearchKeys.push(col.key);
        }
      });

      this.setState({
        advancedSearch: fields,
        displayedCols : this.updateDisplayedCols(this.state.cols),
        simpleSearchKeys: simpleSearchKeys,
        initAdvancedSearchFields: false
      });
    }

    prepareColsExtendView = (cols, defaultCols, initDefaultCols) => {

      var simpleSearchKeys = [];

      simpleSearchKeys = defaultCols.concat(simpleSearchKeys);

      var extendedCols = [];

      var colsToDisplay = !initDefaultCols && this.state && this.state.simpleSearchKeys.length ? this.state.simpleSearchKeys : defaultCols;

      cols.forEach((col) => {
          col.isDisplayed = colsToDisplay.includes(col.key);

          col.toggleDisplay = () => {
            if(simpleSearchKeys.includes(col.key)){
              simpleSearchKeys = simpleSearchKeys.filter(key => key != col.key);
            } else {
              simpleSearchKeys.push(col.key);
            }

            col.isDisplayed = !col.isDisplayed ;

            this.setState({
                displayedCols : this.updateDisplayedCols(cols),
                simpleSearchKeys: simpleSearchKeys
            });
          };
          extendedCols.push(col);
      });

        this.setState({
          simpleSearchKeys : colsToDisplay
        });

      return extendedCols;
    }

    prepareColsCompactedView = (cols) => {

      return cols.map((col) => {
          if(this.state && this.state.advancedSearch && col.key === "email" && Object.keys(this.state.advancedSearch).includes("email")){
            col.displayedInCompact = true;
          }
          if (col.displayedInCompact) {
            col.isDisplayed = true;
          } else {
            col.isDisplayed = false;
          }
        return col;
      });
    }

    resetDefaultCols = () => {
      this.setCols(true);
    }

    clearAllFilter(){
      var advencedSearchIsOpen = this.state.isAdvencedSearchOpen;

      var advancedSearchFielsCount = Object.keys(this.state.advancedSearch).length;

      this.setState({
        searchString: '',
        advancedSearch: [],
        isAdvencedSearchOpen: advancedSearchFielsCount > 0,
        initAdvancedSearchFields: true
      });
    }

    toggleAdvancedSearchPanel = () => {

      var advencedSearchIsOpen = this.state.isAdvencedSearchOpen;
      var simpleSearchKeys = this.state.simpleSearchKeys;

      var advancedSearchFielsCount = Object.keys(this.state.advancedSearch).length;
      var cols = this.state.cols;

      if(advancedSearchFielsCount > 0){
        advencedSearchIsOpen = advancedSearchFielsCount > 0;
      }

      if(this.state.advancedSearchType === "COMPANY"){

        cols.forEach((col) => {
          if(col.key === "groups")
            col.isDisplayed = !advencedSearchIsOpen;
            simpleSearchKeys.push(col.key);
        });

      }

      this.setState({
        isAdvencedSearchOpen : !advencedSearchIsOpen,
        searchString: '',
        advancedSearch: [],
        initAdvancedSearchFields: true,
        displayedCols : this.updateDisplayedCols(cols),
        simpleSearchKeys: simpleSearchKeys
      });
    }

    listHeight(advancedSearchType, isAdvencedSearchOpen, filtersViewHeight){
      var listHeight = "calc(100% - 54px)";

      if(filtersViewHeight > 0 ){
        filtersViewHeight = filtersViewHeight + 15;
        listHeight = "calc(100% - " + filtersViewHeight + "px)";
      }
      else {

        if(isAdvencedSearchOpen){

          if(advancedSearchType === "CONTACT"){
            listHeight = "calc(100% - 178px)";
          }
          if(advancedSearchType === "GROUP"){
            listHeight = "calc(100% - 216px)";
          }
          if(advancedSearchType === "COMPANY"){

            var searchSectionRef = this.searchSectionRef.current && Object.keys(this.state.advancedSearch).length !== 0 ? this.searchSectionRef.current.offsetHeight : 110;
            var offsetHeight = searchSectionRef > 110 ? searchSectionRef + 15 : "110";

            listHeight = "calc(100% - " + offsetHeight + "px)";
          }
        }
      }
      return listHeight;
    }

    getRowsFitler(rows, advancedSearch, searchString, simpleSearchKeys, advancedSearchType){

      var rowsFilter = [];

      var advancedSearchFielsCount = Object.keys(advancedSearch).length;

      if(advancedSearchType === "COMPANY"){

        if(advancedSearchFielsCount){
          rowsFilter = rows.filter((row) => {
            return filterAdvancedSearchCompany( row, advancedSearch);
          });
        }
        else {
          rowsFilter = rows.filter((row) => {
            row.groups.map(group => {
              group.isDisplay = true;
            });
            return matchStringInObject(searchString, simpleSearchKeys, row);
          });
        }


      } else {


        if(advancedSearchFielsCount){
          rowsFilter = rows.filter((row) => {
            return filterAdvancedSearch(advancedSearch, row);
          });
        } else {
          rowsFilter = rows.filter((row) => {
            return matchStringInObject(searchString, simpleSearchKeys, row);
          });
        }


      }
      return rowsFilter;
    }

    render () {
        const {
          rows,
          listOptions
        } = this.props;

        const {
          viewMode,
          cols,
          searchString,
          displayedCols,
          simpleSearchKeys,
          advancedSearch,
          advancedSearchType
        } = this.state;

        let rowsFilter = this.getRowsFitler(rows, advancedSearch, searchString, simpleSearchKeys, advancedSearchType);
        var advancedSearchFielsCount = Object.keys(advancedSearch).length;


        var colsDropdownItems = cols.map((col) => {
            var color = col.isDisplayed ? 'selected' : 'unselected' ;
            return (<DropdownItem key={col.key} className={color} onClick={col.toggleDisplay}><FontAwesomeIcon icon={faCheckCircle}/> {col.title}</DropdownItem>);
        });
        colsDropdownItems.unshift(<DropdownItem key="Defaultcolumns" onClick={this.resetDefaultCols} className="defaultColums"><FontAwesomeIcon icon={faUndoAlt}/> Default columns</DropdownItem>);

        var filtersViewHeight = 0;

        if(viewMode !== "extended" && this.filtersView.current){
          filtersViewHeight = this.filtersView.current.offsetHeight;
        }

        var listHeight = this.listHeight(this.state.advancedSearchType, this.state.isAdvencedSearchOpen, filtersViewHeight);

        var advencedBtnText = '';

        if(this.state.isAdvencedSearchOpen || advancedSearchFielsCount > 0){
          advencedBtnText = "Simple Filter ";
        } else {
          advencedBtnText = "Advanced Filter ";
        }

        return(
          <div style={{height : "calc(100% - 56px)"}}>
               {viewMode === "extended" ? <div ref={this.searchSectionRef}><Row>
                 <Col xs={7}>
                    <FormGroup>
                      <SearchComponent onSearchChanged={this.onSearch.bind(this)}
                                       disabled={advancedSearchFielsCount > 0 || this.state.isAdvencedSearchOpen}
                                       searchString={searchString}
                                       placeholder={advancedSearchType === "COMPANY" ? "Filter by companies and groups" : null}/>
                    </FormGroup>
                 </Col>
                 <Col xs={5} style={{ display: "flex", height: "38px"}}>
                   <Button style={{ marginLeft : "-1rem"}}
                           color={advancedSearchFielsCount === 0 && !searchString ? "secondary" : "primary"}
                           disabled={advancedSearchFielsCount === 0 && !searchString}
                           onClick={this.clearAllFilter}>Clear filters <FontAwesomeIcon icon={faTrashAlt}/></Button>
                   {this.state.advancedSearchType ? <Button style={{ marginLeft : "1rem", position : "relative"}}
                                                            color="primary"
                                                            onClick={this.toggleAdvancedSearchPanel}>
                                                              {advencedBtnText}
                                                              <FontAwesomeIcon icon={faEllipsisV}/>
                                                    </Button> : null }

                   {this.props.isClipboard
                     ? <ButtonExport data={rowsFilter}
                                     displayedCols={displayedCols}
                                     exportCompanyList={this.state.advancedSearchType === "COMPANY"}
                                     exportTo="LIST"/> 
                     : null
                   }

                   <ButtonDropdownComponent btnName="Columns ">{colsDropdownItems}</ButtonDropdownComponent>
                 </Col>
                 <Col xs={12}>
                 <AdvancedSearch isCollapse={advancedSearchFielsCount > 0 ? advancedSearchFielsCount > 0 : this.state.isAdvencedSearchOpen}
                                 type={this.state.advancedSearchType}
                                 advancedSearch={this.state.advancedSearch}
                                 initAdvancedSearchFields={this.state.initAdvancedSearchFields}
                                 onSearchChanged={this.onAdvancedsearchChanged.bind(this)}/>
                 </Col>

              </Row></div> : <FiltersView searchString={searchString}
                                    advancedSearch={this.state.advancedSearch}
                                    ref={this.filtersView}
                                    goBack={this.props.targetLink}/> }

              <Row style={{ height: listHeight}}>
                  <List cols={displayedCols}
                        rows={rowsFilter}
                        rowSelectedId={this.props.rowSelectedId}
                        options={listOptions}
                        sortKey={this.props.sortKey}/>
              </Row>
          </div>
        );
    }
}

export default ListView;
