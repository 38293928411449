import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTools
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import ButtonDropdownComponent from '../../../ButtonDropdownComponent';

class ContactEditToolsAccess extends React.Component{
  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      accessOption : "No"
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const { toolsAccess } = nextProps;

    if(toolsAccess){
      return { accessOption : toolsAccess.includes("KMS") ? "Yes" : "No" }
    }
    return null;
  }

  handleChange(event){
    var value = event.target.value
    this.setState({
      accessOption : value
    });
    if(value === "Yes"){
      this.props.updateToolsAcces(["KMS"]);
    } else {
      this.props.updateToolsAcces([]);
    }
  }

  render(){
    var accessOption = this.state.accessOption;

    return (
      <div className="panelBoxSection" >
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faTools}/>
          </div>
          <h2>Tools access</h2>
        </div>
        <div className="panelBoxBody">

          <Row>
            <Col>
            <FormGroup>
              <Label>KMS access</Label>
              <Input autoComplete="noautoComplete"
                     type="select"
                     name="toolsAccess"
                     value={accessOption}
                     onChange={this.handleChange}>
                <option value="No">This user does NOT have access</option>
                <option value="Yes">This user has access</option>
              </Input>
            </FormGroup>
            </Col>
          </Row>

        </div>
      </div>
    );
  };
}

export default ContactEditToolsAccess;
