import React from 'react';
import { sortRows } from '../../utils/sortUtils';
import { getLoggedUser } from '../../utils';

import {
  ListGroup,
  ListGroupItem,
  Collapse,
  Button
} from 'reactstrap';

import ModalMembershipWidget from './ModalMembershipWidget';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSortUp, faSortDown, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';

class ListWithDropdown extends React.Component {

  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.generateHtrmlGroupList = this.generateHtrmlGroupList.bind(this);
    this.getCollapse = this.getCollapse.bind(this);
    this.selectGroupMembersAction = this.selectGroupMembersAction.bind(this);
    this.closeModalCb = this.closeModalCb.bind(this);
    this.itemAction = this.itemAction.bind(this);

    this.state = {
      groups : props.groups,
      items : this.generateHtrmlGroupList(props.groups),
      groupSelected : { isCollapse: false },
      modalMergeGroupIsOpen : false,
      groupToMerge : {},
      current_user: getLoggedUser()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { groups } = nextProps;

    if (groups && groups !== prevState.groups) {
      return { groups }
    }
    return null;
  }

  handleChange(currentGroup){
    var groups = this.state.groups;

    groups.forEach((group) => {
      if(group.id !== currentGroup.id){
        group.isCollapse = false;
      }
    });

    currentGroup.isCollapse = !this.state.groupSelected.isCollapse;

    this.setState({
      groupSelected : currentGroup,
      groups: groups
    });
  }

  selectGroupMembersAction(groupSelected){

    this.props.selectGroupMembersAction(groupSelected);

    var contactToScroll = groupSelected.members.filter(contact => contact.newContactSelected == true)[0];

    setTimeout(() => {
      var elmnt = document.getElementById(contactToScroll.id);
      elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 100);

    groupSelected.members.forEach((contact) => {
      if(contact.isSelected == false){
        contact.isSelected = true;
        contact.newContactSelected = true;
      }
    });

    this.setState({
      items : this.generateHtrmlGroupList(this.props.groups, groupSelected),
      modalMergeGroupIsOpen : false
    });
  }

  modalGroupMembersAction(groupSelected){
    this.setState({
      modalMergeGroupIsOpen : true,
      groupToMerge : groupSelected
    });
  }

  closeModalCb(){
    this.setState({
      modalMergeGroupIsOpen : false
    });
  }

  itemAction(item){
    this.props.personAction(item);
    setTimeout(() => {
      var elmnt = document.getElementById(item.id);
      elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 100);

  }

  getCollapse(currentGroupId, groupSelected){

    var collapseHtml = null;

    var needToGenerateCollapse = groupSelected ? currentGroupId == groupSelected.id : false;

    if(needToGenerateCollapse){

      var peopleInGroup = [];

      var groupMembersSorted = this.props.sortContactKey ? sortRows(groupSelected.members, this.props.sortContactKey, 'asc') : groupSelected.members;

        groupMembersSorted.forEach((contact) => {
          var disabled = contact.isSelected;
          peopleInGroup.push(
             <ListGroupItem action
                            disabled={disabled}
                            className="justify-content-between listWithDropdown-contactItem btn"
                            key={contact.id}
                            style={{ cursor: !contact.isSelected ? "pointer" : "default"}}>
                            <span className="iconList" onClick={() => this.itemAction(contact)}>
                              <FontAwesomeIcon icon={!contact.isSelected ? faUserPlus : faUser}
                                             style={{ marginRight: "1.25rem", color : !contact.isSelected ? "#9ACA3C" : "rgba(108, 117, 125, 0.9)"}}/>
                            </span>
                            <span>{contact.lastname}</span>
                            <span className="cell-firstname">{" " + contact.firstname}</span>

             </ListGroupItem>);
        });

      collapseHtml = (<Collapse key={'collapse-' + groupSelected.id } isOpen={groupSelected ? groupSelected.isCollapse : false}>{peopleInGroup}</Collapse>);
    }

    return collapseHtml;

  }

  generateHtrmlGroupList(groups, groupSelected){

    var groupsSorted = this.props.sortGroupKey ? sortRows(groups, this.props.sortGroupKey, 'asc') : groups;

    var items = [];

    groupsSorted.forEach((group) => {

      var collapsed = group.isCollapse;

      items.push(<ListGroupItem key={group.id} action className={group.isCollapse ? "justify-content-between listWithDropdown-groupItem itemCollapsed" : "justify-content-between listWithDropdown-groupItem"}>
                      <span onClick={() => this.handleChange(group)} name={group.id} className={collapsed ? "sortCollapsed": null}><FontAwesomeIcon icon={collapsed ? faSortDown : faSortUp} style={{color : "#495057"}}/></span>
                      <span onClick={() => this.handleChange(group)} style={{width: "calc(100% - 110px)", display : "inline-block"}} className="text-truncate" title={group.name}>{group.name}</span>
                      <span onClick={() => this.modalGroupMembersAction(group)}><FontAwesomeIcon icon={faPlusCircle} style={{color : "rgb(154, 202, 60)", fontSize : "18px"}}/></span>
                      {this.getCollapse(group.id, groupSelected)}
                 </ListGroupItem>);
    });
    return items;
  }

  render(){

    var groups = this.state.groups;
    var groupSelected = this.state.groupSelected;

    var items = this.generateHtrmlGroupList(groups, groupSelected);

    return(
      <ListGroup className="listWithDropdown">
        {items}
        {this.state.groupToMerge ? <ModalMembershipWidget isOpen={this.state.modalMergeGroupIsOpen}
                               group={this.state.groupToMerge}
                               cancelCb={this.closeModalCb}
                               successCb={this.selectGroupMembersAction}/> : null}
      </ListGroup>
    );
  }
}
export default ListWithDropdown;
