import React from 'react';
import {Modal, ModalBody } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import BoxModalHeaderComponent from '../../../SubComponent/BoxModal/BoxModalHeaderComponent';
import BoxModalFooterComponent from '../../../SubComponent/BoxModal/BoxModalFooterComponent';

const BoxModalComponent = (props) => {
  const {
    isOpen,
    successCb,
    boxModule,
    isLoading
  } = props;

  let domainUrl = 'https://' + boxModule.domain;

  return (
    <Modal isOpen={isOpen} className="p-5">

      <BoxModalHeaderComponent />

      <ModalBody className="p-5">
        <strong>Info : </strong>
        The access to folders shared for collaboration in <a target="_blank" href={domainUrl}>{boxModule.domain}</a> will be adapted according to the rules described <a target="_blank" href={boxModule.internalHelpCenter.url}>here</a>.<br/>
        <br/>
        Content shared <strong className="d-inline">only</strong> by link is <strong className="d-inline">not</strong> impacted.<br/>
        <br/>
        This operation will take some time, around {boxModule.minBeforeExecutingSync} minutes.
      </ModalBody>

      <BoxModalFooterComponent successCbIsDisabled={isLoading} successCb={successCb}/>

    </Modal>
  );
}

export default BoxModalComponent;
