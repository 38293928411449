import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUsers, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Badge, Placeholder } from 'reactstrap';

const GroupTitleHeader = (props) => {
  const {
    group,
    badgeDisabled,
    company,
    isLoading
  } = props;

  return (
    <div className="titleHeaderBox">
      <div className="titleHeaderBox-icon">
        <FontAwesomeIcon icon={company ? faBuilding : faUsers}/>
      </div>
      <div className="titleHeaderBox-body">
      {!isLoading
        ? <>
            <h1 className="pannelTitle">{company ? company.name : group.name}</h1>
            {!badgeDisabled ? <p><Badge color="light">{group.type}</Badge></p> : null}
          </>
        : null
      }
        
      </div>
    </div>
  );
}

export default GroupTitleHeader;
