import React from 'react';
import { 
  Container, 
  Row,
  Col,
  Spinner
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import CloseComponent from '../SubComponent/CloseComponent';
import GroupMembersView from '../GroupMembersView/GroupMembersView';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { 
  fetchGroupById, 
  updateGroup, 
  updateGroupsV2,
  fetchGroups,
  fetchPeople
} from '../ODD/action';
import { prepareGroupById } from '../../utils';

class TransfertEFPIAContactView extends React.Component {
  constructor(props){
    super(props);

    this.onFormSubmitEditGroup = this.onFormSubmitEditGroup.bind(this);
    this.getContactToDisplay = this.getContactToDisplay.bind(this);

    this.state = {
      staffGroup: [],
      isLoading: false,
      contactToDisplay: null,
      groupsChanged: null,
      groupIsChange: false
    }
  }

  componentDidMount(){
  	var staffGroupId = encodeURIComponent(this.props.staffGroupId);
    this.props.onFetchGroupById(staffGroupId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
  	const { staffGroupId } = nextProps;
    const { groupById, people } = nextProps.ldap;

    if(staffGroupId && Object.keys(groupById).length > 0 && groupById.id === staffGroupId && people){
      var groupFormatted = prepareGroupById(groupById, people);
      return { staffGroup : groupFormatted }
    }
    return null;
  }

  prepareAssignedGroup(contacts){
  	return contacts.map(contact => {
  	  contact.assignedGroup = [];
  	  this.props.ldap.groups.forEach((group) => {
        var contactIsAssigned = group.efpiaContact && group.efpiaContact.id === contact.id || (typeof(group.efpiaContact) === "string" && group.efpiaContact === contact.id)
  	  	if(contactIsAssigned){
  	  		contact.assignedGroup.push(group)
  	  	}
  	  });
  	  return contact;
  	});
  }

  onFormSubmitEditGroup(groupsAssigned) {
    this.setState({isLoading : true});
    var self = this;
    var contactToDisplay = this.state.contactToDisplay;

    var promises = [];

    var groupFormatted = groupsAssigned.map(group => {
    	return { id: group.id,
               changes: { efpiaContact: group.newEFPIAContactAssigned }
      };
    })

    updateGroupsV2(groupFormatted)
    .then(response => {
      var state = {};
      state.groupIsChange = true;
      if(self.state.groupIsChange && self.state.groupsChanged.length > 0){
        state.groupsChanged = self.state.groupsChanged.concat(response);
      } else {
        state.groupsChanged = response;
      }
      self.setState(state);

      toast("Transfert successfull !", {type:"success"});
    })
    .catch((err) => {
      toast(err && err.response && err.response.data ? err.response.data : err.message, {type:"error",autoClose: false})  
    })
    .finally(() => {
      self.setState({isLoading : false});
    });

  }

  getContactToDisplay(contactToDisplay){
    var state = {};
    state.contactToDisplay = contactToDisplay;
    if(this.state.groupIsChange && this.state.contactToDisplay !== contactToDisplay){
      this.props.onFetchGroups();
      this.props.onFetchPeople();
      state.groupIsChange = false;
    }
    if(!contactToDisplay || this.state.contactToDisplay !== contactToDisplay){
      state.groupsChanged = null;
    }
    this.setState(state);
  }

  componentWillUnmount() {
    if(this.state.groupIsChange){
      this.props.onFetchGroups();
      this.props.onFetchPeople();
    }
    
    this.setState({
      staffGroup: [],
      isLoading: false,
      contactToDisplay: null,
      groupsChanged: null,
      groupIsChange: false
    });

  }


  render(){
  	var staffGroup = this.state.staffGroup;
    if (Object.keys(staffGroup).length <= 0 || this.props.ldap.groups && this.props.ldap.groups.length <= 0) {
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

  	var members = this.prepareAssignedGroup(staffGroup.members);

  	return(
  	  <Container fluid={true} className="groupDetailView h-100 overflowY-auto">
  	    <div className="descriptionRow stickyRow">
  	      <Row>
  	        <Col xs={11}>
  	          <div className="titleHeaderBox">
  			      <div className="titleHeaderBox-icon">
  			        <FontAwesomeIcon icon={faUsers} />
  			      </div>
  			      <div className="titleHeaderBox-body">
  			        <h1 className="pannelTitle">Transfert EFPIA contact</h1>
  			      </div>
  		      </div>
  	        </Col>

  	        <Col xs={1} className="text-right">
  	          <CloseComponent target={pathname + "contact"} />
  	        </Col>
  	      </Row>
  	    </div>
  	    <GroupMembersView 
  		    members={members} 
  		    style={{height: "calc(100% - 230px)"}}
  		    displayAssignedGroup
  		    saveAction={this.onFormSubmitEditGroup}
          getContactToDisplay={this.getContactToDisplay}
          contactToDisplay={this.state.contactToDisplay}
          groupsChanged={this.state.groupsChanged}
          isLoading={this.state.isLoading}
  	    />
  	  </Container>
  	);
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onFetchGroupById: (id) => dispatch(fetchGroupById(id)),
  onUpdateGroup: (data) => dispatch(updateGroup(data)),
  onFetchGroups: () => dispatch(fetchGroups()),
  onFetchPeople: () => dispatch(fetchPeople())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TransfertEFPIAContactView);

