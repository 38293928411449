import React from 'react';

import CollapseComponent from '../CollapseComponent/CollapseComponent';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

const HistoryLog = (props) => {
  const {
    logs,
    isLoading
  } = props;

  return (
    <div className="panelBoxSection editors-history">
      <div className="panelBoxTitle">
        <div className="panelBoxTitle-icon">
          <FontAwesomeIcon icon={faHistory}/>
        </div>
        <h2>History</h2>
      </div>
      <div className="panelBoxBody">
      <div className="content-box flexColum">
      {isLoading 
        ? <div class="content-wrapper">
            <div class="placeholder placeholder-dark w-700">
              <div class="animated-background"></div>
            </div>
          </div>
        : <CollapseComponent items={logs} itemTodisplay={3}/>
      }
      </div>
      </div>
    </div>
  );
}

export default HistoryLog;
