export default function ldap(state = {
  people: [],
  groups: [],
  groupById: {},
  companies: [],
  configuration: {},
  orphanContacts: undefined,
  fetching: false,
  fetched: false,
  error: null
}, action) {

  switch (action.type) {
    case "FETCH_PEOPLE": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "FETCH_PEOPLE_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case 'FETCH_PEOPLE_FULFILLED': {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        people: action.payload,
      })
    }
    case "CREATE_PEOPLE": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "CREATE_PEOPLE_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "CREATE_PEOPLE_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }
    case "CREATE_GROUP": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "CREATE_GROUP_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "CREATE_GROUP_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }
    case "UPDATE_PEOPLE": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "UPDATE_PEOPLE_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "UPDATE_PEOPLE_FULFILLED": {
      return Object.assign({}, state, {
        fetching: true,
        fetched: false,
      })
    }
    case "DELETE_PEOPLE": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "DELETE_PEOPLE_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "DELETE_PEOPLE_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }
    case "FETCH_GROUPBYID": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "FETCH_GROUPBYID_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "FETCH_GROUPBYID_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        groupById: action.payload
      })
    }

    case "FETCH_GROUPS": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "FETCH_GROUPS_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "FETCH_GROUPS_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        groups: action.payload
      })
    }
    case "DELETE_GROUP": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "DELETE_GROUP_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "DELETE_GROUP_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }
    case "UPDATE_GROUPS": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "UPDATE_GROUPS_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "UPDATE_GROUPS_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }

    case "GET_ORPHAN_CONTACTS_GROUP": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "GET_ORPHAN_CONTACTS_GROUP_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "GET_ORPHAN_CONTACTS_GROUP_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        orphanContacts: action.payload
      }
    }

    case "RESET_ORPHAN_CONTACTS_GROUP": {
      return {
        ...state,
        fetching : true
      }
    }

    case "RESET_ORPHAN_CONTACTS_GROUP_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        orphanContacts: action.payload
      }
    }

    case "FETCH_COMPANIES": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "FETCH_COMPANIES_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "FETCH_COMPANIES_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        companies: action.payload
      })
    }
    case "CREATE_COMPANY": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "CREATE_COMPANY_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "CREATE_COMPANY_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }
    case "UPDATE_COMPANY": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "UPDATE_COMPANY_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "UPDATE_COMPANY_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }
    case "DELETE_COMPANY": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "DELETE_COMPANY_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "DELETE_COMPANY_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }
    case "FETCH_CONFIGURATION": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "FETCH_CONFIGURATION_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case 'FETCH_CONFIGURATION_FULFILLED': {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        configuration: action.payload,
      })
    }
    default:
      return state;
  }
}
