import React from 'react';
import { Button, ModalFooter } from 'reactstrap';

const BoxModalFooterComponent = (props) => {

  const { successCb, successCbIsDisabled } = props;

  return (
    <ModalFooter className="p-3 justify-content-center">
      <Button 
        color="primary" 
        onClick={() => { successCb() }} 
        disabled={successCbIsDisabled} >
        Ok
      </Button>
    </ModalFooter>
  );
}

export default BoxModalFooterComponent;
