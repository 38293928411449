import React from 'react';

import List from '../List/ListFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

class ContactGroups extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      groups : props.groups
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const { contactId, groups } = nextProps;

    if (contactId && groups && groups.length > 0) {

      var chairs = [];
      var viceChairs = [];

      groups.map(group => {

        chairs = group.chairs && group.chairs.length ? group.chairs.filter(chair => chair.id === contactId) : [] ;
        viceChairs = group.viceChairs && group.viceChairs.length ? group.viceChairs.filter(vicechair => vicechair.id === contactId) : [];

        group.role = '';

        if(chairs.length > 0){
          group.role = 'Chair';
        }
        if(viceChairs.length > 0){
          group.role = 'Vice-chair';
        }
      });

      return { groups : groups }
    }

    return null;
  }

  render(){
    var groups = this.state.groups;

    const {
      className,
      style
    } = this.props;

    var cols = [
      {type : 'textWithRole', key : 'name', title : 'Group name'}
    ];

    var listOptions = {rowClickHandlerKey:"detailLinkCb"};

    return (
      <div className={className ? "panelBoxSection " + className : "panelBoxSection"} style={ style ? style : {marginBottom: "1.5rem", height: "calc(100% - 3rem)"}}>
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faUsers}/>
          </div>
          <h2 className="m-0">Groups</h2>
          <Badge color="light" pill>
            {groups.length === 1
              ? groups.length + " membership"
              : groups.length + " memberships"
            }
          </Badge>
        </div>
        <div className="panelBoxBody">
          <List cols={cols} rows={groups} options={listOptions} sortKey='name' />
        </div>
      </div>
    );

  }
}

export default ContactGroups;
