import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const TooltipComponent = (props) => {
  const {
    icon,
    text,
    textIcon,
    id
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <p className={textIcon ? "textIcon iconInfoBox" : "iconInfoBox"} href="#" id={id}>
      <FontAwesomeIcon icon={icon}/>
      {textIcon ? <span>{textIcon}</span> : null}
      <Tooltip placement="top" isOpen={tooltipOpen} target={id} toggle={toggle}>
        {text}
      </Tooltip>
    </p>
  );
}

export default TooltipComponent;
