import React from 'react';

import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CloseComponent = (props) => {
  const {
    target,
    className,
    closeCb
  } = props;

  return (
        <Col xs={12} className={className + " btnCloseBox"}>
          {target ? <Link to={target}>
            <div className="btnClose"><FontAwesomeIcon icon={faTimes}/></div>
          </Link> : null}
          
          {closeCb ? <div className="btnClose" onClick={closeCb}>
            <FontAwesomeIcon icon={faTimes}/>
          </div> : null}
        </Col>
  );
}

export default CloseComponent;
