import React from 'react';
import {
  Modal, 
  ModalBody,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import BoxModalHeaderComponent from '../../../../SubComponent/BoxModal/BoxModalHeaderComponent';
import BoxModalFooterComponent from '../../../../SubComponent/BoxModal/BoxModalFooterComponent';

const BoxModalComponent = (props) => {
  const {
    isOpen,
    successCb,
    boxModule,
    isLoading,
    mode,
    externalHelpCenter
  } = props;

  let domainUrl = 'https://' + boxModule.domain;

  return (
    <Modal isOpen={isOpen}>

      <BoxModalHeaderComponent/>

      <ModalBody className="p-5">
        <ListGroup>
          <ListGroupItem className="p-1 text-center">
            <strong>Information</strong>
          </ListGroupItem>
          <ListGroupItem className="pt-4 pb-4">
            The access to folders shared for collaboration in <a target="_blank" href={domainUrl}>{boxModule.domain}</a> will be adapted according to the rules described <a target="_blank" href={boxModule.internalHelpCenter.url}>here</a>.
            <br/>
            <br/>
            Content shared <strong>only</strong> by link is <strong>not</strong> impacted.
            <br/>
            <br/>
            This operation will take some time, around {boxModule.minBeforeExecutingSync} minutes.
          </ListGroupItem>
          
            {mode && mode === "CREATE" || mode === "DUPLICATE"
              ? <ListGroupItem className="pt-4 pb-4">
                  A mail with detailed instructions to create a box account has been sent to the new contact. 
                  {externalHelpCenter && externalHelpCenter.url 
                    ? <><br/><br/><strong>Visit <a href={externalHelpCenter.url} target="_blank" >{externalHelpCenter.title}</a> for more information.</strong>
                      </>
                    : "(see internal helpcenter)."
                  }
                </ListGroupItem>
              : ""
            }
          
        </ListGroup>
      </ModalBody>

      <BoxModalFooterComponent successCbIsDisabled={isLoading} successCb={successCb}/>

    </Modal>
  );
}

export default BoxModalComponent;
