import { getCountry } from '../components/SubComponent/Country-List/countriesOverrided';

var BreakException = {};

export const prepareGroups = (groups) => {
  const newGroups = []
  groups.map(group => {
    let new_group = Object.assign({}, group);

    var companies = [];

    group.members.forEach((contact) => {
      if(!companies.includes(contact.company))
        companies.push(contact.company);
    });

    new_group.company = companies.sort();

    newGroups.push(new_group)
  })
  return newGroups;
}

export const prepareGroupById = (group, people) => {

  group.editorsIds = group.editors ? group.editors.map(contact => {
    if(typeof(contact) === "object"){
      return contact.id
    } else {
      return contact;
    }
  }) : group.editors;

  people.forEach(person => {
    for (var i = 0; i < group.chairs.length; i++) {
      if(person.id === group.chairs[i]){
        group.chairs[i] = person;
      }
    }

    for (var i = 0; i < group.viceChairs.length; i++) {
      if (person.id === group.viceChairs[i])
        group.viceChairs[i] = person;
    }

    if (person.id === group.efpiaContact) {
      group.efpiaContact = person;
    }

    if (group.members) {
      for (var i = 0; i < group.members.length; i++) {
        if (person.id === group.members[i])
          group.members[i] = person;
      }
    }

    if (group.editors) {
      for (var i = 0; i < group.editors.length; i++) {
        if (person.id === group.editors[i] )
          group.editors[i] = person;
      }
    }
  });

  group.members.map(function (person){
    if(typeof(person) === "object"){
      person.role = '';

      var chairs = group.chairs.filter(chair => chair.id === person.id);

      var viceChairs = group.viceChairs.filter(viceChair => viceChair.id === person.id);

      if(chairs.length > 0 ){
        person.role = "Chair";
        person.groups.forEach(personGroup => {
          if (group.id === personGroup.id) {
            personGroup.chairs = group.chairs;
          }
        });
      }

      if(viceChairs.length > 0 ){
        person.role = "Vice-chair";
        person.groups.forEach(personGroup => {
          if (group.id === personGroup.id) {
            personGroup.viceChairs = group.viceChairs;
          }
        });
      }
    }
    return person;
  });
  group.memberCount = group.members.length;

return group;

}

export const prepareCountry = (people) => {
  const newPeople = []
  people.map((contact) => {
    let new_people = Object.assign({}, contact);
    const countryName = getCountry(contact.country);
    if (countryName) {
      new_people.country = countryName;
    }
    newPeople.push(new_people)
  });
  return newPeople;
}

// THERE IS NO OVERLOADING IN JAVASCRIPT
// export var prepareDataFromApi = function (data) {
// }

// TODO : refactor this to comply with ADR 0002
// (see src/documentation/architecture/ for more info)
export const prepareDataFromApi = function ({people, groups}) {
  var peopleById = {};
  var groupsById = {};
  people.forEach ( function (person) {
    peopleById[person.id] = person;
  });
  groups.forEach ( function (group) {
    groupsById[group.id] = group;
  });


  people.forEach ( function (person) {
    var i;
    if (person.groups) {
      for (i = 0; i < person.groups.length; i++) {
        if (groupsById[person.groups[i]])
          person.groups[i] = groupsById[person.groups[i]];
      }
    }
    person.fullname = person.lastname + " " + person.firstname;
  });


  groups.forEach ( function (group) {
    for (var i = 0; i < group.chairs.length; i++) {
      if (peopleById[group.chairs[i]])
        group.chairs[i] = peopleById[group.chairs[i]];
    }

    for (var i = 0; i < group.viceChairs.length; i++) {
      if (peopleById[group.viceChairs[i]])
        group.viceChairs[i] = peopleById[group.viceChairs[i]];
    }

    if (peopleById[group.efpiaContact]) {
      group.efpiaContact = peopleById[group.efpiaContact];
    }

    if (group.members) {
      for (var i = 0; i < group.members.length; i++) {
        if (peopleById[group.members[i]])
          group.members[i] = peopleById[group.members[i]];
      }
    }
    if (group.editors) {
      for (var i = 0; i < group.editors.length; i++) {
        if (peopleById[group.editors[i]])
          group.editors[i] = peopleById[group.editors[i]];
      }
    }

    group.memberCount = group.members.length;
    group.createdDate = new Date(group.createdTimestamp * 1000);
    group.modifiedDate = new Date(group.modifiedTimestamp * 1000);
    group.membershipModifiedDate = Date(group.membershipModifiedTimestamp * 1000);
  });

  return {
    people: people,
    groups: groups,
    peopleById: peopleById,
    groupsById: groupsById
  };
};

export const matchStringInObject = function (searchString, keys, object) {
  if(searchString.length === 0){
    return true;
  }
  var isMatch = false;
  searchString = normalizeString(searchString);

  try {
    keys.forEach( function (key) {
      if (object[key] === null || object[key] === undefined || object[key] ==={}) {
        return;
      }
      var value = "";

      if(key === "fullname"){
        isMatch = searchInFullname(object, searchString);
        if(isMatch)
        throw BreakException;
      }

      if(typeof(object[key]) === "object" && object[key].fullname){
        isMatch = searchInFullname(object[key], searchString);
        if(isMatch)
        throw BreakException;
      }
      else if(Array.isArray(object[key])){
        var arrayValues = "";

        if(object[key].length > 0){

          object[key].forEach(el => {
            if(el.fullname){
              isMatch = searchInFullname(el, searchString);
              if(isMatch)
              throw BreakException;
            } else if(el.name){
              arrayValues += el.name;
            }
            else {
              arrayValues += el;
            }
          });
        }
        value = arrayValues;
      }
      else {
        value = object[key];
      }

      value = normalizeString(value);
      isMatch = value.indexOf(searchString) >= 0;

      if(isMatch){
        throw BreakException;
      }
    });
  }
  catch (e) {
    if(e !== BreakException)
      throw e;
  }

  return isMatch;
};

function normalizeString(string){
  return string.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^0-9a-z\u0370-\u03FF]/gi, "");
}

function searchInFullname(object, searchString){

  var isMatch = false;

  var fullname = normalizeString(object.fullname);

  isMatch = fullname.indexOf(searchString) >= 0;

  if (!isMatch) {
    var value = normalizeString(object.firstname + object.lastname);
    isMatch = value.indexOf(searchString) >= 0;
  }
  else {

  }

  return isMatch;
}

export const filterAdvancedSearchCompany = (row, searchDict) => {

  let companiesField = searchDict['name'];
  let groupsField = searchDict['groups'];

  let keepRow = keepCompanyName(row, companiesField) && keepGroup(row.groups, groupsField);

  if(keepRow){
    updateIsDisplayGroups(row, groupsField);
  }

  return keepRow;

}

function keepGroup(groups, groupsField){
  let keepRow = false;
  if(groupsField && groupsField.length){
    try {
      groups.forEach(group => {

        keepRow = groupsField.includes(group.name);
        if(keepRow){
          throw BreakException;
        }
      });

    } catch (e) {
      if(e !== BreakException)
        throw e;
    }
  } else {
    keepRow = true;
  }

  return keepRow;

}

function keepCompanyName(row, companiesField){
  let keepRow = false;
  if(companiesField && companiesField.length){
    keepRow = companiesField.includes(row.name);
  } else {
    keepRow = true;
  }
  return keepRow;
}

function updateIsDisplayGroups(row, groupsField){
  if(row.groups && row.groups.length){
    row.groups.forEach(group => {
      group.isDisplay = groupsField && groupsField.length > 0 && groupsField.some(groupField => groupField === group.name);
    });
  }
}

export const filterAdvancedSearch = function (fields, object) {

  var isMatch = false;

  try {
    Object.keys(fields).forEach(key => {

      if (object[key] === null || object[key] === undefined || object[key] ==={} || object[key] === '') {
        isMatch = false;
        throw BreakException;
      }
      // the .normalize(...).replace(...) removes accents
      var searchString = fields[key].value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^0-9a-z\u0370-\u03FF]/gi, "");

      var value = object[key];

      switch (fields[key].searchType) {
        case "list":
          isMatch = matchInArray(searchString, value);
          break;
        case "contains":
          if (typeof(value) === 'object'){
            value = value.fullname;
          }
          if(key === "fullname"){
            value = object.fullname + object.lastname + object.firstname;
          }
          isMatch = matchInString(searchString, value);
          break;
        case "strictly":
          if (typeof(value) === 'object'){
            value = value.fullname;
          }
          isMatch = matchInStringStrictly(searchString, value);
          break;
      }

      if(!isMatch){
        isMatch = false;
        throw BreakException;
      }

    });
  }
  catch (e) {
    if(e == BreakException)
      isMatch = false;
    else
      throw e;
  }
  return isMatch;
};

function matchInString(searchString, targetString){
  var value = targetString.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^0-9a-z\u0370-\u03FF]/gi, "");

  return value.includes(searchString);
}

function matchInStringStrictly(searchString, targetString){
  var value = targetString.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^0-9a-z\u0370-\u03FF]/gi, "");
  return value === searchString;
}

function matchInArray(searchString, array){

  var isMatch = false;
  var BreakException = {};

  try {

    array.forEach((elem) => {
        var value = elem.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^0-9a-z\u0370-\u03FF]/gi, "");
        if(value === searchString){
          isMatch = true;
          throw BreakException;
        }
        else{
          isMatch = false;
        }
    });

  }
  catch (e) {
    if (e !== BreakException) throw e;
  }

  return isMatch;

}

export const getAuthHeaders = (needJson) => {
  const logged = JSON.parse(sessionStorage.getItem('efpia-ldap-user'))
  let token = logged ? logged.refreshToken : '';
  let uid = logged ? logged.uid : '';

  if (needJson) {
    return {
      'Authorization': token,
      'user': uid,
      'Content-Type': 'application/json'
    }
  }
  return {
    'Authorization': token,
    'user': uid
  }
}

export const getLoggedUser = () => {
  const logged = JSON.parse(sessionStorage.getItem('efpia-ldap-user'))
  return logged && logged.firstname ? logged : null;
}
