import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ModalContactEdit = (props) => {
  const {
    buttonLabel,
    titleBody,
    text,
    duplicateLinkCb,
    action1,
    btnTextAction1
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const editAnyway = () => {
    action1();
    toggle();
  }

  return (
    <div>
      <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className="panelModal">
        <ModalHeader toggle={toggle}><FontAwesomeIcon icon={faExclamationTriangle} style={{fontSize : "40px"}}/> <span>{titleBody}</span></ModalHeader>
        <ModalBody>
          <p>{text}</p>
          <h4>To create a new contact <br/>please use the “Duplicate” button.</h4>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
          <Button color="primary" onClick={editAnyway}>{btnTextAction1}</Button>{' '}
          <Button color="primary" onClick={duplicateLinkCb}>Duplicate</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalContactEdit;
