import React from 'react';

import { Button, Badge, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faUndoAlt } from '@fortawesome/free-solid-svg-icons';

const FiltersView = React.forwardRef((props, ref) => {
  const {
    searchString,
    advancedSearch,
    goBack
  } = props;

  var displayFiltersBadge = searchString.length || Object.keys(advancedSearch).length;

  var generateFields = (fields) => {
    var displayAdvancedSearch = [];

    Object.keys(fields).forEach(fieldName => {

      var value = Array.isArray(fields[fieldName]) ? fields[fieldName].join(', ') : fields[fieldName].value;

      displayAdvancedSearch.push(<Badge key={fieldName + fields[fieldName].value} className="searchActive-badge text-truncate">
                                   <span style={{textTransform: "capitalize"}}>{fieldName + ': '}</span>
                                   {value}
                                 </Badge>)
    });
    return displayAdvancedSearch
  };

  return (
        <div className="filtersView" ref={ref}>
          <div className="searchActive">
            <div className="searchActive-hearder">
              <div className="searchActive-hearder-title">
                <h5>Filters</h5>
                <h5><Link to={goBack} id="UncontrolledTooltipExample">{displayFiltersBadge ? "Go back " : "Add filters "} <FontAwesomeIcon icon={faUndoAlt}/></Link></h5>
              </div>
              <UncontrolledTooltip placement="bottom" target="UncontrolledTooltipExample">
                You will be redirected to the complete list
              </UncontrolledTooltip>
            </div>
            {displayFiltersBadge ? <div className="searchActive-body">
              {searchString ? <Badge className="searchActive-badge">
                {searchString}
              </Badge> : null}
              {Object.keys(advancedSearch).length ?  generateFields(advancedSearch) : null}
            </div> : null}
          </div>
        </div>
  );
});

export default FiltersView;
