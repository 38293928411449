import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
  FormFeedback
} from 'reactstrap';
import { matchGroupnameInvalidChars } from "../../../SubComponent/fieldsValidators";
const MAX_CHARS = 73;
import GlossaryComponent from './GlossaryComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from "react-redux";

class ModalGroupNameValidator extends React.Component {
  constructor(props) {
    super(props);

    this.cancelAction = this.cancelAction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveAction = this.saveAction.bind(this);

    this.state = {
      isOpen: props.isOpen,
      titleModal : props.titleModal,
      label : props.label,
      invalidValue : props.invalidValue
    };
  }

  saveAction(){
    var value = this.state.inputValue.trim();
    this.props.successCb(value);
  }

  cancelAction(){
    this.props.cancelCb();

    this.setState({
      formIsUsed : false,
      isOpen:false,
      inputValue: ''
    });

  }

  static getDerivedStateFromProps(nextProps, prevState){
    const { isOpen, inputValue, titleModal } = nextProps;

    if(isOpen != prevState.isOpen){
      return { isOpen : isOpen, inputValue : inputValue, titleModal : titleModal}
    }
    return null;
  }

  handleChange(event) {

    this.setState({
      formIsUsed: true,
      inputValue: event.target.value.replace(/[\s]{2,}/g," ")
    });

    event.preventDefault();
  }

  groupAlreadyExistInDb(name){
    var isExistInDb = false;

    name = name ? name.toLowerCase().replace(/\s/g, '') : '';

    var groupExistInDb = this.props.ldap.groups.filter(function(group){
      if (group.name.toLowerCase().replace(/\s/g, '') === name){
        return group;
      }
    });

    if(this.props.mode === "EDIT" && name && this.props.inputValue && name.toLowerCase().replace(/\s/g, '') === this.props.inputValue.toLowerCase().replace(/\s/g, '')){
      isExistInDb = false
    } else {
      isExistInDb = groupExistInDb.length > 0;
    }

    return isExistInDb;
  }

  groupnameHaveInvalidChars(inputValue){
    var groupnameHaveInvalidChars = false;
    if(inputValue && inputValue.length){
      groupnameHaveInvalidChars = matchGroupnameInvalidChars(inputValue) !== null && matchGroupnameInvalidChars(inputValue).length > 0;
    }
    return groupnameHaveInvalidChars;
  }
  formCanBeSubmit(formIsUsed, inputValue, groupAlreadyExistInDb, groupnameHaveInvalidChars){
    var formCanBeSubmit = false;
    if(formIsUsed && inputValue.length){
      formCanBeSubmit = !groupAlreadyExistInDb && formIsUsed && inputValue.length != 0 && !groupnameHaveInvalidChars && !(inputValue.length > MAX_CHARS) && inputValue.trim() !== this.props.inputValue.trim();
    }
    return formCanBeSubmit;

  }

  render() {

    var groupAlreadyExistInDb = this.groupAlreadyExistInDb(this.state.inputValue);
    var isInvalidValue = groupAlreadyExistInDb && this.state.formIsUsed;
    var groupnameHaveInvalidChars = this.groupnameHaveInvalidChars(this.state.inputValue);
    var formCanBeSubmit = this.formCanBeSubmit(this.state.formIsUsed, this.state.inputValue, groupAlreadyExistInDb, groupnameHaveInvalidChars)
    var valueIsTooLong = this.state.inputValue ? this.state.inputValue.length > MAX_CHARS : false;

    return (
      <div>
        <Modal isOpen={this.state.isOpen} toggle={this.cancelAction} className="panelModal">
          <ModalHeader toggle={this.cancelAction} ><FontAwesomeIcon className="font-size-40" icon={faExclamationTriangle}/> <span>{this.state.titleModal}</span></ModalHeader>

          <ModalBody className="text-left">
            <Label className="required">{this.state.label}</Label>
            <Input autoComplete="off"
                   type="text"
                   name="name"
                   className="inputAppendLabel"
                   value={this.state.inputValue}
                   invalid={isInvalidValue || groupnameHaveInvalidChars || valueIsTooLong}
                   required
                   placeholder="Group name"
                   onChange={this.handleChange}/>

            <FormText><FontAwesomeIcon icon={faInfoCircle}/> 73 characters maximum</FormText>
            <FormFeedback tooltip style={{top: "inherit"}}>
              {groupAlreadyExistInDb ? this.state.inputValue + " already exist!" : null }
              {groupnameHaveInvalidChars ?  "Characters not allowed " + matchGroupnameInvalidChars(this.state.inputValue).join(" ") : null}
              {valueIsTooLong ? " " + this.state.label + " is too long. Max " + MAX_CHARS + " allowed. " : null}
            </FormFeedback>

            <GlossaryComponent/>

            <FormText>
              <span className="text-danger">*</span> Required fields.<br/>
              <strong className="p-0">Don't forget to save your modification after work.</strong>
            </FormText>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.cancelAction}>Cancel</Button>
            <Button color="primary" disabled={!formCanBeSubmit} onClick={this.saveAction}>Ok</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

export default compose(
  connect(mapStateToProps)
)(ModalGroupNameValidator);
