import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from "react-redux";

class ModalMembershipWidget extends React.Component {
  constructor(props) {
    super(props);
    this.successAction = this.successAction.bind(this);

    this.state = {
      isOpen: props.isOpen,
      group : props.group
    };

  }

  static getDerivedStateFromProps(nextProps, prevState){
    const { isOpen, group } = nextProps;

    if( isOpen != isOpen.prevState && Object.keys(group).length){

      var members = group.members;

      var membersCountNotInGroupToEdit =  members.filter(contact => !contact.isSelected).length ;
      return { isOpen : isOpen, group : group, membersCountNotInGroupToEdit : membersCountNotInGroupToEdit };
    }

    return null;
  }

  successAction(){
    this.props.successCb(this.state.group);
  }

  render() {


    return (
      <div>
        <Modal isOpen={this.state.isOpen} toggle={this.props.cancelCb} className="panelModal">
          <ModalHeader toggle={this.props.cancelCb} ><FontAwesomeIcon style={{fontSize : "40px"}} icon={faExclamationTriangle}/></ModalHeader>
          <ModalBody>
            {this.state.membersCountNotInGroupToEdit ? <div><p>{this.state.membersCountNotInGroupToEdit} contacts will be added to the current group.</p>
            <h4>Are you sure you want to continue ?</h4></div> :

            <p>All contacts in this group have already been added</p>}

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.cancelCb}>Cancel</Button>
            {this.state.membersCountNotInGroupToEdit ? <Button color="primary" onClick={this.successAction}>Yes</Button> : null}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalMembershipWidget ;
