import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";

import InputMultiSelect from './InputMultiSelect';

class CompanyAdvencedSearch extends React.Component {
  constructor(props) {
    super(props);

    this.companyHandleChange = this.companyHandleChange.bind(this);

    this.state = {
      companyAdvancedSearch: [],
      initFields: false
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { initAdvancedSearchFields } = nextProps;

    if(initAdvancedSearchFields){
      return { companyAdvancedSearch : []}
    }
    return null;
  }

  companyHandleChange(inputResult, fieldKey){
    var currentCompanyAdvancedSearch = this.state.companyAdvancedSearch;
    currentCompanyAdvancedSearch[fieldKey] = inputResult;

    if(!inputResult.length){
      delete currentCompanyAdvancedSearch[fieldKey] ;
    }

    this.setState({
      companyAdvancedSearch: currentCompanyAdvancedSearch
    });

    this.props.onSearchChanged(currentCompanyAdvancedSearch);

  }

  render(){

    return(
      <>
        <InputMultiSelect handleChangeCb={this.companyHandleChange}
                          id="name"
                          items={this.props.ldap.companies}
                          initField={this.props.initAdvancedSearchFields}
                          advancedSearch={this.props.advancedSearch}
                          label="Companies"/>
        <InputMultiSelect handleChangeCb={this.companyHandleChange}
                          id="groups"
                          advancedSearch={this.props.advancedSearch}
                          items={this.props.ldap.groups}
                          initField={this.props.initAdvancedSearchFields}
                          label="Groups"/>
      </>
    );
  }
}


const mapStateToProps = state => ({
  ldap: state.ldap
});

export default compose( connect(mapStateToProps) )(CompanyAdvencedSearch);
