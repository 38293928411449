import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUser, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getCountry } from './Country-List/countriesOverrided';
import { Tooltip } from 'reactstrap';

import { getURLPrefix } from '../../utils/baseURLService.js';
var pathname = getURLPrefix().pathname;

const ContactTitleHeader = (props) => {

  const {
    contact,
    className,
    tag,
    locationPathname,
    groupId
  } = props;

  var countryName = getCountry(contact.country);

  var action = () => {
    if(locationPathname){
      var stock = {};
      stock.locationPathname = locationPathname;
      stock.contactId = contact.id;
      stock.id = groupId;
      sessionStorage.setItem('group-redirect', JSON.stringify(stock));
    }

    contact.detailLinkCb();
  }

  return (
    <div className={className ? className + " titleHeaderBox" : "titleHeaderBox"}>
      <div className="titleHeaderBox-icon">
        <FontAwesomeIcon icon={faUser}/>
      </div>
      <div className="titleHeaderBox-body w-100">
        <h1 onClick={tag && tag == "a" ? action : null }
            style={{cursor :tag && tag == "a" ? "pointer" : null }}
            className="pannelTitle">
            {contact.title ? contact.title + " " : ""}
            {contact.firstname + " " + contact.lastname}
            {tag && tag == "a"
              ? <FontAwesomeIcon style={{ paddingLeft: "10px", fontSize: "inherit"}} icon={faExternalLinkAlt}/>
              : null
            }
        </h1>
        <h4>{contact.employeeType ? contact.employeeType + " @ " : null}<Link to={pathname + "company/details/" + contact.company} className="text-truncate mw-100 d-inline-block align-bottom">{contact.company}</Link>{countryName ? " in " + countryName : null}</h4>
      </div>
    </div>
  );
}

export default ContactTitleHeader;
