import React from 'react';

import List from '../List/ListFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from 'reactstrap';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';


class AssignedGroupView extends React.Component {
  constructor(props){
    super(props);
    this.getGroupsFormatted = this.getGroupsFormatted.bind(this);
    this.setEFIPAContactForAllGroups = this.setEFIPAContactForAllGroups.bind(this);

    this.state = {
      formIsChange: false,
      groupsAssigned: [],
      groups: props.groups,
      contactId: props.contactId,
      staffGroup: props.staffGroup,
      options: [],
      displayConfirmation: false,
      isLoading: false,
      currentlySaving: false,
      allGroupAssignedTo: ""
    }

  }

  componentDidMount(){
    if(this.props.staffGroup && this.props.staffGroup.length > 0){
      var options = this.props.staffGroup.filter(staff => staff.id !== this.props.contactId);

      this.setState({
        options: options
      })
    }
  }

  setEFIPAContactForAllGroups(event){
    var value = event.target.value;
    var stateGroupsAssigned = [];
    var groups = this.props.groups;

    groups.forEach(group => {
      stateGroupsAssigned.push({
        id: group.id, 
        newEFPIAContactAssigned : value
      })
    });

    this.setState({
      formIsChange: true,
      groupsAssigned: stateGroupsAssigned,
      allGroupAssignedTo : value
    })

  }

  getGroupsFormatted(groups){

    var self = this;
    var stateGroupsAssigned = this.state.groupsAssigned;

    return groups.map(group => {
      var groupEfpiaContactIsChange = stateGroupsAssigned.length > 0 && stateGroupsAssigned.filter(groupAssigned => groupAssigned.id === group.id).length > 0;

      group.handleChangeCb = function(event){
        var value = event.target.value;

        if(groupEfpiaContactIsChange){
          if(value && value.length){
            stateGroupsAssigned.map(groupAssigned => {
              if(groupAssigned.id === group.id){
                  groupAssigned.newEFPIAContactAssigned = value;
              }
              return groupAssigned
            });
          } else {
            stateGroupsAssigned = stateGroupsAssigned.filter(groupAssigned => groupAssigned.id !== group.id);
          }
        } else {
          
          group.newEFPIAContactAssigned = value;

          stateGroupsAssigned.push({
            id: group.id, 
            newEFPIAContactAssigned : value
          })  
        }

        self.setState({
          formIsChange: true,
          groupsAssigned: stateGroupsAssigned,
          allGroupAssignedTo: ""
        })
      };

      group.optionValue = groupEfpiaContactIsChange ? stateGroupsAssigned.filter(groupAssigned => groupAssigned.id === group.id)[0].newEFPIAContactAssigned : "";
      
      var groupsChanged = this.props.groupsChanged;
      if(groupsChanged && groupsChanged.length > 0){
        group.transferSuccessfully = groupsChanged.filter(groupChanged => groupChanged.value.id === group.id && groupChanged.status === "fulfilled").length > 0;
        group.transferRejected = groupsChanged.filter(groupChanged => groupChanged.value.id === group.id && groupChanged.status === "rejected").length > 0;
        if(group.transferSuccessfully){
          var groupTransferSuccessfully = groupsChanged.filter(groupChanged => groupChanged.value.id === group.id && groupChanged.status === "fulfilled")[0];
          group.contactTransfered = groupTransferSuccessfully.value.efpiaContact;
        }
      }
      
      return group;
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { contactId, staffGroup } = nextProps;

    if(contactId && contactId !== prevState.contactId){
      var optionsFormated = staffGroup.filter(staff => staff.id !== contactId);

      return { 
        contactId : contactId, 
        options : optionsFormated, 
        groupsAssigned: [],
        optionValue: ""
      };
    }
    return null;
  }

  render(){
    var groups = this.props.groups && this.props.groups.length > 0 ? this.getGroupsFormatted(this.props.groups) : this.props.groups;
    var options = this.state.options;

    if(options && options.length === 0 || this.props.isLoading){
      return 'Loading...'
    }

    var selectAllOptionsRow = { options: options, handleChangeCb: (event) => this.setEFIPAContactForAllGroups(event),  optionValue: this.state.allGroupAssignedTo};

    var cols = [
      {type : 'text', key : 'name', title : 'Group name'},
      {key:'members', type:'count', title:'Members count'},
      {key:'inputSelect', type:'inputSelect', title:'Select destination contact', options: options}
    ];

    var listOptions = {rowClickHandlerKey:"detailLinkCb"};

    var disabledAction = this.state.groupsAssigned.length <= 0 || this.props.isLoading || groups && groups.length <= 0;

    return (
      <div 
        className="panelBoxSection contactGroup-groupMembers mb-2 assignedGroupView" 
        style={{height: "calc(100% - 3rem)"}}>

        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faUsers}/>
          </div>
          <h2 className="m-0">Assigned groups</h2>
          <Badge color="light" pill>
            {groups.length === 1
              ? groups.length + " membership"
              : groups.length + " memberships"
            }
          </Badge>
        </div>

        <div className="panelBoxBody">
          <List 
            cols={cols} 
            rows={groups} 
            options={listOptions} 
            sortKey='name' 
            isLoading={this.props.isLoading} 
            selectAllOptionsRow={selectAllOptionsRow}
          />
        </div>

        <div className="d-flex justify-content-center">
          <Button 
            outline
            onClick={() => this.setState({groupsAssigned : [], optionValue: "", allGroupAssignedTo: ""})}
            disabled={disabledAction}
            className="mr-3"
          >
            Cancel
          </Button>
          {' '}
          <Button 
            color="primary" 
            disabled={disabledAction}
            onClick={() => {
              this.props.saveAction(this.state.groupsAssigned);
              this.setState({groupsAssigned: [], optionValue: "", allGroupAssignedTo: ""});
            }}
          >
            Transfert
          </Button>
        </div>

      </div>
    );

  }
}

export default AssignedGroupView;
