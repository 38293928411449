export default function user(state = {
  logged_user: {
    uid: null,
    firstname: null,
    lastname: null,
    token: null,
    perm: 0,
    refreshToken: null,
    expirationTimestamp: null
  },
  statusAuth: null,
  refreshToken: null,
  fetching: false,
  fetched: false,
  error: null,
}, action) {

  switch (action.type) {
    case "FETCH_USER": {
      return { ...state, fetching: true }
    }
    case "FETCH_USER_REJECTED": {
      return { ...state, fetching: false, error: action.payload }
    }
    case "FETCH_USER_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        logged_user: {
          uid: action.payload.uid,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          token: action.payload.token,
          perm: action.payload.perm,
          refreshToken: action.payload.refreshToken,
          timestamp: action.payload.timestamp
        }
      }
    }

    case "REFRESH_USER_TOKEN": {
      return { ...state, fetching: true }
    }
    case "REFRESH_USER_TOKEN_REJECTED": {
      return { ...state, fetching: false, error: action.payload }
    }
    case "REFRESH_USER_TOKEN_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        refreshToken: {
          token: action.payload.token,
          timestamp: action.payload.timestamp
        }
      }
    }

    case "FETCH_STATUS_AUTH": {
      return { ...state, fetching: true }
    }
    case "FETCH_STATUS_AUTH_REJECTED": {
      return { ...state, fetching: false, error: action.payload }
    }
    case "FETCH_STATUS_AUTH_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        statusAuth: action.payload
      }
    }

    default:
      return state;
  }
}
