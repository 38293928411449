import React from 'react';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle,faPlus } from '@fortawesome/free-solid-svg-icons';
import ListView from '../ListView/ListView';
import ModalCompanyEditView from '../CompanyView/ModalCompanyEditView';
import { toast } from 'react-toastify';
import { getLoggedUser } from '../../utils';

import { compose } from 'redux';
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom';
import { prepareDataFromApi } from '../../utils';
import { getURLPrefix } from '../../utils/baseURLService.js';

toast.configure({
  position: toast.POSITION.TOP_CENTER
});

import {
  createCompany,
  fetchGroups,
  fetchCompanies
} from '../ODD/action'

class CompanyListView extends React.Component{

    constructor(props){
      super(props);
      this.closeModalCb = this.closeModalCb.bind(this);
      this.companyValidCb = this.companyValidCb.bind(this);

      this.state = {
        companies:  [],
        isEditModalOpen : props.isEditModalOpen,
        companyName : '',
        current_user: getLoggedUser()
      };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
      const { companies, history } = nextProps;
      const { people, groups } = nextProps.ldap;

      if (companies.length > 0 && people.length > 0 && groups.length > 0){

        let data = prepareDataFromApi({
          people: people,
          groups: groups,
        });

        let groupsWithCompany = groups.map(group => {

          let companiesId = group.members.reduce((companiesId, member) => {

            if(!companiesId.some(companyId => companyId === member.company)){
              companiesId.push(member.company);
            }

            return companiesId;

          }, []);

          group.companiesId = companiesId;

          return group;

        });

        let companiesFormatted = groupsWithCompany.reduce((accCompaniesWithGroups, group) => {

            group.companiesId.forEach((companyId) => {

              let accCompanyWithGroups = accCompaniesWithGroups.filter(company => company.id === companyId)[0];

              if(accCompanyWithGroups){

                if(!accCompanyWithGroups.groups.some(accGroup => accGroup.id === group.id )){

                  let membersInCompany = group.members.reduce((accMembersId, member) => {
                    if(member.company === companyId){
                      accMembersId.push(member);
                    }
                    return accMembersId;
                  }, []);

                  accCompanyWithGroups.groups.push({
                    id:group.id,
                    name:group.name,
                    membersInCompany:membersInCompany
                  });
                }

              }
              else{

                accCompanyWithGroups = {
                  id : companyId,
                  name : companyId,
                  groups : []
                }

                let membersInCompany = group.members.reduce((accMembersId, member) => {
                  if(member.company === companyId){
                    accMembersId.push(member);
                  }
                  return accMembersId;
                }, []);

                accCompanyWithGroups.groups.push({
                  id:group.id,
                  name:group.name,
                  members : group.members,
                  membersInCompany:membersInCompany,
                });

                accCompaniesWithGroups.push(accCompanyWithGroups)
              }
            });

            return accCompaniesWithGroups;
        }, []);

        companies.forEach(company => {
          if(!companiesFormatted.some(companyFormatted => companyFormatted.id === company.id)){
            companiesFormatted.push({
              id : company.id,
              name : company.name,
              groups : []
            });
          }
        });

        companiesFormatted = companiesFormatted.map(company => {
          company.detailLinkCb = function(){
            history.push( getURLPrefix().pathname + 'company/details/' + encodeURIComponent(company.id));
          };
          return company;
        });



        return { companies : companiesFormatted  }
      }
      return null;
    }

    closeModalCb() {
      this.setState({isEditModalOpen: false});
      this.props.history.push( getURLPrefix().pathname + 'company/');
    }

    companyValidCb(company){
      company = company.trim();
      
      this.closeModalCb();
      this.setState({isLoading : true});
      var data = { name: company };

      this.props.textLoadingOverlay("Creating " + company + "...");
      this.props.loadingOverlayCb(true);

      this.props.onCreateCompany(data)
      .then(this.props.onFetchCompanies)
      .then(this.props.onFetchGroups)
      .then(() => {
          this.props.history.push( getURLPrefix().pathname + 'company/details/' + encodeURIComponent(company));
          this.props.loadingOverlayCb(false);
          toast(company + " has been created !", {type:"success"});
      })
      .catch(err => toast(err.response.data ? err.response.data : err.message, {
              type:"error",
              autoClose: false
            }))
      .finally(() => {
        this.setState({isLoading : false});
        this.props.loadingOverlayCb(false);});
    }

    render(){
      if (!this.state.companies){ 
        return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
      };
      var modeView = this.props.modeView;

      var cols = [
          {key:'name', type:'text', title: 'Company', displayedInCompact : true, typeExport: "rowspanExport"},
          {key:'groups', type:'multiCells', title: 'Groups', colsKeys: ["name", "membersInCompany"]}
      ];

      var defaultCols = ['name'];

      const listOptions = {rowClickHandlerKey:"detailLinkCb"};
      var current_user = this.state.current_user;

      var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

      var pathname = getURLPrefix().pathname;

      return(
          <div className="container-fluid h-100 pt-3">
          <Row>
            <Col className="pannelTitleList">
              <Link to={pathname + "company"} className="text-decoration-none"><h1>Companies</h1></Link>
              {isAllowedToEdit ? <Link to={pathname + 'company/new'}>
                  <Button color='primary' className="addBtn"><FontAwesomeIcon icon={faPlus}/></Button>
              </Link> : null }
            </Col>
          </Row>
          <ListView cols={cols}
                    rows={this.state.companies}
                    defaultCols={defaultCols}
                    sortKey='name'
                    targetLink={pathname + "company"}
                    rowSelectedId={this.props.requestedCompanyId}
                    listOptions={listOptions}
                    modeView={modeView}
                    advancedSearchType="COMPANY"
                    isClipboard={true}/>
          <ModalCompanyEditView 
            isOpen={this.props.isEditModalOpen}
            company=''
            cancelCb={this.closeModalCb}
            successCb={this.companyValidCb}
            mode='create'
            titleModal='New company'
            isLoading={this.state.isLoading}
            />
          </div>
      );
    }
};

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onFetchCompanies: () => dispatch(fetchCompanies()),
  onCreateCompany : (data) => dispatch(createCompany(data)),
  onFetchGroups: () => dispatch(fetchGroups())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyListView);
