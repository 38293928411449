import React from 'react';

import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from "react-redux";
import { prepareDataFromApi } from '../../utils';

import { fetchPeople, updateGroup } from '../ODD/action';

import {
  fetchDirectoryEditors
} from './action';

import {
  Container,
  Row,
  Col,
  Button,
  Spinner
 } from 'reactstrap';

import CloseComponent from '../SubComponent/CloseComponent';
import GroupTitleHeader from '../SubComponent/GroupTitleHeader';

import GroupMembersView from '../GroupMembersView/GroupMembersView';
import HistoryLog from '../SubComponent/HistoryLog';


import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;


class EditorsDetail extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      people: props.ldap.people,
      directoryEditors: props.directoryEditors,
      groups: props.ldap.groups,
      peopleById: [],
      groupsById: []
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { directoryEditors } = nextProps;
    const { people, groups } = nextProps.ldap;

    if (directoryEditors && people && people.length > 0 && groups && groups.length > 0) {

      directoryEditors.members = directoryEditors.members.map(contact => {

        if(typeof(contact) === "string"){

          contact = people.filter(person => person.id === contact)[0];

          contact.role = "";

          contact.groups = contact.groups.map(groupCurrent => {

            if(typeof(groupCurrent) === "string"){
              groupCurrent = groups.filter(group => group.id === groupCurrent)[0];
            }
            return groupCurrent;
          });
        }
        return contact;
      });

      return { directoryEditors: directoryEditors }
    }
    return null;
  }


  render() {

    if ( !this.state.directoryEditors && !this.state.directoryEditors.members.length && !this.state.people.length) {
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

    var group = this.state.directoryEditors;

    return (
      <Container fluid={true} className="groupDetailView"  style={{height : "100%", overflowY: "auto"}}>
        <div className="descriptionRow stickyRow">
          <Row>

            <Col xs={11}>
              <GroupTitleHeader group={group} badgeDisabled/>
            </Col>

            <Col xs={1} className="text-right">
              <CloseComponent target={pathname + "contact"} />
            </Col>

            <Col xs={12}  className="text-right">
              <Link to={pathname + "directoryEditors/edit"}>
              <Button color="primary">Edit</Button>
              </Link>
            </Col>

          </Row>
        </div>


        <GroupMembersView members={group.members} style={{height: "calc(100% - 230px)"}}/>
        <Row>
          <Col xs={12}>
            <HistoryLog logs={group.logs} />
          </Col>
        </Row>

      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user,
  admin: state.admin
});

const mapDispatchToProps = dispatch => ({
  onFetchPeople: () => dispatch(fetchPeople()),
  onUpdateGroup: (data) => dispatch(updateGroup(data))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditorsDetail);
