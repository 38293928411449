import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux"
import { matchPath } from 'react-router';
import ContactListView from '../ContactView/ContactListView';
import { withRouter } from 'react-router-dom';

import ContactDetailView from '../ContactView/ContactDetailView';
import ContactEditView from '../ContactView/ContactEditView';
import PanedViewport from '../PanedViewport/PanedViewport';
import { getLoggedUser } from '../../utils';

import {
  updateContact
} from '../ODD/action'

import { prepareDataFromApi } from '../../utils';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class ContactView extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      people: props.people,
      groups: props.groups,
      peopleById: [],
      groupsById: [],
      user: props.user,
      loadingOverlayCb : props.loadingOverlayCb,
      textLoadingOverlay : props.textLoadingOverlay,
      current_user: getLoggedUser()
    }
  }

  componentDidMount() {
    if (this.props.ldap.groups.length && this.props.people.length) {
      this.setState(prepareDataFromApi({people: this.props.ldap.people, groups: this.props.ldap.groups}))
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { people, groups } = nextProps.ldap;
    if (groups && (groups.length !== prevState.groupsById.length || people.length !== prevState.peopleById.length)) {
      return prepareDataFromApi({
        people: people,
        groups: groups,
      })
    }
    return null;
  }

  render() {
    const { location } = this.props;
    const {
      people,
      groups,
      groupsById,
      peopleById
    } = this.state;
    if (groupsById.length === 0 && peopleById.length === 0 && people && people.length > 0) return (<div>Loading...</div>)

    var detailsMatch = matchPath(location.pathname, {
      "path": pathname + "contact/details/:id",
      "exact": true,
      "strict": true
    });

    var editMatch = matchPath(location.pathname, {
      "path": pathname + "contact/edit/:id",
      "exact": true,
      "strict": true
    });

    var duplicateMatch = matchPath(location.pathname, {
      "path": pathname + "contact/duplicate/:id",
      "exact": true,
      "strict": true
    });

    var current_user = this.state.current_user;
    var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

    var isDetails = (detailsMatch !== null && detailsMatch.params);
    var isEdit = (editMatch !== null && editMatch.params && isAllowedToEdit);
    var isDuplicate = (duplicateMatch !== null && duplicateMatch.params && isAllowedToEdit);
    var requestedContactId = "";
    var contact = {};



    if(isDetails){
      requestedContactId = decodeURIComponent(detailsMatch.params.id);
      contact = this.props.ldap.people.filter(person => person.id == requestedContactId)[0];
    }
    else if (isEdit) {
      requestedContactId = decodeURIComponent(editMatch.params.id);
    }
    else if (isDuplicate){
      requestedContactId = decodeURIComponent(duplicateMatch.params.id);
    }

    var modeView = isDetails || isEdit || isDuplicate ? 'compacted' : 'extended';
    var children = [<ContactListView key='contactList'
                                     requestedContactId={requestedContactId}
                                     modeView={modeView}/>];

    if (isDetails) {
      children.push(<ContactDetailView  key='contactDetail'
                                        contact={contact}
                                        people={this.props.ldap.people}
                                        groups={this.props.ldap.groups} />);
    }

    if (isEdit) {
      children.push(<ContactEditView key={'edit-' + requestedContactId}
                                      contactId={editMatch.params.id}
                                      mode="EDIT"
                                      people={this.props.ldap.people}
                                      groups={groups}
                                      loadingOverlayCb={this.state.loadingOverlayCb}
                                      textLoadingOverlay={this.state.textLoadingOverlay}/>
      );
    }
    if (isDuplicate) {
      children.push(<ContactEditView key={'edit-' + duplicateMatch.params.id}
        contactId={requestedContactId}
        mode="DUPLICATE"
        people={this.props.ldap.people}
        groups={groups}
        loadingOverlayCb={this.state.loadingOverlayCb}
        textLoadingOverlay={this.state.textLoadingOverlay}/>
      );
    }


    return (
      <PanedViewport>
        {children}
      </PanedViewport>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onUpdateContact: (data) => dispatch(updateContact(data))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ContactView);
