import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import 'react-toastify/dist/ReactToastify.css';
import Favicon from 'react-favicon';

import Login from '../Login/Login';
import ProtectedApp from './ProtectedApp';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import logoFavicon from '../../assets/images/efpia-favicon.png';

import {
  fetchPeople,
  fetchGroups,
  fetchConfiguration
} from '../ODD/action'

const SESSION_NAME = 'efpia-ldap-user';
import { getURLPrefix } from '../../utils/baseURLService.js';

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      current_user: props.user.logged_user,
      loadingOverlayisActive : false,
      textLoadingOverlay : 'Loading'
    }
  }

  setDisplayLoadingOverlay = (isActive) => {
    this.setState({
      loadingOverlayisActive: isActive
    });
  }

  setTextLoadingOverlay = (text) => {
    this.setState({
      textLoadingOverlay: text
    });
  }

  componentDidMount() {
    const { current_user } = this.state;
    const { logged_user, refreshToken } = this.props.user;
  }

  componentDidUpdate() {
    const { current_user } = this.state;
    const { logged_user, refreshToken } = this.props.user;

    if (current_user.uid !== logged_user.uid) {
      sessionStorage.setItem(SESSION_NAME, JSON.stringify(logged_user));
      this.setState({
        current_user: logged_user
      })

      this.props.onFetchPeople()
      .then(this.props.onFetchGroups)
      .then(this.props.onFetchConfiguration)
      .then(() => {
        this.setDisplayLoadingOverlay(false);
      });

    }

    if(refreshToken && refreshToken.token !== logged_user.refreshToken){
      logged_user.refreshToken = refreshToken.token;
      logged_user.timestamp = refreshToken.timestamp;
      sessionStorage.setItem(SESSION_NAME, JSON.stringify(logged_user));
    }

  }

  render() {
    const { textLoadingOverlay, loadingOverlayisActive } = this.state;
    var pathname = getURLPrefix().pathname;

    return(
      <LoadingOverlay
      active={loadingOverlayisActive}
      spinner={<BounceLoader sizeUnit={"px"}
                             size={80}
                             color={'#ffffff'}
                             css={'margin : 1rem auto '}
                             />}
      text={textLoadingOverlay}
      styles={{
       overlay: (base) => ({
         ...base,
         zIndex : '100000',
         }),
       }}
     >
     <Favicon url={logoFavicon} />
      <Router>
        <ErrorBoundary>
        <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={5000} hideProgressBar={true}/>
          <Switch>
              <Route path={pathname} render={(props) => {
                return <ProtectedApp {...props}
                  displayLoadingOverlay={this.setDisplayLoadingOverlay}
                  textLoadingOverlay={this.setTextLoadingOverlay}
              />}} />
              <Route path={pathname + "login"} render={(props) => {
                return <Login {...props}
                  displayLoadingOverlay={this.setDisplayLoadingOverlay}
                  textLoadingOverlay={this.setTextLoadingOverlay}
              />}} />
          </Switch>
        </ErrorBoundary>
      </Router>
      </LoadingOverlay>
    );
  };
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onFetchPeople: () => dispatch(fetchPeople()),
  onFetchGroups: () => dispatch(fetchGroups()),
  onFetchConfiguration: () => dispatch(fetchConfiguration()),
  onSetGoogleCred : (data) => dispatch(setGoogleCred(data))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
