import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

const CustumDropdownComponent = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const {
    content,
    toggleContent,
    className
  } = props;

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={className ? className : ""}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {toggleContent}
      </DropdownToggle>
      <DropdownMenu className="text-white bg-dark p-2 w-250">
        {content}
      </DropdownMenu>
    </Dropdown>
  );
}

export default CustumDropdownComponent;
