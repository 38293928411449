import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { Collapse, Row, Col, InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import { sortRows, simpleSort } from '../../utils/sortUtils';
import sortArray  from "sort-array";
import { getCountry } from '../SubComponent/Country-List/countriesOverrided';
import CompanyAdvencedSearch from './CompanyAdvencedSearch';

import {
  fetchCompanies
} from '../ODD/action';

class AdvancedSearch extends React.Component {

  constructor(props) {
    super(props);

    this.result = [];

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      isOpen : props.isCollapse,
      fields : [],
      advancedSearch: []
    }
  }

  componentDidMount(){
    this.props.onFetchCompanies();
  }

  generateFields(fields){

    const fieldsRender = [];

    var advancedSearch = this.state.advancedSearch;

    Object.keys(fields).forEach((fieldName) => {

      if(fieldName == "company"){
        var companiesSorted = sortRows(this.props.ldap.companies, 'name', 'asc');

        var optionCompanyItems = companiesSorted.map((item) =>
            <option key={item.id} value={item.name}>{item.name}</option>
          );

        optionCompanyItems.unshift(<option key="company-selector" value="">*</option>);

        fieldsRender.push(<Col key={fieldName} xs={6} className="advancedSearchField">
                            <InputGroup className={fields[fieldName].isUsed || (advancedSearch && advancedSearch[fieldName] && advancedSearch[fieldName].value && advancedSearch[fieldName].value.length) ? "active" : '' }>
                              <InputGroupAddon addonType="prepend" style={{ zIndex: "1"}}>
                                <InputGroupText>{fields[fieldName].btnText}</InputGroupText>
                              </InputGroupAddon>
                              <Input autoComplete="noAutoComplete"
                                     type="select"
                                     style={{border: "none",left: "-2px"}}
                                     name="company"
                                     value={advancedSearch && advancedSearch[fieldName] ? advancedSearch[fieldName].value : fields[fieldName].value}
                                     onChange={this.handleChange}
                                     onFocus={this.handleFocus}
                                     onBlur={this.handleBlur}>
                                {optionCompanyItems}
                              </Input>
                            </InputGroup></Col>);

      }
      else if(fieldName == "country"){
        var countries = [];
        this.props.ldap.people.forEach(contact => {
          if(contact.country){
            var countryName = getCountry(contact.country) ? getCountry(contact.country) : contact.country;
            if(!countries.includes(countryName) && countryName){
              countries.push(countryName);
            }
          }
        });
        var sorted = sortArray(countries, { order: 'asc' });

        var optionCountryItems = countries.sort().map((item) =>
           <option key={item} value={item}>{item}</option>
         );
        optionCountryItems.unshift(<option key="country-selector" value="">*</option>);

        fieldsRender.push(<Col key={fieldName} xs={6} className="advancedSearchField">
                            <InputGroup className={fields[fieldName].isUsed || (advancedSearch && advancedSearch[fieldName] && advancedSearch[fieldName].value.length) ? "active" : '' }>
                              <InputGroupAddon addonType="prepend" style={{ zIndex: "1"}}>
                                <InputGroupText>{fields[fieldName].btnText}</InputGroupText>
                              </InputGroupAddon>
                              <Input autoComplete="noautoComplete"
                                     type="select"
                                     style={{border: "none",left: "-2px"}}
                                     name="country"
                                     value={advancedSearch && advancedSearch[fieldName] ? advancedSearch[fieldName].value : fields[fieldName].value}
                                     onChange={this.handleChange}
                                     onFocus={this.handleFocus}
                                     onBlur={this.handleBlur}>
                                {optionCountryItems}
                              </Input>
                            </InputGroup></Col>);
      }
      else if(fieldName == "efpiaContact"){

        var efpiaContacts = [];
        var efpiaContactGroup = this.props.ldap.groups.filter(group => group.id == this.props.ldap.configuration.staffGroupId)[0];
        efpiaContacts = simpleSort(efpiaContactGroup.members.map(person => person.fullname ));

        var optionEfpiaContactsItems = efpiaContacts.map((item) =>
           <option key={item} value={item}>{item}</option>
         );
        optionEfpiaContactsItems.unshift(<option key="efpiaContacts-selector" value="">*</option>);

        fieldsRender.push(<Col key={fieldName} xs={6} className="advancedSearchField">
                            <InputGroup className={fields[fieldName].isUsed || (advancedSearch && advancedSearch[fieldName] && advancedSearch[fieldName].value.length) ? "active" : '' }>
                              <InputGroupAddon addonType="prepend" style={{ zIndex: "1"}}>
                                <InputGroupText>{fields[fieldName].btnText}</InputGroupText>
                              </InputGroupAddon>
                              <Input autoComplete="noautoComplete"
                                     type="select"
                                     style={{border: "none",left: "-2px"}}
                                     name="efpiaContact"
                                     value={advancedSearch && advancedSearch[fieldName] ? advancedSearch[fieldName].value : fields[fieldName].value}
                                     onChange={this.handleChange}
                                     onFocus={this.handleFocus}
                                     onBlur={this.handleBlur}>
                                {optionEfpiaContactsItems}
                              </Input>
                            </InputGroup></Col>);
      }
      else if(fieldName == "type"){
        fieldsRender.push(<Col key={fieldName} xs={6} className="advancedSearchField">
                            <InputGroup className={fields[fieldName].isUsed || (advancedSearch && advancedSearch[fieldName] && advancedSearch[fieldName].value.length) ? "active" : '' }>
                              <InputGroupAddon addonType="prepend" style={{ zIndex: "1"}}>
                                <InputGroupText>{fields[fieldName].btnText}</InputGroupText>
                              </InputGroupAddon>
                              <Input autoComplete="noautoComplete"
                                     type="select"
                                     style={{border: "none",left: "-2px"}}
                                     name="type"
                                     value={advancedSearch && advancedSearch[fieldName] ? advancedSearch[fieldName].value : fields[fieldName].value}
                                     onChange={this.handleChange}
                                     onFocus={this.handleFocus}
                                     onBlur={this.handleBlur}>
                                <option value="">*</option>
                                <option value="experts">experts</option>
                                <option value="network">network</option>
                              </Input>
                            </InputGroup></Col>);
      }
      else {
        fieldsRender.push(<Col key={fieldName} xs={6} className="advancedSearchField">
                            <InputGroup className={fields[fieldName].isUsed || (advancedSearch && advancedSearch[fieldName] && advancedSearch[fieldName].value.length) ? "active" : '' }>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>{fields[fieldName].btnText}</InputGroupText>
                              </InputGroupAddon>
                              <Input name={fieldName}
                                     value={advancedSearch && advancedSearch[fieldName] ? advancedSearch[fieldName].value : fields[fieldName].value}
                                     onChange={this.handleChange}
                                     onFocus={this.handleFocus}
                                     onBlur={this.handleBlur}
                                     type="search"
                                     autoComplete="off"/>
                            </InputGroup></Col>);
      }
    });
    return ( fieldsRender );
  }

  handleChange(event) {
    var fieldsState = this.state.fields;
    var advancedSearch = this.state.advancedSearch;
    const search = event.target.value;

    fieldsState[event.target.name].value = search;
    fieldsState[event.target.name].isUsed = search.length > 0;

    var fieldsUsed = [];

    Object.keys(fieldsState).forEach((key) => {
      if(fieldsState[key].isUsed){
        fieldsUsed[key] = {
          value : fieldsState[key].value,
          searchType : fieldsState[key].searchType
        };
        advancedSearch[key] = {
          value : fieldsState[key].value,
          searchType : fieldsState[key].searchType
        };
      }

      if(advancedSearch[key] && !fieldsState[key].isUsed){
        if(event.target.name !== key && fieldsState[key].value !== 0){
          fieldsUsed[key] = {
            value : advancedSearch[key].value ,
            searchType : advancedSearch[key].searchType
          }
        }
        if(event.target.name === key){
          advancedSearch[key] = null;
        }
      }
    });

    this.setState({
      fields:fieldsState,
      advancedSearch: advancedSearch
    });

    this.props.onSearchChanged(fieldsUsed);
    event.preventDefault();
  }

  handleFocus(event){
    var fieldsState = this.state.fields;
    fieldsState[event.target.name].isUsed = true;
    this.setState({
      fields : fieldsState
    });
  }

  handleBlur(event){
    var fieldsState = this.state.fields;

    if (event.target.value.length == 0) {
      fieldsState[event.target.name].isUsed = false;
      fieldsState[event.target.name].value = '';
    }

    this.setState({
      fields : fieldsState
    });

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isCollapse, ldap, type, advancedSearch } = nextProps;

    if ( ldap.companies.length > 0) {
      var fields =[];


      switch(type){
        case "CONTACT" : fields['fullname'] = {searchType: 'contains', btnText : 'Contact name', isUsed : false, value : ''};
                         fields['email']= {searchType: 'contains', btnText : 'Email', isUsed : false, value : ''};
                         fields['company'] = {searchType: 'strictly', btnText : 'Company', isUsed : false, value : ''};
                         fields['country']= {searchType: 'strictly', btnText : 'Country', isUsed : false, value : ''};
        break;
        case "GROUP" :   fields['name'] = {searchType: 'contains', btnText : 'Group name', isUsed : false, value : ''};
                         fields['email']= {searchType: 'contains', btnText : 'Group email', isUsed : false, value : ''};
                         fields['company'] = {searchType: 'list', btnText : 'Company', isUsed : false, value : ''};
                         fields['efpiaContact'] = {searchType:'contains', btnText : 'EFPIA contact', isUsed : false, value : ''};
                         fields['type'] = {searchType: 'contains', btnText : 'Group type', isUsed : false, value : ''};
        break;
      }

      return { isOpen: isCollapse, fields : fields, advancedSearch: advancedSearch }
    }
    return null;
  }

  render() {

    var fields = this.generateFields(this.state.fields);

    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="advancedSearch">
          <Row>
            {this.props.type === "COMPANY" ? <CompanyAdvencedSearch onSearchChanged={this.props.onSearchChanged}
                                                                    advancedSearch={this.props.advancedSearch}
                                                                    initAdvancedSearchFields={this.props.initAdvancedSearchFields}/> : fields}
          </Row>
        </Collapse>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap
});

const mapDispatchToProps = dispatch => ({
  onFetchCompanies: () => dispatch(fetchCompanies())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearch);
