import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import {MultiSelect} from "react-multi-select-component";

import {
  fetchDirectoryEditors
} from '../../DirectoryAdminTools/action';

import { sortRows } from '../../../utils/sortUtils';

import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  FormText
} from 'reactstrap';

class GroupFormEditors extends React.Component{
  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      editorsSelected : [],
      editorsOptions : []
    }
  }

  componentDidMount() {
    this.props.onFetchDirectoryEditors();
    const { staffGroup, editors } = this.props;

    if(editors && editors.length > 0 && Object.keys(staffGroup).length > 0){

      this.setState({
        editorsOptions: editors.map(contact => { return { label: contact.id, value: contact.id, disabled : false } }),
        editorsSelected : editors
      })
    }
  }

  handleChange(options){

    var value = event.target.value;
    var editorsOptionsFormatted = options.map(opt => opt.value);

    var newEditors = this.props.ldap.people.filter(person => editorsOptionsFormatted.some(opt => person.id === opt));

    var staffGroup = this.props.staffGroup;
    var formIsUsed = true;


    this.setState({
      editorsOptions : options,
      editorsSelected : newEditors
    });
    if(formIsUsed){
      if(this.props.editors !== newEditors){
        this.props.boxCanBeSubmit(formIsUsed, this.props.editors !== newEditors, newEditors);
      } else {
        this.props.boxCanBeSubmit(formIsUsed, this.props.editors !== newEditors);
      }
    }
  }



  getStaffGroupMembersFormatted(editors, staffMembers){
    var staffMembersFiltered = [];

    var direcoryEditorsMembers = this.props.admin.directoryEditors.members;
    var staffMembersFormatted = staffMembers.map(contact => {
        contact.disabled = !direcoryEditorsMembers.some(editorId => editorId === contact.id);
        return contact;
    });

    return staffMembersFormatted;
  }

  render(){

    var direcoryEditors = this.props.admin.directoryEditors;

    if(!this.state.editorsSelected || !Object.keys(direcoryEditors).length){
      return "Loading ..."
    }

    var editorsItems = this.getStaffGroupMembersFormatted(this.props.editors, this.props.staffGroup.members);

   var listEditorsItems = this.state.editorsSelected.length ? this.state.editorsSelected.map((item, index) =>
      <ListGroupItem key={"ed"+ item.id}><span className="cell-lastname">{item.lastname}</span> <span className="cell-firstname">{item.firstname}</span></ListGroupItem>
    ) : <ListGroupItem>All LPOv2 editors are allowed to edit this group.</ListGroupItem> ;

    var options = editorsItems.map(item => {
      return { label: item.id, value: item.id, disabled : item.disabled } ;
    });
    const customValueRenderer = (selected, _options) => {
      return <span className="color-primary">Select Editors...</span>;
    };

    return (
      <div className="panelBoxSection groupFormEditors" >
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faUserEdit}/>
          </div>
          <h2>Authorized editors</h2>
          <FormText className="mt-3-negatif mb-3"  color="muted">
           If no editor is selected, by default, all editors will be allowed to edit this group.
          </FormText>
        </div>
        <div className="panelBoxBody">
          <Row>
            <Col>
              <MultiSelect options={options}
                           value={this.state.editorsOptions}
                           onChange={this.handleChange}
                           hasSelectAll={false}
                           valueRenderer={customValueRenderer}
                           labelledBy={"Select"}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="groupFormEditors-ul" flush>
                {listEditorsItems}
              </ListGroup>
            </Col>
          </Row>
         </div>
        </div>
    );
  }
}
const mapStateToProps = state => ({
  ldap: state.ldap,
  admin: state.admin
});

const mapDispatchToProps = dispatch => ({
  onFetchDirectoryEditors: () => dispatch(fetchDirectoryEditors())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(GroupFormEditors);
