import React from 'react';
import {
  Modal,
  ModalHeader
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from "react-redux";

import DeleteConfirmationView from '../DeleteSubComponents/DeleteConfirmationView';
import DeleteGroupView from '../DeleteSubComponents/DeleteGroupView';

class ModalGroupDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen : props.isOpen,
      groupId : props.groupId,
      groupIdConfirmed: ''
    };

    this.toggle = this.toggle.bind(this);
    this.saveAction = this.saveAction.bind(this);

    this.setGroupIdConfirmed = this.setGroupIdConfirmed.bind(this);

  }

  toggle = () =>{
    this.props.toggle();
    this.setState({
      groupIdConfirmed : ''
    })
  }

  saveAction(){
    this.props.successCb(this.state.groupId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { groups } = nextProps.ldap;
    const { groupId } = nextProps;

    if ( groups && groups.length > 0 && groupId && groupId.length > 0){

      var group = groups.filter(group => group.id == groupId)[0];

      return { group : group };
    }
    return null;
  }

  setGroupIdConfirmed(groupIdConfirmed){
    this.setState({
      groupIdConfirmed : groupIdConfirmed
    });
  }

  render() {

    var groupIdConfirmed = this.state.groupIdConfirmed;

    return (
      <div>

        <Modal isOpen={this.props.isOpen} toggle={() => { }} className="panelModal deleteModal">
          <ModalHeader style={{backgroundColor : "#dc3545"}} >
              <FontAwesomeIcon icon={faTrashAlt} style={{fontSize : "40px"}}/> <span>You are about to remove<br/>{this.state.groupId} from LPO</span>
          </ModalHeader>

          {!groupIdConfirmed ? <DeleteConfirmationView setGroupIdConfirmed={this.setGroupIdConfirmed}
                                  groupId={this.state.groupId}
                                  boxModule={this.props.ldap.configuration.boxModule}
                                  toggle={this.toggle}/> : null}
          {groupIdConfirmed 
            ? <DeleteGroupView 
                toggle={this.toggle}
                groupId={this.state.groupId}
                deleteAction={this.saveAction}
                isLoading={this.props.isLoading}/> 
            : null
          }
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ldap: state.ldap
});

export default compose( connect(mapStateToProps) )(ModalGroupDelete);
