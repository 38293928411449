import React from 'react';
import queryString from 'query-string';

import ModalKeepAlive from './ModalKeepAlive';
import Moment from 'moment';

const SEC_BETWEEN_TWO_REFRESH_TOKEN = 45;

class KeepAliveComponent extends React.Component {

  constructor(props){
    super(props);
    this.disconnect = this.disconnect.bind(this);
    this.restartSession = this.restartSession.bind(this);
    this.lastTokenRefreshTimestamp = null;
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.isRefreshingToken = false;

    this.state = {
      modalKeepAliveIsOpen : false,
      expiratedTimestamp: null,
      isLoading : false,
      disconnectedSession: false
    }
  }

  componentDidMount(){
    var refreshTokenTimestamp = this.props.refreshTokenTimestamp;

    if(refreshTokenTimestamp){
      this.getTimer(refreshTokenTimestamp);
      this.setState({expiratedTimestamp: refreshTokenTimestamp});
      this.lastTokenRefreshTimestamp = Moment().unix();
    }
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  disconnect(){
    clearInterval(this.timer);
    this.props.logout()
    this.lastTokenRefreshTimestamp = null;
  }

  restartSession(){
    clearInterval(this.timer);
    this.refreshToken();
  }

  getTimer(expiratedTimestamp){
    this.timer = setInterval(() => {
      const nowTimestamp = Moment().unix();
      const remainingTime  = expiratedTimestamp - nowTimestamp;
      const TIME_LIMIT = this.props.secBeforeModalWarningExpiredSession + 3;
      const modalKeepAliveIsOpen = TIME_LIMIT > Math.floor(remainingTime % 3600);

      this.setState({modalKeepAliveIsOpen: modalKeepAliveIsOpen})
    }, 1000);
  }

  refreshToken(){
    this.isRefreshingToken = true;
    this.setState({isLoading: true});
    var self = this;

    this.props.getRefreshToken()
    .then(() => {

      if(self.props.refreshTokenTimestamp !== self.state.expiratedTimestamp){
        clearInterval(self.timer);
        self.lastTokenRefreshTimestamp = Moment().unix();
        self.getTimer(self.props.refreshTokenTimestamp);
        self.setState({modalKeepAliveIsOpen: false, expiratedTimestamp: self.props.refreshTokenTimestamp});
      }
    })
    .catch(() => {
      this.disconnect();
    })
    .finally(() => {
      this.isRefreshingToken = false;
      this.setState({isLoading : false});
    })
  }

  handleMouseMove(){
    var needToRefreshToken = false;

    if(this.lastTokenRefreshTimestamp){
      var elapsedSecFromLastTokenRefresh =  Moment().unix() - this.lastTokenRefreshTimestamp;
      needToRefreshToken = elapsedSecFromLastTokenRefresh >= SEC_BETWEEN_TWO_REFRESH_TOKEN;
    }

    if(needToRefreshToken && !this.isRefreshingToken){
      this.restartSession();
    }

  }

  render() {
    return(
      <div>
        <div onMouseMove={this.handleMouseMove}>
          {this.props.children}
        </div>
        {this.state.expiratedTimestamp
          ? <ModalKeepAlive 
              isOpen={this.state.modalKeepAliveIsOpen}
              expiratedTimestamp={this.state.expiratedTimestamp}
              secBeforeModalWarningExpiredSession={this.props.secBeforeModalWarningExpiredSession}
              viewAfterDisconnection={this.props.viewAfterDisconnection}
              disconnect={this.disconnect}
              restartSession={this.restartSession}
              isLoading={this.state.isLoading}
              disconnectedSession={this.state.disconnectedSession}
            />
          : null
        }
      </div>
    );
  };
};


export default KeepAliveComponent;
