import React from 'react';
import {
  ListGroup,
  ListGroupItem
 } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

const GroupEditors = (props) => {
  const {
    editors
  } = props;

  var editorsList = editors.map((contact, index) => {
    return <ListGroupItem key={index + contact.id}>
              <span className="cell-lastname">{contact.lastname}</span> <span className="cell-firstname">{contact.firstname}</span>
           </ListGroupItem>;
  });

  return (
    <div className="panelBoxSection editors-history">
      <div className="panelBoxTitle">
        <div className="panelBoxTitle-icon">
          <FontAwesomeIcon icon={faUserEdit}/>
        </div>
        <h2>Authorized editors</h2>
      </div>
      <div className="panelBoxBody">
      <div className="content-box flexColum">
      <ListGroup className="editorsList" flush>
        {editorsList}
      </ListGroup>
      </div>
      </div>
    </div>
  );
}

export default GroupEditors;
