import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PhoneNumber  from "awesome-phonenumber";

import {
  Container,
  Row,
  Col,
  Form,
  Spinner } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import { getLoggedUser } from '../../utils';
import { getLockStatus, lockResource, unlockResource, getLockListStatus} from '../../utils/lockService';

import Header from './ContactEditView/SubComponents/Header';
import ContactEditPerson from './ContactEditView/SubComponents/ContactEditPerson';
import ContactEditCompany from './ContactEditView/SubComponents/ContactEditCompany';
import ContactEditAddress from './ContactEditView/SubComponents/ContactEditAddress';
import ContactEditToolsAccess from './ContactEditView/SubComponents/ContactEditToolsAccess';
import ContactMembershipWidget from './ContactEditView/SubComponents/ContactMembershipWidget';
import ModalContactDelete from '../ContactView/ModalContactDelete';
import BoxModalComponent from './ContactEditView/SubComponents/ModalComponents/BoxModalComponent';


import {
  createContact,
  updateContact,
  deleteContact,
  fetchPeople,
  fetchGroups,
  fetchCompanies
} from '../ODD/action';

const POLLING_LOCK_TIMER = 240000;

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

import ContactValidationSchema from '../../../server/jsonSchema/personCreate.schema.json';

class ContactEditView extends React.Component{

    constructor(props){
      super(props);
      this.onFormSubmitCreateContact = this.onFormSubmitCreateContact.bind(this);
      this.onFormSubmitEditContact = this.onFormSubmitEditContact.bind(this);
      this.prepareGroupData = this.prepareGroupData.bind(this);
      this.getGroupsSelected = this.getGroupsSelected.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.contactCompanyCanBeSubmit = this.contactCompanyCanBeSubmit.bind(this);
      this.contactAddressCanBeSubmit = this.contactAddressCanBeSubmit.bind(this);
      this.contactPersonCanBeSubmit = this.contactPersonCanBeSubmit.bind(this);

      this.updateToolsAcces = this.updateToolsAcces.bind(this);

      this.modaleDelete = this.modaleDelete.bind(this);
      this.deleteContact = this.deleteContact.bind(this);

      this.unselectGroupAction = this.unselectGroupAction.bind(this);
      this.selectGroupAction = this.selectGroupAction.bind(this);
      this.cancel = this.cancel.bind(this);

      this.contactToEdit = null;

      if (props.contactId) {
        this.contactToEdit = props.people.filter(person => person.id == props.contactId)[0];
      }

      this.displayBoxInfoModal = this.displayBoxInfoModal.bind(this);

      this.state = {
          current_user: getLoggedUser(),
          contactCompanyIsValid : false,
          contactAddressIsValid: false,
          contactPersonIsValid: false,
          isOpen : false,
          formIsUsed : false,
          title : '',
          firstname : '',
          lastname : '',
          company : '',
          country : '',
          email : '',
          employeeType : '',
          phone : '',
          mobile : '',
          departmentNumber : '',
          postalAddress : '',
          postalCode : '',
          city : '',
          isLoading : false,
          groups: this.prepareGroupData(props.groups, props.mode, this.contactToEdit),
          people: props.people,
          companies: props.ldap.companies,
          country: '',
          company : '',
          groupsSelected : this.getGroupsSelected(this.props.groups),
          otherGroups : this.updateOtherGroup(this.props.groups),
          current_user: getLoggedUser(),
          lock : {
            isLocked : false
          },
          searchString: '',
          otherGroupsSearchString: '',
          haveGroupsAdded: false,
          haveGroupsAdded: false,
          haveGroupsRemoved: false,
          toolsAccess: [],
          itemsLocked: [],
          currentlySaving: false
         };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { mode, contactId, ldap, people } = nextProps;

      if(mode && people.length > 0 && ldap.groups.length > 0 && ldap.companies.length > 0 && (prevState.mode !== mode)){
        var current_user = getLoggedUser();
        let contactToEdit = [];
        if (contactId){
          contactToEdit = people.filter(person => person.id == nextProps.contactId)[0];
          contactToEdit.groups = ldap.groups.filter(group => group.members.filter(contact => contact.id === contactToEdit.id).length );
          contactToEdit.groups.forEach(group => {
            group.newGroupSelected = false;
            group.role = "";
            if(mode === "EDIT"){
              if(group.chairs.some(chair => chair.id === contactId)){
                group.role = "Chair";
              }
              if(group.viceChairs.some(chair => chair.id === contactId)){
                group.role = "Vice-chair";
              }
            }
          });
        }

        var state = {
            title : contactToEdit.title ? contactToEdit.title : '',
            company : contactToEdit.company ? contactToEdit.company : '',
            country : contactToEdit.country ? contactToEdit.country : '',
            employeeType : contactToEdit.employeeType ? contactToEdit.employeeType : '',
            departmentNumber : contactToEdit.departmentNumber ? contactToEdit.departmentNumber : '',
            postalAddress : contactToEdit.postalAddress ? contactToEdit.postalAddress : '',
            postalCode : contactToEdit.postalCode ? contactToEdit.postalCode : '' ,
            city : contactToEdit.city ? contactToEdit.city : ''
          }

        if(mode === "DUPLICATE"){
          state.title = '';
          state.firstname = '';
          state.lastname = '';
          state.email = '';
          state.phone = '';
          state.mobile = '';
          state.toolsAccess = [];

          if(current_user.perm !== "ADMIN"){
            var groupsSelected = [];
            var otherGroups = [];
            var staffGroupId = ldap.configuration.staffGroupId;

            contactToEdit.groups.forEach(group => {
              if(group.id !== staffGroupId){
                if(group.editors === undefined || !(group.editors.filter(editor => editor.id === current_user.uid).length === 0)){
                  group.isSelected = true;
                  groupsSelected.push(group);
                }
              }
            });

            ldap.groups.forEach(group => {

              if(!groupsSelected.some(currentGroup => currentGroup.id === group.id)){
                group.isSelected = false;
                otherGroups.push(group);
              }
            });

            state.groupsSelected = groupsSelected;
            state.otherGroups = otherGroups;
          }

        }

        if(mode === "EDIT"){
          state.firstname = contactToEdit.firstname;
          state.lastname = contactToEdit.lastname;
          state.email = contactToEdit.email;
          state.phone = contactToEdit.phone;
          state.mobile = contactToEdit.mobile;
          state.toolsAccess = contactToEdit.toolsAccess;
          state.groupsSelected = contactToEdit.groups;

          var otherGroups = [];
          ldap.groups.forEach(group => {
            if(!contactToEdit.groups.some(currentGroup => currentGroup.id === group.id)){
              group.isSelected = false;
              otherGroups.push(group);
            }
          });

          state.otherGroups = otherGroups;
        }

        state.mode = mode;
        state.companies = ldap.companies.map(company => company.name);

        return state;
      }

      return null;
    }

    componentDidUpdate(prevProps, prevState){

      if(prevProps.contactId !== this.props.contactId){
        clearInterval(this.lockTimer);
        this.lockTimer = null;
        var resourceID = this.props.contactId;
        this.getItemsLockListStatus();
        lockResource(resourceID);
        this.lockTimer = setInterval(() => {
          lockResource(resourceID);
          this.getItemsLockListStatus();
        }, POLLING_LOCK_TIMER);
      }
    }

    componentDidMount(){
      this.props.onFetchCompanies();

      this.blockNavigation = this.props.history.block((location) => {
        const promptMessage = "Do you really want to leave this page ? You will lost all the current edition.";

        if (this.state.formIsUsed && !this.state.currentlySaving) {
          if(window.confirm(promptMessage)){
            if(this.props.mode === "EDIT"){
              unlockResource(this.props.contactId);
            }
            return true;
          } else {
            return false;
          }
        } else {
          if(this.props.mode === "EDIT")
            unlockResource(this.props.contactId);
        }
        return true;
      });

      if(this.props.mode && this.props.mode !== "EDIT"){
        this.setState({
          haveGroupsAdded: true
        });
      }
      
      if(this.contactToEdit && this.props.mode && this.props.mode === "EDIT"){
        var resourceID = this.contactToEdit.id;
        this.checkLockStatus(resourceID);
        this.getItemsLockListStatus();

        clearInterval(this.lockTimer);
        this.lockTimer = null;
        lockResource(resourceID);
        this.lockTimer = setInterval(() => {
          lockResource(resourceID);
          this.getItemsLockListStatus();
        }, POLLING_LOCK_TIMER);
      }
    }

    getItemsLockListStatus(){
      var resourceIDS = this.contactToEdit.groups.map(item => item.id );
      var self = this ;
      getLockListStatus(resourceIDS)
      .then((listStatus) => {
        self.setState({
          itemsLocked : listStatus
        });
      });
    }

    checkLockStatus(resourceID){
      getLockStatus(resourceID)
      .then((status) => {
        if(status.isLocked && this.state.current_user.uid !== status.authUsername){
          toast(resourceID + " is currently edited by another user", {type:"error"});
          this.props.history.push(pathname + 'contact/details/' + encodeURIComponent(resourceID));
        }
        else{
          lockResource(resourceID);
        }
      });
    }

    componentWillUnmount() {
      this.blockNavigation();
      this.clearLockTimer();
    }

    clearLockTimer(){
      clearInterval(this.lockTimer);
      this.lockTimer = null;
    }

    resetContactWithEmptyValue(){
      this.setState({
        title : '',
        firstname : '',
        lastname : '',
        company : '',
        country : '',
        email : '',
        employeeType : '',
        phone : '',
        mobile : '',
        departmentNumber : '',
        postalAddress : '',
        postalCode : '',
        city : '',
        toolsAccess: []
      });
    }

    onFormSubmitCreateContact(event) {

      this.setState({isLoading:true, currentlySaving: true});

      var groupIdsSelected = this.state.groupsSelected.map(group => {return group.id});

      var contact = this.genContactToSend();

      contact.title = this.state.title.length ? this.state.title : '';
      contact.phone = this.state.phone.length ? new PhoneNumber(this.state.phone).getNumber() : '';
      contact.mobile = this.state.mobile.length ? new PhoneNumber(this.state.mobile).getNumber() : '';
      contact.departmentNumber = this.state.departmentNumber.length ? this.state.departmentNumber : '';
      contact.postalAddress = this.state.postalAddress.length ? this.state.postalAddress : '';
      contact.postalCode = this.state.postalCode.length ? this.state.postalCode : '';
      contact.city = this.state.city.length ? this.state.city : '';
      contact.groups = groupIdsSelected;
      contact.employeeType = this.state.employeeType.length ? this.state.employeeType : '';
      contact.toolsAccess = this.state.toolsAccess.length ? this.state.toolsAccess : [];

      this.props.textLoadingOverlay("Creating " + contact.firstname + ' ' + contact.lastname + '...');
      this.props.loadingOverlayCb(true);


      var contactId = '';

      this.props.onCreateContact(contact)
      .then(newContactId => {
        contactId += newContactId;
      })
      .then(this.props.onFetchPeople)
      .then(this.props.onFetchGroups)
      .then(() => {
        toast(contact.firstname + " " + contact.lastname + " has been created !", {type : "success"});
        this.props.history.push( pathname + 'contact/details/' + contactId);
      })
      .catch(err => toast(err.response.data ? err.response.data : err.message, {
              type:"error",
              autoClose: false
            }))
      .finally(() => {
        this.setState({isLoading : false});
        this.props.loadingOverlayCb(false);
      })

      if(event){
        event.preventDefault();
      }

    }

    genContactToSend(){
      var contact = {};

      contact.firstname = this.state.firstname.trim();
      contact.lastname = this.state.lastname.trim();
      contact.company = this.state.company;
      contact.country = this.state.country;
      contact.email = this.state.email;

      return contact;
    }

    onFormSubmitEditContact(event) {
      var groupIdsSelected = this.state.groupsSelected.map(group => {return group.id});
      var contact = this.genContactToSend();

      this.setState({isLoading : true, currentlySaving: true});
      this.props.textLoadingOverlay("Updating " + contact.firstname + " " + contact.lastname + "...");
      this.props.loadingOverlayCb(true);

      contact.title = this.state.title.length ? this.state.title : null;
      contact.phone = this.state.phone && this.state.phone.length ? new PhoneNumber(this.state.phone).getNumber() : null;
      contact.mobile = this.state.mobile && this.state.mobile.length ? new PhoneNumber(this.state.mobile).getNumber() : null;
      contact.departmentNumber = this.state.departmentNumber.length ? this.state.departmentNumber : null;
      contact.postalAddress = this.state.postalAddress.length ? this.state.postalAddress : null;
      contact.postalCode = this.state.postalCode.length ? this.state.postalCode : null;
      contact.city = this.state.city.length ? this.state.city : null;
      contact.employeeType = this.state.employeeType.length ? this.state.employeeType : null;
      contact.toolsAccess = this.state.toolsAccess.length ? this.state.toolsAccess : [];
      contact.groups = groupIdsSelected;

      var contactId = '';

      var dataToSend = {
        id : this.props.contactId,
        data : contact
      }

      this.props.onUpdateContact(dataToSend)
        .then((newContactId) => {
          contactId += newContactId;
        })
        .then(this.props.onFetchPeople)
        .then(this.props.onFetchGroups)
        .then(() => {
          toast(contact.firstname + " " + contact.lastname + " has been saved !", {type:"success"});
          unlockResource(dataToSend.id);
          this.props.history.push( pathname + 'contact/details/' + encodeURIComponent(contactId));
        })
        .catch(err => toast(err.response.data ? err.response.data : err.message, {type:"error",autoClose: false}))
        .finally(() => {
          this.setState({isLoading : false});
          this.props.loadingOverlayCb(false)
        });

      if(event){
        event.preventDefault();
      }
    }

   getGroupsSelected(groups){
    var groupsSelected = groups.filter( function (group) {
      return group.isSelected;
    });

    if(this.props.mode == "EDIT" && groupsSelected.lenth > 0){
      groupsSelected.forEach(group => {
        group.role = "";


         if(group.chairs.some(chair => chair.id === this.contactToEdit.id)){
           group.role = "Chair";
         }
         if(group.viceChairs.some(chair => chair.id === this.contactToEdit.id)){
           group.role = "Vice-chair";
         }
       });
    }
     return groupsSelected;
   }

   contactCompanyCanBeSubmit(contactCompanyIsValid){
     this.setState({
       contactCompanyIsValid: contactCompanyIsValid
     });
   }

   contactAddressCanBeSubmit(contactAddressIsValid){
     this.setState({
       contactAddressIsValid : contactAddressIsValid
     });
   }

   contactPersonCanBeSubmit(contactPersonIsValid){
     this.setState({
       contactPersonIsValid: contactPersonIsValid
     });
   }

   prepareGroupData(groups, mode, contactToEdit){
    var self = this;
    var groupsFormatted = groups.map(group => {

      if(mode == "DUPLICATE" || mode == "EDIT"){
        group.isSelected = contactToEdit.groups.filter(groupInContact => groupInContact.id == group.id).length > 0;
      }
      else {
        group.isSelected = false;
      }
       return group;
     });

     return groupsFormatted;
   }

   handleChange(event) {

     var evtValue = event.target.value.replace(/[\s]{2,}/g," ");

     this.setState({
         formIsUsed: true,
         firstname: event.target.name === 'firstname' ? evtValue : this.state.firstname,
         lastname: event.target.name === 'lastname' ? evtValue : this.state.lastname,
         title: event.target.name === 'title' ? evtValue : this.state.title,
         company: event.target.name === 'company' ? evtValue : this.state.company,
         country: event.target.name === 'country' ? evtValue : this.state.country,
         employeeType: event.target.name === 'employeeType' ? evtValue : this.state.employeeType,
         phone: event.target.name === 'phone' ? evtValue : this.state.phone,
         mobile: event.target.name === 'mobile' ? evtValue : this.state.mobile,
         departmentNumber: event.target.name === 'departmentNumber' ? evtValue : this.state.departmentNumber,
         postalAddress: event.target.name === 'postalAddress' ? evtValue : this.state.postalAddress,
         postalCode: event.target.name === 'postalCode' ? evtValue : this.state.postalCode,
         city: event.target.name === 'city' ? evtValue : this.state.city,
         email: event.target.name === 'email' ? evtValue.toLowerCase() : this.state.email
     });
     event.preventDefault();
   }

   modaleDelete(){
     this.setState({
       isOpen : !this.state.isOpen
     });
   }

  deleteContact(contact){

    this.setState({
      isOpen : !this.state.isOpen,
      isLoading: true, 
      currentlySaving: true
    });

    const data = {
      id: contact
    };

    this.props.textLoadingOverlay("Deleting " + this.state.firstname + " " + this.state.lastname + "...");
    this.props.loadingOverlayCb(true);

    const groupToRedirect = JSON.parse(sessionStorage.getItem('group-redirect'));
    var targetToRedirect = pathname + 'contact';
    if(groupToRedirect && groupToRedirect.contactId === contact){
      targetToRedirect = groupToRedirect.locationPathname;
    }

    this.props.onDeleteContact(data)
    .then(this.props.onFetchPeople)
    .then(this.props.onFetchGroups)
    .then(() => {
        toast(`${data.id} removed!`, { type:"success" });
        this.props.history.push(targetToRedirect);
    })
    .catch(err => toast(err.response.data ? err.response.data : err.message, {type:"error",autoClose: false}))
    .finally(() => {
      this.setState({isLoading : false});
      this.props.loadingOverlayCb(false)
    });
  }

   updateGroupsSelected(groups, groupSelected){
     groups.forEach((group)=> {
       if(group.id == groupSelected.id){
         group.isSelected = groupSelected.isSelected;
         group.role = "";
         if(this.props.mode !== "CREATE"){
           if(group.chairs.some(chair => chair.id === this.contactToEdit.id)){
             group.role = "Chair";
           }
           if(group.viceChairs.some(chair => chair.id === this.contactToEdit.id)){
             group.role = "Vice-chair";
           }
         }
       }
     });
     return groups;
   }

   updateOtherGroup(groups){
     return groups.filter( function (group) {
       return !group.isSelected;
     });
   }

   unselectGroupAction(group){
     group.isSelected = false;
      group.newGroupSelected = false;

      var groups = this.updateGroupsSelected(this.props.groups, group);
      var checkMemberStatus = this.checkMemberStatus(group.id, false, true);

     this.setState({
       formIsUsed :  true,
       otherGroups : this.updateOtherGroup(groups),
       groupsSelected : this.getGroupsSelected(groups),
       groups : groups,
       haveGroupsAdded: checkMemberStatus.groupsAdded,
       haveGroupsRemoved: checkMemberStatus.groupsRemoved
     });
   }

   selectGroupAction(group){

    group.isSelected = true;
    group.newGroupSelected = true;

    var groups = this.updateGroupsSelected(this.props.groups, group);

    var checkMemberStatus = this.checkMemberStatus(group.id, true, false);

    this.setState({
      formIsUsed : true,
      otherGroups : this.updateOtherGroup(groups),
      groupsSelected : this.getGroupsSelected(groups),
      haveGroupsAdded: checkMemberStatus.groupsAdded,
      haveGroupsRemoved: checkMemberStatus.groupsRemoved
    });

     setTimeout(() => {
       var elmnt = document.getElementById(group.id);
       elmnt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
     }, 10);
   }

   updateToolsAcces(accessOption){
     this.setState({
       formIsUsed: true,
       toolsAccess : accessOption
     });
   }

   cancel() {
     this.props.history.goBack();
   }

   checkMemberStatus(groupId, addedInGroup, removedInGroup){

     var groupsRemoved = false;

     var groupsAdded = false;

     if(this.props.mode === "EDIT"){

       var currentGroupsIds = this.contactToEdit.groups.map(group => {

         if(group.id){

           return group.id;

         } else {

           return group
         }

       });

       if(currentGroupsIds.indexOf(groupId) > -1){

         groupsRemoved = !addedInGroup || removedInGroup;
         groupsAdded = this.state.haveGroupsAdded;

       }

       if(currentGroupsIds.indexOf(groupId) <= -1){
         groupsRemoved = this.state.haveGroupsRemoved;
         groupsAdded = addedInGroup || !removedInGroup;
       }

     } else {
       groupsAdded = true;
     }

      return { groupsRemoved, groupsAdded }
    }

    displayBoxInfoModal(){
      this.setState({
        displayBoxInfoModal : !this.state.displayBoxInfoModal
      })

    }

   render(){
     if (!this.state.groups) {
       return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
     }

     var mode = this.props.mode;
     var groups = this.state.groups;
     var people = this.state.people;
     var isLoading = this.state.isLoading;
     var country = this.state.country;
     const { current_user } = this.state;
     var groupsSelected = this.state.groupsSelected;


    const formAction = () => {
      if(!this.state.isLoading){
        switch(this.props.mode) {
          case "CREATE":    return this.onFormSubmitCreateContact;
          case "EDIT":      return this.onFormSubmitEditContact;
          case "DUPLICATE": return this.onFormSubmitCreateContact;
          default:          return null;
        }
      }
    }

     var isAuthorizedToEdit = false;

     var staffGroupId = this.props.ldap.configuration.staffGroupId;

     if(mode === "EDIT"){
       isAuthorizedToEdit = this.state.current_user.perm === "ADMIN";

       if(this.state.current_user.perm !== "ADMIN"){
         isAuthorizedToEdit = this.contactToEdit.groups.filter(group => group.id === staffGroupId).length === 0;
       }

     } else {
       isAuthorizedToEdit = true;
     }

     var formCanBeSubmit = () => {

       var defaultCheck = this.state.formIsUsed && !this.state.isLoading && groupsSelected.length > 0;

       if(isAuthorizedToEdit){

         var contactCompanyIsValid = this.state.contactCompanyIsValid;
         var contactAddressIsValid = this.state.contactAddressIsValid;
         var contactPersonIsValid = this.state.contactPersonIsValid;
         var contactJobtitleIsValid = this.state.employeeType.length <= ContactValidationSchema.properties.employeeType.maxLength;

         defaultCheck = defaultCheck && contactPersonIsValid && contactCompanyIsValid && contactAddressIsValid && contactJobtitleIsValid;

       }

      return defaultCheck;

    };

    let boxModule = this.props.ldap.configuration.boxModule;

    return(
         <Container fluid={true}>

         <Form onSubmit={formAction()} className="h-100 m-0 overflowY-auto">
            <Header 
              mode={this.props.mode}
              contactToEdit={this.contactToEdit}
              formCanBeSubmit={formCanBeSubmit()}
              formIsUsed={this.state.formIsUsed}
              closeCb={this.cancel}
              defaultSaveAction={!this.state.haveGroupsRemoved && !this.state.haveGroupsAdded}
              saveAction={this.displayBoxInfoModal}
              staffGroupId={this.props.ldap.configuration.staffGroupId}
              modaleDelete={this.modaleDelete}
              current_user={current_user}
              isLoading={this.state.isLoading}
              itemsLocked={this.state.itemsLocked}
            />

            <div className="displayFlex editViewBody">
              {!isAuthorizedToEdit ? <div className="panelBoxSection w-40 h-150" style={{width: "40%", height: "150px"}}>
                <div className="panelBoxTitle">
                  <div className="panelBoxTitle-icon">
                    <FontAwesomeIcon icon={faUser}/>
                  </div>
                  <h2 className="mb-2-negatif" >Person</h2>
                  <small className="form-text text-muted colorRed mb-3 text-center"><FontAwesomeIcon icon={faExclamationTriangle}/> You are not authorized to edit personal data.<br/>You can only edit groups.</small>
                </div>
              </div> : <div className="col-xxl-5">
                <ContactEditPerson mode={this.props.mode}
                                   title={this.state.title}
                                   handleChange={this.handleChange}
                                   formIsUsed={this.state.formIsUsed}
                                   lastname={this.state.lastname}
                                   firstname={this.state.firstname}
                                   phone={this.state.phone}
                                   mobile={this.state.mobile}
                                   email={this.state.email}
                                   isLoading={isLoading}
                                   boxCanBeSubmit={this.contactPersonCanBeSubmit}
                                   contactToEdit={this.contactToEdit}/>

                <ContactEditCompany employeeType={this.state.employeeType}
                                    boxCanBeSubmit={this.contactCompanyCanBeSubmit}
                                    handleChange={this.handleChange}
                                    companies={this.state.companies}
                                    formIsUsed={this.state.formIsUsed}
                                    mode={mode}
                                    company={this.state.company}/>

                <ContactEditToolsAccess toolsAccess={this.state.toolsAccess}
                                        updateToolsAcces={this.updateToolsAcces}/>

                <ContactEditAddress handleChange={this.handleChange}
                                    departmentNumber={this.state.departmentNumber}
                                    postalCode={this.state.postalCode}
                                    city={this.state.city}
                                    mode={mode}
                                    country={this.state.country}
                                    postalAddress={this.state.postalAddress ? this.state.postalAddress.replace(/\$/g, '\n') : ""}
                                    boxCanBeSubmit={this.contactAddressCanBeSubmit}
                                    formIsUsed={this.state.formIsUsed}/>

              </div>}

              <div  className="col-xxl-7 h-100">
                <ContactMembershipWidget groupsSelected={this.state.groupsSelected}
                                         otherGroups={this.state.otherGroups}
                                         selectGroupAction={this.selectGroupAction}
                                         modeView={mode}
                                         unselectGroupAction={this.unselectGroupAction}/>

              </div>
            </div>
          </Form>

          <BoxModalComponent 
            isOpen={this.state.displayBoxInfoModal}
            boxModule={boxModule}
            successCb={mode === "EDIT" ? this.onFormSubmitEditContact : this.onFormSubmitCreateContact}
            isLoading={this.state.isLoading}
          />

          <ModalContactDelete isOpen={this.state.isOpen}
                              contact={this.contactToEdit}
                              toggle={this.modaleDelete}
                              boxModule={boxModule}
                              staffGroupId={staffGroupId}
                              successCb={!this.state.isLoading ? this.deleteContact : null} />
         </Container>
     );
   };
};

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onCreateContact: (data) => dispatch(createContact(data)),
  onUpdateContact: (data) => dispatch(updateContact(data)),
  onDeleteContact: (data) => dispatch(deleteContact(data)),
  onFetchPeople: () => dispatch(fetchPeople()),
  onFetchGroups: () => dispatch(fetchGroups()),
  onFetchCompanies: () => dispatch(fetchCompanies())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ContactEditView);
