import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom';
import { Row, Col, Spinner } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ListView from '../ListView/ListView';
import { prepareGroups } from '../../utils';
import { getLoggedUser } from '../../utils';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class GroupListView extends React.Component{

    constructor(props){
      super(props);
      this.state = {
        modeView : props.modeView,
        groups: props.ldap.groups,
        current_user: getLoggedUser()
      };
    }

    componentDidUpdate(prevProps) {
      if (this.props.modeView !== prevProps.modeView) {
          this.setState({
            modeView : this.props.modeView
          });
      }
    }

    componentDidMount() {
      const { groups } = this.state;
      this.setState({groups: prepareGroups(groups)})
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      let { groups } = nextProps.ldap;
      const { modeView } = nextProps;
      if (groups !== prevState.groups || modeView !== prevState.modeView) {

        return {
            groups: prepareGroups(groups),
            modeView
          }
      }
      return null;
    }

    render() {

      if (this.state.groups.length == 0) {
        return <div className="loadingPage"><Spinner color="light" /> </div>;
      }

      const { modeView } = this.state;
      const cols = [
          {'key':'name', 'type':'text', 'title': 'Group name', 'displayedInCompact' : true},
          {'key':'company', 'type':'text', 'title': 'Companies'},
          {'key':'email', 'type':'mailto', 'title':'Group mail'},
          {'key':'efpiaContact', 'type':'fullname','title':'EFPIA contact'},
          {'key':'type', 'type':'text', 'title': 'Group type'},
          {'key':'chairs', 'type':'fullname', 'title':'Chair'},
          {'key':'createdDate', 'type':'date', 'title': 'Creation date', 'format' : "LLL" },
          {'key':'modifiedDate', 'type':'date', 'title': 'Last modification date', 'format' : "LLL" },
          {'key':'memberCount', 'type':'text', 'title':'Member count'},
          {'key':'editors', 'type':'fullname', 'title':"Authorized editors", staffId: this.props.ldap.configuration.staffGroupId},
          {'key':'gid', 'type':'text', 'title':"Group ID"}
      ];
      const defaultCols = ['name', 'efpiaContact', 'email', 'type'];

      const listOptions = {rowClickHandlerKey:"detailLinkCb"};

      var current_user = this.state.current_user;
      var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

      return(
          <div className="container-fluid h-100 pt-3">
          <Row>
            <Col className="pannelTitleList">
              <Link to={pathname + "group"} className="text-decoration-none"><h1>Groups</h1></Link>
              {isAllowedToEdit ? <Link to={pathname + 'new-group'}>
                    <Button color='primary' className="addBtn"> <FontAwesomeIcon icon={faPlus}/></Button>
              </Link> : null}
            </Col>
            </Row>
              <ListView cols={cols}
                        rows={this.state.groups}
                        rowSelectedId={this.props.requestedGroupId}
                        defaultCols={defaultCols}
                        listOptions={listOptions}
                        modeView={modeView}
                        sortKey='name'
                        targetLink={pathname + "group"}
                        advancedSearchType="GROUP"
                        isClipboard={true}/>
          </div>
      );
    }
};
const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GroupListView);
