import React from 'react';
import Moment from 'moment';

const TIME_PASSED = 0;
const SEC_GRACEFUL_SESSION = 2;

class Timer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countdown : null,
      circleStrokeDasharray : 100
    }
  }

  componentDidMount() {
    const expiratedTimestamp = this.props.expiratedTimestamp;
    const TIME_LIMIT = this.props.secBeforeModalWarningExpiredSession;

    this.timer = setInterval(() => {
      const nowTimestamp = Moment().unix();

      const remainingTime  = expiratedTimestamp - nowTimestamp;
      const remainingTimeFormat = this.secondsToHms(remainingTime - SEC_GRACEFUL_SESSION);

      if(remainingTimeFormat.m === 0 && remainingTimeFormat.s <= TIME_LIMIT){

        if(remainingTimeFormat.s >= TIME_PASSED){
          this.setState({
              countdown : remainingTimeFormat,
              circleStrokeDasharray : Math.round((remainingTimeFormat.s*100)/TIME_LIMIT)
          });
        }
      }

     if(remainingTimeFormat.s < TIME_PASSED){
        this.props.disconnect();
        clearInterval(this.timer);
      }
    }, 1000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  secondsToHms(d){
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    return {h: h, m : m, s : s};
  }

  render() {

    return (
      <div className="timer-container">
      <svg viewBox="0 0 36 36" class="circular-chart">
        <path class="circle"
          stroke-dasharray={this.state.circleStrokeDasharray + ", 100"}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <p className="timer-number">{this.state.countdown ? this.state.countdown.m + " : " + this.state.countdown.s : null}</p>

      </div>
    );
  }
}

export default Timer;
