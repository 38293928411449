export default function directoryEditors(state = {
  directoryEditors: {},
  fetching: false,
  fetched: false,
  error: null,
}, action) {

  switch (action.type) {
    case "FETCH_DIRECTORY_EDITORS": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "FETCH_DIRECTORY_EDITORS_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "FETCH_DIRECTORY_EDITORS_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
        directoryEditors: action.payload
      })
    }

    case "UPDATE_DIRECTORY_EDITORS": {
      return Object.assign({}, state, {
        fetching: true
      })
    }
    case "UPDATE_DIRECTORY_EDITORS_REJECTED": {
      return Object.assign({}, state, {
        fetching: false,
        error: action.payload
      })
    }
    case "UPDATE_DIRECTORY_EDITORS_FULFILLED": {
      return Object.assign({}, state, {
        fetching: false,
        fetched: true,
      })
    }

    default:
      return state;
  }
}
