import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import PhoneNumber  from "awesome-phonenumber";
import { matchFullnameInvalidChars, validateEmail } from "../../../SubComponent/fieldsValidators";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEdit,
  faLock,
  faLockOpen,
  faInfoCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  FormText
} from 'reactstrap';

import ModalContactEdit from './ModalComponents/ModalContactEdit';
import ContactValidationSchema from '../../../../../server/jsonSchema/personCreate.schema.json';

class ContactEditPerson extends React.Component{
  constructor(props){
    super(props);
    this.handlePersonChange = this.handlePersonChange.bind(this);
    this.displayEditEmail = this.displayEditEmail.bind(this);
    this.displayEditFullname = this.displayEditFullname.bind(this);

    this.state = {
      editFullnameIsOpen : false,
      editEmailIsOpen: false,
      title: "",
      email: "",
      lastname: "",
      firstname: "",
      phone: "",
      mobile: ""
    }

  }
  componentDidMount(){
    if(this.props.mode === "EDIT"){
      this.props.boxCanBeSubmit(true);
      const {
        mode,
        title,
        email,
        lastname,
        firstname,
        phone,
        mobile
      } = this.props;

      this.setState({
        title: title,
        email: email,
        lastname: lastname,
        firstname: firstname,
        phone: phone,
        mobile: mobile
      })
    }
  }
  componentDidUpdate(prevProps, prevState){

    if(prevProps.mode !== this.props.mode){
      var boxCanBeSubmit = this.props.mode === "EDIT";
      this.props.boxCanBeSubmit(boxCanBeSubmit);
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      mode,
      title,
      email,
      lastname,
      firstname,
      phone,
      mobile
    } = nextProps;

    if(mode && mode !== prevState.mode){
      return {
        mode,
        title,
        email,
        lastname,
        firstname,
        phone,
        mobile
      }
    }

    return null;
  }

  handlePersonChange(event){
    this.props.handleChange(event);

    var titleValue = event.target.name === "title" ? event.target.value : this.state.title;
    var firstnameValue = event.target.name === "firstname" ? event.target.value : this.state.firstname;
    var lastnameValue = event.target.name === "lastname" ? event.target.value : this.state.lastname;
    var emailValue = event.target.name === "email" ? event.target.value.trim().toLowerCase() : this.state.email;
    var phoneValue = event.target.name === "phone" ? event.target.value : this.state.phone;
    var mobileValue = event.target.name === "mobile" ? event.target.value : this.state.mobile;

    this.setState({
      title: titleValue,
      email: emailValue,
      lastname: lastnameValue,
      firstname: firstnameValue,
      phone: phoneValue,
      mobile: mobileValue
    });

    var titleValueIsValid = titleValue ? titleValue.length !== 0 : true;
    var firstnameIsValid = firstnameValue.length > 0 && !matchFullnameInvalidChars(firstnameValue) && firstnameValue.length <= ContactValidationSchema.properties.firstname.maxLength;
    var lastnameIsValid = lastnameValue.length > 0 && !matchFullnameInvalidChars(lastnameValue) && lastnameValue.length <= ContactValidationSchema.properties.lastname.maxLength;
    var fullnameIsValid = firstnameIsValid && lastnameIsValid && !(this.isFullnameInvalid(firstnameValue, lastnameValue, emailValue, this.state.editFullnameIsOpen));
    var emailIsValid = !this.isEmailInvalid(emailValue);
    var phoneIsValid = phoneValue ? phoneValue.length === 0 || phoneValue.length <= ContactValidationSchema.properties.phone.maxLength : true;
    var mobileIsValid = mobileValue ? mobileValue.length === 0 || mobileValue.length <= ContactValidationSchema.properties.mobile.maxLength : true;

    var contactPersonIsValid = titleValueIsValid && fullnameIsValid && emailIsValid && phoneIsValid && mobileIsValid;

    this.props.boxCanBeSubmit(contactPersonIsValid);

  }

  displayEditEmail(){
    this.setState({
      editEmailIsOpen : !this.state.editEmailIsOpen
    });
  }

  displayEditFullname(){
    this.setState({
      editFullnameIsOpen: !this.state.editFullnameIsOpen
    })
  }

  userAlreadyExist(firstname, lastname, email){
    var mode = this.props.mode;
    var contactToEdit = this.props.contactToEdit;
    var userAlreadyExist = false;
    var people = this.props.ldap.people;
    email = email ? email.toLowerCase() : '';

    if(mode === "EDIT"){
      var lastnameIsChanged = contactToEdit.lastname.toLowerCase().trim() !== lastname.toLowerCase().trim();
      var firstnameIsChanged = contactToEdit.firstname.toLowerCase().trim() !== firstname.toLowerCase().trim();
      var emailIsChanged = contactToEdit.email.toLowerCase() !== email;

      if(lastnameIsChanged || firstnameIsChanged || emailIsChanged){
        var personExistInDb = people.filter(function(person){
          return person.lastname.toLowerCase().trim() === lastname.toLowerCase().trim()
                    && person.firstname.toLowerCase().trim() === firstname.toLowerCase().trim()
                    && person.email.toLowerCase().trim() === email;
        });

        userAlreadyExist = personExistInDb.length > 0;
      }
    }
    else {
      lastname = lastname ? lastname.toLowerCase().replace(/\s/g, '') : '';
      firstname = firstname ? firstname.toLowerCase().replace(/\s/g, '') : '';
      email = email ? email.toLowerCase() : '';

      var personExistInDb = people.filter(function(person){
        return person.lastname.toLowerCase().replace(/\s/g, '') === lastname
                  && person.firstname.toLowerCase().replace(/\s/g, '') === firstname
                  && person.email.toLowerCase() === email;
      });

      userAlreadyExist = personExistInDb.length > 0;
    }

    return userAlreadyExist;
  }

  isFullnameInvalid(firstname, lastname, email, editFullnameIsOpen){

     var checkUserAlreadyExist = this.userAlreadyExist(firstname, lastname, email);

     switch (this.props.mode) {

       case "EDIT": return checkUserAlreadyExist && editFullnameIsOpen ;

         break;
       default: return checkUserAlreadyExist;

     }
  };

  isEmailInvalid(email){

    var emailIsValid = validateEmail(email);
    var emailCanBeUse = this.emailCanBeUse(email);
    var defaultValidatorEmail = !emailIsValid || !emailCanBeUse || email.length < ContactValidationSchema.properties.email.minLength || email.length > ContactValidationSchema.properties.email.maxLength;
    switch (this.props.mode) {
      case "EDIT": return defaultValidatorEmail && this.state.editEmailIsOpen;

        break;
      default:     return defaultValidatorEmail;
    }
  }

  emailCanBeUse(email){
    var peopleMatchEmail = this.props.ldap.people.filter(person => person.email.toLowerCase() === email.toLowerCase());
    var emailCanBeUse = false;

    if(this.props.mode === "EDIT" && this.props.contactToEdit.email.toLowerCase() === email.toLowerCase()){
       emailCanBeUse = this.props.contactToEdit.email.toLowerCase() === email.toLowerCase();
    } else {
      emailCanBeUse = peopleMatchEmail.length == 0;
    }

    return emailCanBeUse;

  }

  isNamesakeContact(firstname, lastname){
    var isNamesakeContact = false;
    firstname = firstname ? firstname : '';
    lastname = lastname ? lastname : '';

    var mode = this.props.mode;
    var contactToEdit = this.props.contactToEdit;
    var contactIsChange = contactToEdit && contactToEdit.lastname.toLowerCase() !== lastname.toLowerCase()
                              && contactToEdit.firstname.toLowerCase() !== firstname.toLowerCase();

    var contactExistInDb = this.props.ldap.people.filter(contact => contact.lastname.toLowerCase().trim() === lastname.toLowerCase().trim() && contact.firstname.toLowerCase().trim() === firstname.toLowerCase().trim()).length ;

    if(mode === "EDIT" && !contactIsChange){
      isNamesakeContact = contactExistInDb > 1;
    } else {
      isNamesakeContact = contactExistInDb > 0;
    }

    return isNamesakeContact;
  }

  render(){

    const {
      title,
      email,
      lastname,
      firstname,
      phone,
      mobile
    } = this.state;

    const {
      contactToEdit,
      mode,
      isLoading,
      formIsUsed
    } = this.props;


    var editFullnameIsOpen = this.state.editFullnameIsOpen;
    var editEmailIsOpen = this.state.editEmailIsOpen;

    var isFullnameInvalid = this.isFullnameInvalid(firstname, lastname, email, editFullnameIsOpen);

    var emailIsValid = validateEmail(email);
    var emailCanBeUse = this.emailCanBeUse(email);
    var isEmailInvalid = this.isEmailInvalid(email);

    var phoneNumber = new PhoneNumber(phone ? phone : '' );
    var mobileNumber = new PhoneNumber(mobile ? mobile : '' );

    var firstnameHaveInvalidChars = matchFullnameInvalidChars(firstname) !== null && matchFullnameInvalidChars(firstname).length > 0;
    var lastnameHaveInvalidChars = matchFullnameInvalidChars(lastname) !== null && matchFullnameInvalidChars(lastname).length > 0;

    var isNamesakeContact = this.isNamesakeContact(firstname, lastname);

    return (
      <div className="panelBoxSection" >
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faUser}/>
          </div>
          <h2>Person</h2>
        </div>
        <div className="panelBoxBody">
          <Row>
            <Col xs={3}>
              <FormGroup>
                <Label>Salutation</Label>
                <Input autoComplete="noautoComplete"
                       type="select"
                       name="title"
                       value={title}
                       onChange={this.handlePersonChange}>
                  <option value="">Select...</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Pr.">Pr.</option>
                </Input>
              </FormGroup>
            </Col>

            <Col xs={9}>
              <FormGroup>
                <Label for="firstname" className="required">Firstname</Label>
                <InputGroup>
                  <Input className={mode == 'EDIT' ? "inputLocked" : null }
                         autoComplete="off"
                         type="text"
                         name="firstname"
                         value={firstname}
                         onChange={this.handlePersonChange}
                         invalid={!isLoading && ( isFullnameInvalid || firstnameHaveInvalidChars || firstname.length > ContactValidationSchema.properties.firstname.maxLength )}
                         required
                         disabled = {mode == 'EDIT' ? !editFullnameIsOpen : null}/>
                  {mode == 'EDIT'? <InputGroupAddon addonType="append">
                    <InputGroupText className="inputModal">
                      <ModalContactEdit buttonLabel={<FontAwesomeIcon icon={!editFullnameIsOpen ? faLock : faLockOpen}/>}
                                      titleBody="Modifying someone's Firstname"
                                      text="is only allowed when correcting a typo or when the contact changes his/her name (marriage, divorce)."
                                      duplicateLinkCb={contactToEdit.duplicateLinkCb}
                                      action1={this.displayEditFullname}
                                      btnTextAction1="Edit fullname"
                                      />
                    </InputGroupText>
                  </InputGroupAddon> : null}
                  <FormFeedback>
                    {firstname.length > ContactValidationSchema.properties.firstname.maxLength ? "Firstname is too long. Max " + ContactValidationSchema.properties.firstname.maxLength + " allowed. " : null}
                    {firstnameHaveInvalidChars ? "Characters not allowed " + matchFullnameInvalidChars(firstname).join(" ") : null}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="lastname" className="required">Family name</Label>
                <InputGroup>
                  <Input className={mode == 'EDIT' ? "inputLocked" : null }
                         autoComplete="noautoComplete"
                         type="text"
                         name="lastname"
                         value={lastname}
                         onChange={this.handlePersonChange}
                         required
                         invalid={!isLoading && ( isFullnameInvalid || lastnameHaveInvalidChars || lastname.length > ContactValidationSchema.properties.lastname.maxLength )}
                         disabled = {mode == 'EDIT' ? !editFullnameIsOpen : null}/>
                    {mode == 'EDIT'? <InputGroupAddon addonType="append">
                      <InputGroupText className="inputModal">
                        <ModalContactEdit buttonLabel={<FontAwesomeIcon icon={!editFullnameIsOpen ? faLock : faLockOpen}/>}
                                        titleBody="Modifying someone's Family name"
                                        text="is only allowed when correcting a typo or when the contact changes his/her name (marriage, divorce)."
                                        duplicateLinkCb={contactToEdit.duplicateLinkCb}
                                        action1={this.displayEditFullname}
                                        btnTextAction1="Edit fullname"/>
                    </InputGroupText>
                  </InputGroupAddon> : null}
                  <FormFeedback tooltip={isFullnameInvalid}>
                    {lastname.length > ContactValidationSchema.properties.lastname.maxLength ? "Family name is too long. Max " + ContactValidationSchema.properties.lastname.maxLength + " allowed. " : null}
                    {lastnameHaveInvalidChars ? "Characters not allowed " + matchFullnameInvalidChars(lastname).join(" ") : null}
                    {isFullnameInvalid ? firstname + " "  + lastname + " already exist!" : null}
                  </FormFeedback>
                </InputGroup>
                {isNamesakeContact ? <FormText color="muted"><FontAwesomeIcon icon={faExclamationCircle}/> {firstname + " "  + lastname} has a namesake contact in LPO</FormText> : null}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label className="required">Email</Label>
                <InputGroup>
                  <Input className={mode == 'EDIT' ? "inputLocked" : "" }
                         autoComplete="noautoComplete"
                         type="text"
                         name="email"
                         value={email}
                         placeholder="person@company.com"
                         onChange={this.handlePersonChange}
                         required
                         valid={formIsUsed && !isEmailInvalid && email.length > 0}
                         invalid={!isLoading && isEmailInvalid}
                         disabled={mode == 'EDIT' ? !editEmailIsOpen : null}/>
                         <FormFeedback tooltip>
                            {!emailCanBeUse ? email + " is already taken. " : null}
                            {!emailIsValid ? "Email is not valid. " : null}
                            {email.length > ContactValidationSchema.properties.email.maxLength ? "Email is too long." : null}
                         </FormFeedback>
                  {mode == 'EDIT'? <InputGroupAddon addonType="append">
                    <InputGroupText className="inputModal">
                      <ModalContactEdit buttonLabel={<FontAwesomeIcon icon={!editEmailIsOpen ? faLock : faLockOpen}/>}
                                      titleBody="Modifying someone's email"
                                      text="is only allowed when correcting a typo or when the contact really change email."
                                      duplicateLinkCb={contactToEdit.duplicateLinkCb}
                                      action1={this.displayEditEmail}
                                      btnTextAction1="Edit email"
                                      />
                    </InputGroupText>
                  </InputGroupAddon> : null}

                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <Input
                  autoComplete="noautoComplete"
                  type="tel"
                  name="phone"
                  placeholder="+32 11 22 33 44"
                  value={phoneNumber.isValid() ?  phoneNumber.getNumber( 'international' ) : phoneNumber.getNumber('input')}
                  onChange={this.handlePersonChange}
                  valid={phoneNumber.isValid()}
                  invalid={phoneNumber.getNumber('input') ? !phoneNumber.isValid() : false}/>
                  <FormText><FontAwesomeIcon icon={faInfoCircle}/> <strong>Prefix</strong> required</FormText>
                  <FormFeedback style={{top: "70"}}>Phone number invalid, please check if prefix is present!<br/>ex: +32 11 22 33 44</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                  <Label for="mobile">Mobile</Label>
                  <Input
                    autoComplete="noautoComplete"
                    type="tel" name="mobile"
                    placeholder="+32 11 22 33 44"
                    value={mobileNumber.isValid() ?  mobileNumber.getNumber( 'international' ) : mobileNumber.getNumber('input')}
                    onChange={this.handlePersonChange}
                    valid={mobileNumber.isValid()}
                    invalid={mobileNumber.getNumber('input') ? !mobileNumber.isValid() : false}/>
                    <FormText><FontAwesomeIcon icon={faInfoCircle}/> <strong>Prefix</strong> required</FormText>
                    <FormFeedback style={{top: "70"}}>Mobile number invalid, please check if prefix is present!<br/>ex: +32 11 22 33 44</FormFeedback>
                </FormGroup>
            </Col>
          </Row>

        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  ldap: state.ldap
});

export default compose( connect(mapStateToProps) )(ContactEditPerson);
