import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux"
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';

import GroupListView from '../GroupListView/GroupListView';
import GroupDetailView from '../GroupDetailView/GroupDetailView';
import GroupEditView from '../GroupEditView/GroupEditView';
import PanedViewport from '../PanedViewport/PanedViewport';
import { getLoggedUser } from '../../utils';

import {
  updateGroup
} from '../ODD/action'

import { prepareDataFromApi } from '../../utils';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class GroupView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      people: props.people,
      groups: props.groups,
      peopleById: [],
      groupsById: [],
      user: props.user,
      loadingOverlayCb : props.loadingOverlayCb,
      textLoadingOverlay : props.textLoadingOverlay,
      current_user: getLoggedUser()
    }
  }

  componentDidMount() {
    const { ldap } = this.props
    if (ldap.groups.length && ldap.people.length) {
      this.setState(prepareDataFromApi({people: ldap.people, groups: ldap.groups}))
    }
  }

  // update state on reload
  static getDerivedStateFromProps(nextProps, prevState) {
    const { people, groups } = nextProps.ldap;
    if (groups && (groups.length !== prevState.groupsById.length || people.length !== prevState.peopleById.length)) {
      return prepareDataFromApi({people: people, groups: groups})
    }
    return null;
  }

  render() {
    const { groups, groupsById, peopleById  } = this.state;
    const { location  } = this.props;
    if (groupsById.length === 0 || peopleById.length === 0){
      return "Loading";
    }
    var editMatch = matchPath(location.pathname, {
      "path": pathname + "group/edit/:id",
      "exact": true,
      "strict": true
    });
    var detailsMatch = matchPath(location.pathname, {
      "path": pathname + "group/details/:id",
      "exact": true,
      "strict": true
    });

    var current_user = this.state.current_user;
    var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";


    var isEdit = (editMatch !== null && isAllowedToEdit);
    var isDetails = (detailsMatch !== null);
    var modeView = isDetails || isEdit ? 'compacted' : 'extended';

    var group = {};
    var requestedGroupId = "";

    if(isDetails){
      requestedGroupId = decodeURIComponent(detailsMatch.params.id);
    }
    if(isEdit){
      requestedGroupId = decodeURIComponent(editMatch.params.id);
    }

    var children = [<GroupListView key="list"
                                   requestedGroupId={requestedGroupId}
                                   modeView={modeView}/>];

    if (isEdit) {
      children.push(<GroupEditView key={'edit-' + editMatch.params.id}
                                   groupId={requestedGroupId}
                                   mode="EDIT"
                                   loadingOverlayCb={this.state.loadingOverlayCb}
                                   textLoadingOverlay={this.state.textLoadingOverlay}  />);
    }
    else if (isDetails) {
      children.push(<GroupDetailView key={'details-' + detailsMatch.params.id}
                                     groupId={requestedGroupId} />);
    }
    return (
      <PanedViewport>
        {children}
      </PanedViewport>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onUpdateGroup: (data) => dispatch(updateGroup(data))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupView);
