import React from 'react';

import { compose } from 'redux';
import { connect } from "react-redux"
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

import { Row, Col } from 'reactstrap';
import List from '../List/ListFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ContactAddressCard from '../SubComponent/ContactAddressCard';
import ContactGroups from '../SubComponent/ContactGroups';
import ContactTitleHeader from '../SubComponent/ContactTitleHeader';
import CloseComponent from '../SubComponent/CloseComponent';
import AssignedGroupView from '../SubComponent/AssignedGroupView';


class GroupMembersView extends React.Component {
  constructor(props){
    super(props);

    this.closeContactPanel = this.closeContactPanel.bind(this);
    this.navigation = this.navigation.bind(this);
    this.prepareContact = this.prepareContact.bind(this);

    this.state = {
      members : this.props.isLoading ? [] : this.prepareMembers(props.members),
      contactToDisplay : null,
      initRowSelectedNavigation : false,

    }

  }

  componentDidMount(){
    const groupToRedirect = JSON.parse(sessionStorage.getItem('group-redirect'));

    if(!this.props.isLoading && groupToRedirect){

      var people = this.props.ldap.people;
      var members = this.props.ldap.groups.filter(group => group.id === groupToRedirect.id)[0].members;

      var displayContactIsMembers = groupToRedirect && members.filter(contact => contact.id === groupToRedirect.contactId).length;

      if(people.length && this.props.groupId === groupToRedirect.id && displayContactIsMembers){
        this.setState({
          contactToDisplay : this.prepareContact(people, groupToRedirect.contactId)
        })
        sessionStorage.removeItem('group-redirect');
      }
      if(this.props.groupId !== groupToRedirect.id || !displayContactIsMembers){
        sessionStorage.removeItem('group-redirect');
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { members, hiddeCompanyCol } = nextProps;

    if(members && (members !== prevState.members || members.length !== prevState.members.length)){
      var state = { members : members };
      if(hiddeCompanyCol){
        state.contactToDisplay = null
      }
      return state;
    }
    return null;
  }

  prepareMembers(members){
    var self = this;
    members.forEach(contact => {
      contact.contactToDisplayCb = function(){
        self.setState({
          contactToDisplay : contact
        });
        if(self.props.getContactToDisplay){
          self.props.getContactToDisplay(contact);
        }
      }
    });
    return members;
  }

  prepareContact(people, contactToDisplay){
    var contactToDisplay = contactToDisplay.id ? contactToDisplay.id : contactToDisplay;
    return people.filter(contact => contact.id == contactToDisplay)[0];

  }

  prepareCols(members, contactToDisplay){
    var cols = [
      {type : 'fullname', key: 'fullname', title:'Contact name'},
      {type : 'link', key: 'company', title:'Company', target: pathname + "company/details/"},
      {type : 'tel', key: 'mobile', title:'Mobile'},
      {type : 'mailto', key: 'email', title:'Email'},
      {type : 'toolsAccess', key: 'toolsAccess', title:'KMS access', tool: "KMS"}];

    var roles = members && members.length > 0 ? members.filter(contact => contact.role) : [];

    if(roles.length > 0){
      cols = [
        {type : 'fullname', key: 'fullname', title:'Contact name'},
        {type : 'badge', key : 'role'},
        {type : 'link', key: 'company', title:'Company', target: pathname + "company/details/"},
        {type : 'tel', key: 'mobile', title:'Mobile'},
        {type : 'mailto', key: 'email', title:'Email'},
        {type : 'toolsAccess', key: 'toolsAccess', title:'KMS access', tool: "KMS"}];
    }
    if(this.props.hiddeCompanyCol){
      cols = [
        {type : 'fullname', key: 'fullname', title:'Contact name'},
        {type : 'text', key: 'groupsName', title:'Member of'},
        {type : 'tel', key: 'mobile', title:'Mobile'},
        {type : 'mailto', key: 'email', title:'Email'},
        {type : 'toolsAccess', key: 'toolsAccess', title:'KMS access', tool: "KMS"}];
    }

    if(this.props.displayAssignedGroup){
      cols = [
        {'key':'fullname', 'type':'fullname', 'title': 'Contact name'},
        {'key':'assignedGroup', 'type':'text', 'title': 'Assigned group'},
        {'key':'assignedGroup', 'type':'count', 'title':'Number of groups assigned'}
      ];
    }

    if(contactToDisplay){
      cols = [{type : 'fullname', key: 'fullname', title:'Contact name'}];
    }
    return cols;
  }

  closeContactPanel(){
    this.setState({
      contactToDisplay : "",
      initRowSelectedNavigation : true
    });

    if(this.props.getContactToDisplay){
      this.props.getContactToDisplay(null);
    }
  }

  navigation(contactToDisplay){
    this.setState({
      contactToDisplay : contactToDisplay
    });
    if(this.props.getContactToDisplay){
      this.props.getContactToDisplay(contactToDisplay);
    }
  }

  render(){
    var isLoading = this.props.isLoading;

    var members = !isLoading ? this.prepareMembers(this.state.members) : [];

    var contactToDisplay = this.state.contactToDisplay ? this.state.contactToDisplay : '';

    var rowSelectedId = contactToDisplay ? contactToDisplay.id : null;

    var listOptions = {rowClickHandlerKey:"contactToDisplayCb"};

    var cols = this.prepareCols(this.state.members, contactToDisplay);

    return(
      <div className="panelBoxSection" style={this.props.style ? this.props.style : {marginTop: "2.5rem", height: "calc(100% - 300px)"}}>
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faUser}/>
          </div>
          <h2>Members</h2>
        </div>
        <div className="panelBoxBody row groupMembers" style={{ height: "calc(100% - 60px)"}}>
          <Col xs={contactToDisplay ? 2 : 12}
               className={contactToDisplay ? "listColContactIsDisplay h-100" : "h-100"}>
            <List 
              cols={cols}
              rows={!isLoading ? this.state.members : []}
              rowSelectedId={rowSelectedId}
              options={listOptions}
              sortKey='fullname'
              rowSelectedNavigationCb={this.navigation}
              initRowSelectedNavigation={!contactToDisplay}
              selectedRow={contactToDisplay}
              incentiveNavigation
              isLoading={isLoading}
            />
          </Col>

          {contactToDisplay
            ? <Col xs={10} className="w-100 h-100 m-0 pl-0">
                <Row className="h-100 w-100 contactToDisplayPanel m-0">
                  <Col xs={11}>
                    <ContactTitleHeader tag="a"
                                        className="contactTitleHeader-groupMembers"
                                        contact={contactToDisplay}
                                        groupId={this.props.groupId}
                                        locationPathname={this.props.locationPathname} />
                  </Col>
                  <Col xs={1}>
                    <CloseComponent className="closeComponent-groupMembers" closeCb={this.closeContactPanel} />
                  </Col>
                  {this.props.displayAssignedGroup
                    ? <Col xs={12} style={{height : "calc(100% - 110px)"}}>
                        <AssignedGroupView 
                          groups={contactToDisplay.assignedGroup}
                          contactId={contactToDisplay.id}
                          destinationContacts={this.state.members.map(contact => contact.id)}
                          staffGroup={members}
                          saveAction={this.props.saveAction}
                          groupsChanged={this.props.groupsChanged}
                          isLoading={this.props.isLoading}
                        />
                      </Col>
                    : <>
                        <Col xs={6} style={{height : "calc(100% - 110px)"}}>
                          <ContactAddressCard className="contactAddressCard-groupMembers" contact={contactToDisplay}/>
                        </Col>
                        <Col xs={6} style={{height : "calc(100% - 110px)"}}>
                          <ContactGroups className="contactGroup-groupMembers"  contactId={contactToDisplay.id} groups={contactToDisplay.groups}/>
                        </Col>
                      </>
                  }
                </Row>
              </Col>
            : null
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GroupMembersView);
