import React from 'react';
import { Table } from 'reactstrap';

const GlossaryComponent = (props) => {

  return (
    <div style={{background: "rgba(190, 192, 194, 0.25)" , padding: "1rem", marginTop: "1rem"}}>
      <h4>Glossary of recommended abbreviations</h4>
      <Table>
        <tbody>
          <tr>
            <td>TF</td>
            <td>Task Force</td>
          </tr>
          <tr>
            <td>CC</td>
            <td>Copy to</td>
          </tr>
          <tr>
            <td>WG</td>
            <td>Working Group</td>
          </tr>
          <tr>
            <td>EG</td>
            <td>Expert Group</td>
          </tr>
          <tr>
            <td>CTE</td>
            <td>Committee</td>
          </tr>
          <tr>
            <td>TT</td>
            <td>Think Tank</td>
          </tr>
          <tr>
            <td>FM</td>
            <td>Forum</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default GlossaryComponent;
