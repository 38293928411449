import React from 'react';
import {
  ModalFooter,
  Button
} from 'reactstrap';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { generateHtmlClipboard } from '../../../ClipboardGenerator';

class DeleteOrphanButtonComponents extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {
    var cols = [
      {type : 'fullname', key: 'fullname', title:'Contact name'},
      {type : 'text', key: 'company', title:'Company'},
      {type : 'mailto', key: 'email', title:'Email'}];

    var dataToExport = '<h2>Contacts only in ' + this.props.groupName + ' group. </h2>'+ generateHtmlClipboard(this.props.orphanContacts , cols, "email");

    return (
      <ModalFooter style={{alignItems: "initial"}}>
        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}
        <CopyToClipboard text={dataToExport}
                         onCopy={() => { toast("The data have been copied! You can now paste them into the file of your choice.", {type : "success"}) }}
                         options={{format: "text/html"}}>
            <Button block color="secondary" style={{margin: "0", padding: "0.375rem 0.5rem"}}>Copy orphan contact list<br/>to the clipboard</Button>
        </CopyToClipboard>

        <Button color="danger"
                block
                style={{marginTop: "0"}}
                disabled={this.props.disabled}
                onClick={this.props.deleteGroupAndOrphans}>Delete group AND<br/>all orphaned contacts</Button>
      </ModalFooter>
    );
  }
}

export default DeleteOrphanButtonComponents;
