import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faEdit
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  Button,
  DropdownToggle,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import CloseComponent from '../../SubComponent/CloseComponent';

import { getURLPrefix } from '../../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

const Header = (props) => {

  const {
    mode,
    groupName,
    formCanBeSubmit,
    formIsUsed,
    groupId,
    modaleDelete,
    closeCb,
    closeComponentTarget,
    groupCanNotBeDelete,
    saveAction,
    defaultSaveAction,
    isLoading,
    itemsLocked
  } = props;

  const groupStaff = props.ldap.configuration.staffGroupId;
  var itemsIsLocked = mode === 'EDIT' && itemsLocked && itemsLocked.length > 0 && itemsLocked.filter(itemLocked => itemLocked.isLocked ).length > 0;


  var viewTitle = () => {
    switch(mode) {
      case "CREATE":    return <h1 className="pannelTitle"><FontAwesomeIcon icon={faEdit}/> New group</h1>;
      case "EDIT":      return <h1 className="pannelTitle"><FontAwesomeIcon icon={faEdit}/> {groupName}</h1>;
      default:          return <h1 className="pannelTitle"><FontAwesomeIcon icon={faEdit}/> New group</h1>;
    }
  }

  return (
    <Row className="descriptionRow stickyRow editDescriptionRow" form>
        <Col>
          {viewTitle()}
        </Col>
        <Col  xs={4}  lg={2} style={{textAlign: "right"}}>
          <Col xs={12} className="btnCloseBox">
           <CloseComponent target={closeComponentTarget ? closeComponentTarget : pathname + "group"} />
          </Col>

          <Button onClick={closeCb} color="danger">Cancel</Button>
          {defaultSaveAction
            ? <Button 
                className={!formCanBeSubmit ? "btnSubmit" : null} 
                color="primary" 
                disabled={!formCanBeSubmit || isLoading}
                >
                Save
              </Button>
            : <Button 
                onClick={() => saveAction()} 
                color="primary" 
                disabled={!formCanBeSubmit || isLoading}>
                Save
              </Button>
          }
          {mode == "EDIT" && groupId != groupStaff && !groupCanNotBeDelete ? <UncontrolledButtonDropdown>
            <DropdownToggle className="dropdownToggleBtn" color="primary">
              <FontAwesomeIcon icon={faEllipsisV}/>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem 
                className="dropdownItemBtn" 
                onClick={modaleDelete} 
                disabled={isLoading || itemsIsLocked}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown> : null }
        </Col>
        {!formCanBeSubmit && formIsUsed ? <Col xs={12}><p  className="fieldsMissing">You cannot save because some required fields are missing</p></Col> : null}
    </Row>
  );
}

const mapStateToProps = state => ({
  ldap: state.ldap
});

export default compose( connect(mapStateToProps) )(Header);
