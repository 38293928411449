import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

class CellMultiRows extends React.Component {
  constructor(props) {
    super(props);
  }

  genRows(items, colsKeys){
    var cellRows = [];
    var rowsDisplayed = 0;

      items.forEach(item => {
        if(item.isDisplay === undefined || item.isDisplay){

          var row = [];
          colsKeys.forEach((col, i) => {


              if(Array.isArray(item[col])){


                var sub_row = [];

                item[col].forEach((sub_item, index) => {
                  if(item[col].length && sub_item && typeof(sub_item) == "object" && sub_item.fullname){
                    var addComma = item[col].length - 1 != index ? ", " : "";
                    sub_row.push(<span key={sub_item.id + index}><span className="cell-lastname">{sub_item.lastname}</span> <span className="cell-firstname">{sub_item.firstname}</span>{addComma}</span>);
                  }
                  else {
                    sub_row.push(<span key={sub_item + index}>{sub_item}</span>);
                  }
                });

                row.push(<ListGroupItem key={col + i} className="w-60 text-truncate">{sub_row}</ListGroupItem>);

              }
              else
                row.push(<ListGroupItem key={col + i} className="w-40 text-truncate">{item[col]}</ListGroupItem>);

          });


          cellRows.push(<ListGroup key={item.id} horizontal className="row-cellMultiRows">{row}</ListGroup>);
        }
      });
    return cellRows;
  }

  render(){
    var tableHeight = this.props.tableHeight;
    var rowsDisplayed = this.props.rows.filter(row => row.isDisplay || row.isDisplay === undefined ).length;

    var need = this.props.heightLimit && (rowsDisplayed * 45) > (tableHeight - 45);
    var style = {height : tableHeight - 225, overflowY: "auto"};

    return(
      <div style={need ? style : null}>
        {this.genRows(this.props.rows, this.props.colsKeys)}

        {need ? <div className="arrow">
                  <span></span>
                  <span></span>
                </div> : null }
      </div>
    );
  }
}

export default CellMultiRows;
