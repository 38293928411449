import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import appInfo from '../../../appVersion.json';

import leftImg from '../../assets/images/scientific.svg';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

const ErrorView = (props) => {

  const {
    error,
    errorInfo
  } = props;

  var goHome = () => {
    window.location.assign(pathname);
  };
  var date = Date();

  var dataToExport = '<ul><li>' + appInfo.appName + ' v' + appInfo.version + '</li> <li>Date : ' + date +' </li> <li>' + error.toString() + '</li> <li>'
    + errorInfo.componentStack +
  '</li></ul>';

  return (
    <Container fluid={true} style={{height:"100%"}}>
      <Row className="errorBoundary">
        <Col xs={4} className="errorBoundary-ContentLeft">
          <img src={leftImg}/>
        </Col>
        <Col xs={8} className="errorBoundary-Content">
          <div  className="errorBoundary-body">
            <h1>Ooops...</h1>
            <h5>Don't worry, someone is looking carefully at this error.</h5>
            <p>Please copy the error message to your clipboard<br/>
              <small>
                by clicking on the "copy to clipboard" button,<br/>then paste it into an email to your<br/>system administrator.<br/>Thank you.
              </small>
            </p>
            <details>
              {appInfo.appName + ' v' + appInfo.version}
              <br/>
              {date}
              <br/>
              {error && error.toString()}
              <br />
              {errorInfo.componentStack}
            </details>
            <Button color="primary" onClick={goHome}>Home</Button>
            <CopyToClipboard text={dataToExport} onCopy={() => {toast("The error has been copied, you can now copy it and send it by email.", {type : "success"})}} options={{format: "text/html"}}>
                <Button color="primary">Copy error to clipboard</Button>
            </CopyToClipboard>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default compose( withRouter )(ErrorView);
