import React from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { toast } from 'react-toastify';

import queryString from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';
import logo from '../../assets/images/efpia-logo.png';
import appInfo from '../../../appVersion.json';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fabGoogle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);

import './login.scss'

import {
  getCred,
  setGoogleCred,
  getStatusAuth
} from './action';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      current_user: props.user.logged_user,
      form: {
        email: '',
        passwd: ''
      },
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    this.props.onGetStatusAuth();
    if (this.nameInput) this.nameInput.focus();

    const params = queryString.parse(this.props.location.search);

    if(params.token && params.refreshToken && params.uid){

      this.props.onSetGoogleCred(params)
      .then(() => {
        this.props.history.push(pathname + 'contact');
      });
    }

    if(params.failure_redirect){
      toast("You are not allowed to sign in", { type:"error", autoClose: false });
    }
  }

  handleChange(event) {
    this.setState({
      form: {
        email: event.target.name === 'email' ? event.target.value : this.state.form.email,
        passwd: event.target.name === 'passwd' ? event.target.value : this.state.form.passwd,
      }
    });
    event.preventDefault();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({isLoading: true});

    if (this.state.form.email && this.state.form.passwd) {

      var loginData = {
        login : this.state.form.email,
        passwd : this.state.form.passwd
      };
      this.props.textLoadingOverlay("Please wait while we log you in and load data");
      this.props.displayLoadingOverlay(true);

      this.props.onGetCred(loginData).then(() => {
        this.props.history.push(pathname + 'contact')
        setTimeout(() => {
          this.props.displayLoadingOverlay(false);
        }, 6000);
      })
      .catch((err) => {
        this.setState({
          form: {
            email: '',
            passwd: '',
          }
        });
        toast("You are not allowed to sign in", { type:"error" });
        this.props.displayLoadingOverlay(false);
      })
      .finally(() => {
        this.setState({isLoading : false});
      });;

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { logged_user } = nextProps.user;
    if (logged_user.uid !== prevState.current_user.uid) {
      return {current_user: logged_user}
    }
    return null;
  }

  render() {
    var { user } = this.props;

    if(user.statusAuth === null){
      return <div className="loadingPage"><Spinner color="light" /> </div>;
    }

    var statusAuth = user.statusAuth;

    var loginWithGoogle = statusAuth.switch.googleAuth;
    var loginWithLDAP = statusAuth.switch.auth;
    var domain = statusAuth.domain + "/api/v1/oauth/google/auth";

    var loginContainerClassName = "container-fluid loginContainer";

    if(loginWithGoogle && !loginWithLDAP){
      loginContainerClassName += " loginWithGoogle ";
    }

    if(loginWithLDAP && !loginWithGoogle){
      loginContainerClassName += " loginWithLDAP ";
    }


    return (
      <div className={loginContainerClassName}>
        <div className="LoginContent">
          <form onSubmit={this.handleSubmit}>
            <div className="row loginHeader">
              <img src={logo}/>
              <p>LPO v{appInfo.version}</p>
            </div>

            <div className="loginBody">

              {loginWithLDAP ? <div className="row">
                <div className="form-group col-sm-12">
                  <label>Email</label>
                  <input ref={(input) => { this.nameInput = input; }}
                         value={this.state.form.email}
                         onChange={this.handleChange}
                         name="email"
                         type="email"
                         className="form-control" />
                </div>
                <div className="form-group col-sm-12">
                  <label>Password</label>
                  <input value={this.state.form.passwd}
                         onChange={this.handleChange}
                         name="passwd"
                         type="password"
                         className="form-control" />
                </div>
              </div> : null}

              {loginWithLDAP 
                ? <div className="ContentCenter mb-4">
                    <button 
                      type="submit"
                      className="btn btn-primary btn-block mt-3"
                      disabled={this.state.isLoading}>
                      Log In
                    </button>
                  </div> 
                : null 
              }

              {loginWithGoogle 
                ? <div className={loginWithLDAP ? "loginWithGoogle" : null}>
                    <Button 
                      color="primary"
                      block
                      onClick={() => { window.location.replace(domain) }}
                      disabled={this.state.isLoading}>
                        <FontAwesomeIcon 
                          icon={['fab', 'google']}
                          className="branding-icon"/> Login with Google
                    </Button>
                  </div> 
                : null
              }

            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onGetCred: (loginData) => dispatch(getCred(loginData)),
  onSetGoogleCred : (data) => dispatch(setGoogleCred(data)),
  onGetStatusAuth : () => dispatch(getStatusAuth())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
