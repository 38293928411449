import React from 'react';
import Autosuggest from 'react-autosuggest';



const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
  <div className="autosuggest-option">
    {suggestion.name}
  </div>
);

class AutosuggestComponent extends React.Component {
  constructor() {
    super();
    this.getSuggestions = this.getSuggestions.bind(this);

    this.state = {
      value: '',
      suggestions: []
    };
  }

  componentDidMount(){
    if(this.props.inputValue && this.props.inputValue.length > 0){
      this.setState({
        value: this.props.inputValue
      })
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.items.filter(item =>
      item.name.toLowerCase().includes(inputValue)
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.props.onChange(newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {

    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };


  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: this.props.placeholder ? this.props.placeholder : '...',
      value,
      onChange: this.onChange
    };

    return (
      <div className={this.props.isInvalid ? "react-autosuggest-component react-autosuggest-component-invalid" : "react-autosuggest-component"}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

export default AutosuggestComponent;