import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
  FormFeedback
} from 'reactstrap';
import { validateEmail } from "../../../SubComponent/fieldsValidators";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from "react-redux";

import GlossaryComponent from './GlossaryComponent';

class ModalGroupEmailValidator extends React.Component {
  constructor(props) {
    super(props);

    this.saveAction = this.saveAction.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.emailAlreadyExist = this.emailAlreadyExist.bind(this);

    this.state = {
      isOpen: props.isOpen,
      formIsUsed : false,
      email : props.email,
      emailWithoutSuffix : this.removeEmailSuffix(props.email),
      formIsChanged:false,
      titleModal : props.titleModal,
      groupMailDomain: '@' + props.ldap.configuration.groupEmailDomain
    };
  }
  componentDidMount(){
    if(this.props.ldap.configuration){
      this.setState({
        groupMailDomain: '@' + this.props.ldap.configuration.groupEmailDomain
      })
    }
  }

  removeEmailSuffix(email){
    if(email)
      return email.replace(this.state.groupMailDomain, '');
    return '';
  }

  saveAction(){
    var emailToLowerCase = this.state.email.toLowerCase();
    this.props.successCb(emailToLowerCase);
  }

  cancelAction(){
    this.props.cancelCb();
    this.setState({
      formIsUsed : false,
      isOpen:false,
      email: '',
      emailWithoutSuffix: '',
      formIsChanged : false
    });
  }

  static getDerivedStateFromProps(nextProps, prevState){

    const { isOpen, inputValue, ldap } = nextProps;

    if(isOpen != prevState.isOpen && ldap.configuration){
      var groupMailDomain = "@" + ldap.configuration.groupEmailDomain;

        return {  isOpen : isOpen,
                  email: nextProps.email,
                  titleModal : nextProps.titleModal,
                  emailWithoutSuffix : nextProps.email ? nextProps.email.replace(groupMailDomain, '') : '' };
    }
    return null;
  }

  handleChange(event) {

    var evtValue = event.target.value.trim().toLowerCase();
    var groupMailDomain = this.state.groupMailDomain;

    this.setState({
          formIsUsed : true,
          email: evtValue + groupMailDomain,
          emailWithoutSuffix: evtValue,
          formIsChanged : true
    });
    event.preventDefault();
  }

  emailAlreadyExist(){
    return  this.props.ldap.groups.filter(group => group.email.toLowerCase() == this.state.email.toLowerCase()).length > 0;
  }

  render() {
    var emailIsValid = validateEmail(this.state.email, "group") && !this.state.email.includes("+") && !this.state.email.includes("=") && !this.state.emailWithoutSuffix.includes("@");
    var emailIsTooLong = this.state.emailWithoutSuffix.length > 28;
    var emailAlreadyUsed = this.emailAlreadyExist();
    var domaineIsInvalid = this.state.email.length > 0 && !this.state.email.includes(this.state.groupMailDomain);
    var formCanBeSubmit = this.state.formIsChanged && emailIsValid && !emailIsTooLong && !emailAlreadyUsed && !domaineIsInvalid;
    var invalidEmail = (!emailIsValid && this.state.formIsChanged) || emailIsTooLong || (emailAlreadyUsed && this.state.formIsChanged) || domaineIsInvalid;

    return (
      <div>
        <Modal isOpen={this.state.isOpen} toggle={this.props.cancelCb} className="panelModal">
          <ModalHeader toggle={this.props.cancelCb} ><FontAwesomeIcon className="font-size-40" icon={faExclamationTriangle}/> <span>{this.state.titleModal}</span></ModalHeader>
          <ModalBody className="text-left">
          <h4>Please fill only the left part.</h4>
            <Label for="Email" className="required">{this.props.label}</Label>
            <InputGroup>
              <Input autoComplete="off"
                     type="text"
                     className="inputAppendLabel"
                     name="email"
                     value={this.state.emailWithoutSuffix}
                     invalid={invalidEmail}
                     required
                     placeholder="group"
                     onChange={this.handleChange}/>
              <InputGroupAddon addonType="append">
                <InputGroupText className="inputEmailModal">{this.state.groupMailDomain}</InputGroupText>
              </InputGroupAddon>
              <FormFeedback>
                {!emailIsValid ? "Email is not valid. " : null}
                {emailIsTooLong ? "There are more than 28 characters. " : null}
                {emailAlreadyUsed && this.state.formIsUsed ? this.state.email.toLowerCase() + " already used. ": null}
                {domaineIsInvalid ? "Email domain is invalid. " : null}
              </FormFeedback>
            </InputGroup>
            <FormText><FontAwesomeIcon icon={faInfoCircle}/> 28 characters maximum.<br/>Note : the email case cannot be changed.</FormText>

            <GlossaryComponent/>

            <FormText>
              <span className="text-danger">*</span> Required fields.<br/>
              <strong className="p-0">Don't forget to save your modification after work.</strong>
            </FormText>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={this.cancelAction}>Cancel</Button>
            <Button color="primary" disabled={!formCanBeSubmit} onClick={this.saveAction}>Ok</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

export default compose(
  connect(mapStateToProps)
)(ModalGroupEmailValidator);
