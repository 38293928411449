export const matchFullnameInvalidChars = (string) => {

  return string.match(/[^A-zÀ-ú0-9\u0370-\u03FF\s-']/gi);
};

export const matchAlphaNum = (string) => {

  return string.match(/[^A-zÀ-ú0-9\s-'()]/gi);
};

export const matchGroupnameInvalidChars = (string) => {

  return string.match(/[^A-zÀ-ú0-9\s-'()]/gi);
};

export const matchCompanynameInvalidChars = (string) => {

  return string.match(/[^A-zÀ-ú0-9\s-'().&]/gi);
};

export const matchInvalidCharsWhithPonctuation = (string) => {

  return string.match(/[^A-zÀ-ú0-9\s-'()?./&|,!:;"%+=/]/gi);
};

export const validateEmail = (email, type) => {
  if (!email){
    return false;
  }

  var emailParts = email.split('@');
  if(emailParts.length !== 2){
    return false;
  }

  var account = emailParts[0];
  var address = emailParts[1];

  if(account.length > 64){
    return false;
  } else if(address.length > 255){
    return false;
  }
  if(account.includes('^')){
    return false;
  }

  var domainParts = address.split('.');
  if(domainParts.length < 2){
    return false;
  }

  if (domainParts.some(part => part.length > 63)){
    return false;
  }

  var validRegex = null;

  if(type && type === "group"){
    validRegex = /^[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  } else {
    validRegex = /^[a-zA-Z0-9-._+!#$%&'*/=?^`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  }

  if(!validRegex.test(email)){
    return false;
  };

  return true;

}
