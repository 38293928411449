import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faInfoCircle , faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { getURLPrefix } from '../../utils/baseURLService.js';

class ModalCompanyDeleteView extends React.Component {
  constructor(props) {
    super(props);

    this.cancelAction = this.cancelAction.bind(this);
    this.saveAction = this.saveAction.bind(this);

    this.state = {
      isOpen : props.isOpen,
      company : props.company,
      people : props.ldap.people
    }
  }

  companyCanBeDeleted(company, people){

    var peopleInCompany = people.filter(function(contact){
      return company === contact.company;
    });

    return peopleInCompany.length == 0
  }

  cancelAction(){
    this.props.cancelCb();
  }

  saveAction(){
    this.props.successCb(this.state.company);
  }

  render() {

    var companyCanBeDeleted = this.companyCanBeDeleted(this.state.company, this.state.people);
    var pathname = getURLPrefix().pathname;

    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.cancelAction} className="panelModal">
          <ModalHeader style={{backgroundColor : "#dc3545"}} toggle={this.cancelAction} ><FontAwesomeIcon icon={faTrashAlt} style={{fontSize : "40px"}}/> <span>You are about to remove <br/>{this.props.titleModal} from LPO</span></ModalHeader>
          <ModalBody>
            <h4>{ companyCanBeDeleted ? "Do you really want to delete this company ?" : "This company contains members, you can not delete it."}</h4>
            {!companyCanBeDeleted ? "Make sure there are no more members before removing this company." : null}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.cancelAction}>Cancel</Button>
            { companyCanBeDeleted 
              ? <Button disabled={this.props.isLoading} color="danger" onClick={this.saveAction}>Delete</Button> 
              : <Link to={pathname + 'company/details/' + this.props.company}><Button color="primary" >View members</Button></Link>
            }
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(ModalCompanyDeleteView);
