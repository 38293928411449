import React from 'react';

function PanedViewport (props) {
  var style = {
    height: "100%",
    overflowY: "hidden",
    width: "100%"
  };
  var children = props.children.map( (component, index) => <div style={style} key={index}>{component}</div>);

  return (
    <div className={children.length > 1 ? "compactedMode" : null} style={{height:"100%", display: "flex"}}>
      {children}
    </div>
  );
}

export default PanedViewport;
