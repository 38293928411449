import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';


import {
  Container,
  Row,
  Col,
  Button,
  Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLockStatus } from '../../utils/lockService';
import {
  faMinusCircle,
  faLock
} from '@fortawesome/free-solid-svg-icons';

import ContactAddressCard from '../SubComponent/ContactAddressCard';
import ContactToolsAccess from '../SubComponent/ContactToolsAccess';
import ContactGroups from '../SubComponent/ContactGroups';
import HistoryLog from '../SubComponent/HistoryLog';
import ContactTitleHeader from '../SubComponent/ContactTitleHeader';
import ButtonExport from '../SubComponent/ButtonExport';
import CloseComponent from '../SubComponent/CloseComponent';

import { getLoggedUser } from '../../utils';

const POLLING_LOCK_TIMER = 5000;
import { getURLPrefix } from '../../utils/baseURLService.js';

class ContactDetailView extends React.Component {
  constructor(props){
    super(props);
    this.leftCol = React.createRef();
    this.editAction = this.editAction.bind(this);
    this.state = {
      contact: props.contact,
      groups: props.groups,
      current_user: getLoggedUser(),
      lock : {
        isLocked : false
      }
    }
  }

  componentDidUpdate(prevProps, prevState){

    if(prevProps.contact.id !== this.props.contact.id){
      clearInterval(this.lockTimer);
      this.lockTimer = null;
      var contactId = this.props.contact.id;
      this.getAndUpdateStateWithLockStatus(contactId);
      this.lockTimer = setInterval(() => {
        this.getAndUpdateStateWithLockStatus(contactId)
      }, POLLING_LOCK_TIMER);
    }

  }

  componentDidMount(){

    clearInterval(this.lockTimer);
    this.lockTimer = null;
    var contactId = this.props.contact.id;
    this.getAndUpdateStateWithLockStatus(contactId);
    this.lockTimer = setInterval(() => {
      this.getAndUpdateStateWithLockStatus(contactId)
    }, POLLING_LOCK_TIMER);

  }

  getAndUpdateStateWithLockStatus(resourceID){
    getLockStatus(resourceID)
    .then((status) => this.setState({lock:status}));
  }

  componentWillUnmount() {
    clearInterval(this.lockTimer)
    this.lockTimer = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const { contact, groups } = nextProps;

    if (contact && contact.groups && groups) {
      return { contact, groups}
    }

    return null;
  }

  editAction(){
    getLockStatus(this.state.contact.id)
    .then(status => {
      if(!status.isLocked || this.state.current_user.uid === this.state.lock.authUsername ){
        this.state.contact.editLinkCb();
      }
      else{
        this.setState({lock:status});
      }
    })
  }

  getLocationPathname(groupToRedirect){
    this.props.history.push(groupToRedirect.locationPathname);
  }

  render() {

    const { contact, groups } = this.state;

    if (!contact || !groups.length) {
      return (<div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-dark-40">
                  <Spinner color="light" />
                  <p className="text-white mt-3">Loading...</p>
               </div>);
    }

    var current_user = this.state.current_user;
    var isAllowedToEdit = current_user.perm === "ADMIN" || current_user.perm === "EDITOR";

    var isLocked = this.state.lock.isLocked;

    var leftColHeight = this.leftCol.current ? this.leftCol.current.offsetHeight : "100%";

    const groupToRedirect = JSON.parse(sessionStorage.getItem('group-redirect'));
    var pathname = getURLPrefix().pathname;

    return(
        <Container  className="containerRow" fluid={true}>
            <Row className="descriptionRow stickyRow">
                <Col xs={10}>
                  <ContactTitleHeader contact={contact} />
                </Col>

                <Col xs={2} className="text-right">
                  <CloseComponent target={pathname + "contact"} />
                </Col>

                <Col xs={12}>
                  <div className="text-right">
                  {groupToRedirect && groupToRedirect.contactId === contact.id
                    ? <Button className="btn-light-opacity mr-2-negatif"
                              onClick={() => this.getLocationPathname(groupToRedirect)}>Back to last group</Button>
                    : null
                  }
                  <ButtonExport data={contact} exportTo="CONTACT"/>
                  {isAllowedToEdit
                    ? <span>
                        <Button disabled={isLocked} onClick={this.editAction} color="primary">{isLocked ? <FontAwesomeIcon icon={faLock} /> : null} Edit</Button>{' '}
                        <Button disabled={isLocked} onClick={contact.duplicateLinkCb} color="primary">{isLocked ? <FontAwesomeIcon icon={faLock} /> : null} Duplicate</Button>
                      </span>
                    : null
                  }
                  </div>

                  {isLocked && isAllowedToEdit
                    ? <p className="text-right w-100 text-light">
                      {isLocked ? <strong><FontAwesomeIcon icon={faMinusCircle} /> Someone is already editing this contact.</strong> : null}
                      </p>
                    : null}
                </Col>
            </Row>

            <Row>
                <div className="col-6" style={{height: "100%"}} ref={this.leftCol}>
                  <ContactAddressCard contact={contact}/>
                  <ContactToolsAccess toolsAccess={contact.toolsAccess}/>
                  <HistoryLog logs={contact.logs} />
                </div>

                <Col xs={6} style={{height: leftColHeight}}>
                  <ContactGroups className="contactGroup-contactDetail" style={{height: "calc(100% - 4rem)"}} contactId={contact.id} groups={contact.groups}/>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default compose(
  withRouter
)(ContactDetailView);
