import { Badge, Input, Progress } from 'reactstrap';
import React from 'react';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faArrowRight, faArrowLeft, faSortUp, faSortDown, faSort, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import InfiniteScrollWrapper from '../QodScroller';
import { sortRows } from '../../utils/sortUtils';
import Logger from '../../utils/frontend-logger.js';
import { Link } from "react-router-dom";

import CellMultiRows from './CellMultiRows';

import KeyboardEventHandler from 'react-keyboard-event-handler';

const BUCKET = 100;

class ListFactory extends React.Component{
    constructor(props) {
        super(props);
        this.reactRef = React.createRef();

        this.navigation = this.navigation.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.state = {
          bucket: BUCKET,
          hasMore: false,
          displayHead: false,
          tableHeaderCells: [],
          rowsToDisplay: [],
          sortAsc : true,
          sortKey : props.sortKey,
          selectedRow : null,
          tableHeight: 45
        };
    }

    componentDidMount() {
      this.getThemRows(0);
      if(this.reactRef.current){
        this.setState({
          tableHeight : this.reactRef.current.offsetHeight
        })
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.cols !== prevProps.cols || this.props.rows !== prevProps.rows) {
        this.getThemRows(0)
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { initRowSelectedNavigation } = nextProps;

      if(initRowSelectedNavigation && initRowSelectedNavigation != prevState.initRowSelectedNavigation){
        return {selectedRow : null}
      }
      return null;
    }

    getThemRows = (page) => {

      const { rows, cols } = this.props;
      const { sortAsc, sortKey } = this.state;

      const order = sortAsc ? 'asc' : 'desc';
      const sorted_rows = sortRows(rows, sortKey, order);

      var start_bucket = page * BUCKET;
      var end_bucket = start_bucket + BUCKET;

      const current_rows = sorted_rows.slice(0, end_bucket);

      const hasMore = end_bucket < rows.length;

      this.setState({
        displayHead:  cols && cols[0] && cols[0].title ? true : false,
        tableHeaderCells: this.getTableHeaderCells(cols),
        rowsToDisplay: this.getTableRows(current_rows, cols),
        hasMore: hasMore,
        sorted_rows : current_rows,
        bucket: end_bucket
      })
    };

    applySort = (col) => {

      return new Promise((resolve) => {
        const { sortAsc, sortKey } = this.state;

        if (sortKey === col.key) {
          this.setState({
            sortAsc: !sortAsc
          })
        } else {
          this.setState({
            sortKey: col.key,
            sortAsc: true
          })
        }
        resolve()
      })
    }

    updateAfterSort = (col) => {

      this.applySort(col).then(() => {
        const { sortAsc, sortKey, bucket } = this.state;
        const { cols, rows } = this.props;
        var order = sortAsc ? 'asc' : 'desc';
        var sorted_rows = sortRows(rows, sortKey, order);

        const current = sorted_rows.slice(0, bucket);

        this.setState({
          tableHeaderCells: this.getTableHeaderCells(cols),
          rowsToDisplay: this.getTableRows(current, cols)
        })
      })
    }

    getTableHeaderCells = (cols) => {
      const validCols = cols.filter((col) => {
        if (col.type === 'text' && !(col.hasOwnProperty('key') && typeof col.key === 'string') ) {
            Logger.warn("List -- Missing property : cols[].key");
            return false;
        }
        return true;
      });
      return validCols.map((col, index) => {
          let sortIcon;
          var isNotColSortable = col.type === 'badge' || col.type === 'multiCells';
          var isColAdjustableWidth = col.type === 'toolsAccess' || col.type === 'badge';

          var width = "w-auto";

          var colTitle = <span>{col.title}</span>;

          if(isColAdjustableWidth){
            width = "w-130";
          }
          if(col.type === 'multiCells'){
            width = "w-75";
            colTitle = (<span className="titleMultiCells"><span className="w-40">{col.title}</span><span className="w-60 pl-3">Employees</span></span>);
          }

          if (this.state.sortKey === col.key && this.state.sortAsc === true){
            sortIcon = <span className="sortIcon"><FontAwesomeIcon icon={faSortUp}/><FontAwesomeIcon icon={faSortDown} className="opacity-20"/></span>;
          } else if (this.state.sortKey === col.key && this.state.sortAsc === false){
            sortIcon = <span className="sortIcon"><FontAwesomeIcon icon={faSortUp} className="opacity-20"/><FontAwesomeIcon icon={faSortDown}/></span> ;
          } else {
            sortIcon = <span className="sortIcon mr-2"><FontAwesomeIcon icon={faSort} className="opacity-20"/></span>
          }


          return (<th scope="col"
                      className={width}
                      key={index}
                      onClick={!isNotColSortable ? this.updateAfterSort.bind(this, col) : null }>{!isNotColSortable ? sortIcon : null}{colTitle}</th>);
      });
    }

    getTableRows = (rows, cols) => {
      const { options } = this.props;
      const tableRows = [];
      rows.forEach((row, index) => {
        const cells = [];
        cols.forEach((col) => {
          const clickHandler = () => this.clickHandler(row, col);

          if(col.type === 'icon') {
              var icon;
              if (col.icon === "arrowLeft")  { icon = <FontAwesomeIcon icon={faArrowLeft}/>; }
              if (col.icon === "arrowRight") { icon = <FontAwesomeIcon icon={faArrowRight}/>; }
              if (col.icon === "faTrashAlt") { icon = <FontAwesomeIcon icon={faTrashAlt}/>; }
              if (col.icon === "faPlusCircle") { icon = <FontAwesomeIcon icon={faPlusCircle}/>; }
              if (col.icon === "faMinusCircle") { icon = <FontAwesomeIcon icon={faMinusCircle}/>; }
              if (icon === undefined) { icon = ''; }
              cells.push(<td onClick={clickHandler} key={col.icon}>{icon}</td>);
          }
          if(col.type === "fullname"){
            var cellContainer = [];

            var value = row[col.key];

            if(col.key === "editors"){
              if(row.id === col.staffId){
                cellContainer.push("Directory administrator only");
                value = "";
              }

              if(row.id !== col.staffId && !value){
                cellContainer.push("All editors");
              }
            }

            if(Array.isArray(value)){
              value.forEach((item, index) => {
                var addComma = value.length - 1 != index ? ", " : "";
                cellContainer.push(<span title={item && item.lastname && item.firstname  ? item.lastname + ' ' + item.firstname : null} key={col.key + index}><span className="cell-lastname">{item.lastname}</span> <span className="cell-firstname">{item.firstname}</span>{addComma}</span>);
              });

            }

            else if(typeof(value) === 'object' && value){
              cellContainer.push(<span title={value && value.lastname && value.firstname ? value.lastname + ' ' + value.firstname : null} key={col.key}><span className="cell-lastname">{value.lastname}</span> <span className="cell-firstname">{value.firstname}</span></span>);
            }

            else {
              cellContainer.push(<span title={row && row.lastname && row.firstname ? row.lastname + ' ' + row.firstname : null} key={col.key}><span className="cell-lastname">{row.lastname}</span> <span className="cell-firstname">{row.firstname}</span></span>);
            }

            cells.push(<td key={col.key} onClick={clickHandler} className="text-truncate">{cellContainer}</td>);
          }
          if(col.type === 'text') {
            var value = row[col.key];
            if(Array.isArray(value) && value.length > 0){
              if(col.key === 'assignedGroup'){
                value = row[col.key].map(item => item.name).join(', ');
              } else {
                value = value.join(', ');
              }
            }
            cells.push(<td key={col.key} title={value} onClick={clickHandler} className="text-truncate test">{value}</td>);
          }
          if(col.type === 'textWithRole') {
              var value = row[col.key];
              cells.push(<td key={col.key} title={value} onClick={clickHandler} className={"textWithRoleCol-" + row.role}>
                            <span className="span-contain text-truncate">{value}</span>
                            {row.role ? <Badge color={row.role == "Vice-chair" ? "warning" : "primary"} style={{color : "#ffffff"}}>{row.role}</Badge> : null}
                         </td>);
          }
          if(col.type === 'mailto') {
              cells.push(<td key={col.key} title={row[col.key]} className="text-truncate"><a href={"mailto:" + row[col.key]} target="_blank">{row[col.key]}</a></td>);
          }
          if(col.type === 'tel') {
              cells.push(<td key={col.key} title={row[col.key]} className="text-truncate"><a href={"tel:" + row[col.key]} target="_blank">{row[col.key]}</a></td>);
          }
          if(col.type === 'link' && col.target) {
              cells.push(<td key={col.key} title={row[col.key]} className="text-truncate"><Link to={col.target + row[col.key]} style={{textDecoration: "none"}}>{row[col.key]}</Link></td>);
          }
          if(col.type === 'date'){
            var format = col.format;
            if (format === undefined) {format = 'LLL';}
            var dateFormated = Moment(row[col.key]).format(format);
            cells.push(<td key={col.key} title={dateFormated} onClick={clickHandler}  className="text-truncate">{dateFormated}</td>)
          }
          if(col.type === 'badge'){
            cells.push(<td key={col.key} className="w-130" onClick={clickHandler}><Badge color={row[col.key] == "Vice-chair" ? "warning" : "primary"} style={{color : "#ffffff"}}>{row[col.key]}</Badge></td>)
          }
          if(col.type === 'toolsAccess' && col.tool){
            cells.push(<td key={col.key} onClick={clickHandler} style={{color: row[col.key].includes(col.tool) ? "#28a745" : "#dc3545", fontWeight: "bold", width: "130px", textAlign: "center"}}>{row[col.key].includes(col.tool) ? "Yes" : "No"}</td>)
          }
          if(col.type === 'multiCells' && col.colsKeys){
            var value = row[col.key];

            cells.push(<td className="p-0 w-75" onClick={clickHandler} key={col.key}><CellMultiRows tableHeight={this.state.tableHeight} heightLimit={rows.length > 1} clickHandler={clickHandler} rows={value} colsKeys={col.colsKeys}/></td>);
          }
          if(col.type === 'count'){
            var value = row[col.key];
            cells.push(<td key={col.key} title={value.length} onClick={clickHandler} className="text-truncate test">{value.length}</td>);
          }
          if(col.type === 'inputSelect'){
            if(this.props.isLoading){
              cells.push(<td key={col.key} className="loading"><Progress animated value="100" /></td>);
            } else {

              if(row.transferSuccessfully){
                cells.push(<td key={col.key} className="text-truncate text-primary font-weight-bold">Transferred to {row.contactTransfered}</td>);
              } else if(row.transferRejected){
                cells.push(<td key={col.key} className="text-truncate text-danger">
                  Transfer failed, please try again.
                </td>);
              } else {
                var options = sortRows(col.options, 'fullname', 'asc').map(option => <option key={option.id} value={option.id}>{option.fullname}</option>);
                options.unshift(<option key="empty-option" value="">...</option>);

                cells.push(<td key={col.key}>
                <Input
                  id={row.id}
                  name="select"
                  type="select"
                  onChange={(event) => row.handleChangeCb(event)}
                  value={row.optionValue}
                >
                  {options}
                </Input>
              </td>);
              }

            }
          }

        });
        tableRows.push(<tr key={index} id={this.props.incentiveNavigation ? row.id : null} className={this.props.rowSelectedId && this.props.rowSelectedId === row.id ? "active" : null}>{cells}</tr>);
      });

      var selectAllOptionsRow = this.props.selectAllOptionsRow;

      if(rows.length > 1 && selectAllOptionsRow && cols.filter(col => col.type === 'inputSelect').length > 0 ){
        var selectOptions = sortRows(selectAllOptionsRow.options, 'fullname', 'asc').map(option => <option key={option.id} value={option.id}>{option.fullname}</option>);
        selectOptions.unshift(<option key="empty-option" value="">...</option>);

        tableRows.unshift(<tr key="selectAllOptionsRow">
          <td>Transfer all groups</td>
          <td>
            <Input
              id="selectAll"
              name="select"
              type="select"
              onChange={(event) => selectAllOptionsRow.handleChangeCb(event)}
              value={selectAllOptionsRow.optionValue}
              disabled={rows.filter(row => row.transferSuccessfully || row.transferRejected ).length > 0}
            >
              {selectOptions}
            </Input>
          </td>
        </tr>);
      }

      return tableRows;
    }

    clickHandler(row, col){

      const { options, incentiveNavigation } = this.props;

      if(row.onClickKey){
        row.onClickKey()
      }
       else {
         row[options.rowClickHandlerKey]();
         if(incentiveNavigation){
           this.setState({
             selectedRow : row
           });
         }
       }
    }

    scrollWin(rowId, e) {

      document.onkeydown = e.preventDefault();

      setTimeout(() => {
        var elmnt = document.getElementById(rowId);

        document.getElementById("incentiveNavigationList").scrollTo({
          top: elmnt.offsetTop - 46,
          left : 0,
          behavior: 'smooth'
        });
      }, 10);
    }

    navigation(key, e){

      var selectedRow = this.state.selectedRow;
      var rows = this.state.sorted_rows;
      var updateSelectedRow = selectedRow;

      if(key === "down"){

        if(selectedRow){
          rows.forEach((row, index) => {
            if(row.id === selectedRow.id && (index < rows.length - 1)){
              updateSelectedRow = rows[index + 1];
            }
          });
        } else {
          updateSelectedRow = rows[0];
        }
      }

      if(key === "up"){
        if(selectedRow){
          rows.forEach((row, index) => {
            if(row.id === selectedRow.id && (index != 0)){
              updateSelectedRow = rows[index - 1];
            }
          });
        }
        else {
          updateSelectedRow = rows[rows.length - 1];
        }
      }
      this.setState({
        selectedRow : updateSelectedRow
      });
      this.props.rowSelectedNavigationCb(updateSelectedRow);
      this.scrollWin(updateSelectedRow.id, e);
    }

    render() {
          const { displayHead, tableHeaderCells, rowsToDisplay } = this.state;
          if(this.props.isLoading){
            rowsToDisplay.push(<tr key="listNotification" className="text-truncate"></tr>);
          }

          if (!this.props.isLoading && rowsToDisplay.length === 0) {
            rowsToDisplay.push(<tr key="listNotification" className="text-truncate"><td>No result found...</td></tr>)
          }

          return (
            <div className="qod-scroller-wrapper" style={{height: "100%"}} id={this.props.incentiveNavigation ? "incentiveNavigationList" : null} ref={this.reactRef}>

                {this.props.incentiveNavigation && this.props.rowSelectedNavigationCb ? <KeyboardEventHandler handleKeys={['all']} onKeyEvent={(key, e) => this.navigation(key, e)} /> : null}
                <InfiniteScrollWrapper
                      isReverse={false}
                      tableHeaderCells={tableHeaderCells}
                      displayHead={displayHead}
                      items={rowsToDisplay}
                      hasMore={this.state.hasMore}
                      getNewData={this.getThemRows}
                      loaderComponent={<p key={0}><span className="lds-ellipsis"><span></span><span></span><span></span><span></span></span></p>}
                  />
            </div>
          );
    }
}

export default ListFactory;
