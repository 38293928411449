import React from 'react';
import { matchAlphaNum, matchInvalidCharsWhithPonctuation } from "../../../SubComponent/fieldsValidators";

const JOB_MAX_CHARS = 60;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback
} from 'reactstrap';
import { simpleSort } from '../../../../utils/sortUtils';

class ContactEditCompany extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      mode : props.mode
    }
  }
  componentDidMount(prevProps){

    if(this.props.mode === "EDIT" || this.props.mode === "DUPLICATE"){
      this.props.boxCanBeSubmit(true);
    }

  }

  render(){

    const {
      employeeType,
      handleChange,
      company,
      companies,
      formIsUsed,
      boxCanBeSubmit
    } = this.props;

    var companiesList = simpleSort(companies);

    var employeeTypeIsInvalid = false;
    if(matchInvalidCharsWhithPonctuation(employeeType)){
      employeeTypeIsInvalid = matchInvalidCharsWhithPonctuation(employeeType).length > 0;
    }

    var onEmployeeTypeChange = (event) => {

      handleChange(event);

      var employeeTypeValue = event.target.name === "employeeType" ? event.target.value : employeeType;
      var companyValue = event.target.name === "company" ? event.target.value : company;

      var employeeTypeIsValid = employeeTypeValue.length === 0 || !matchInvalidCharsWhithPonctuation(employeeTypeValue) && !(employeeTypeValue.length > JOB_MAX_CHARS);
      var companyIsValid = companyValue.length !== 0 && employeeTypeIsValid;


      boxCanBeSubmit(companyIsValid);

    };

     var optionCompanyItems = companiesList.map((item) =>
         <option key={item} value={item}>{item}</option>
       );
     optionCompanyItems.unshift(<option key="company-selector" value="">Select a company...</option>)

    return (
      <div className="panelBoxSection" >
        <div className="panelBoxTitle">
          <div className="panelBoxTitle-icon">
            <FontAwesomeIcon icon={faBuilding}/>
          </div>
          <h2>Company</h2>
        </div>
        <div className="panelBoxBody">

          <Row>
            <Col>
              <FormGroup>
                <Label for="job">Job  title</Label>
                <Input autoComplete="noautoComplete"
                       type="text"
                       name="employeeType"
                       invalid={employeeTypeIsInvalid || employeeType.length > JOB_MAX_CHARS}
                       value={employeeType}
                       placeholder="Manager..."
                       onChange={onEmployeeTypeChange} />
                 <FormFeedback>
                   {employeeType.length > JOB_MAX_CHARS ? "Job  title is too long. Max " + JOB_MAX_CHARS + " allowed. " : null}
                   {employeeTypeIsInvalid ? "Characters not allowed " + matchInvalidCharsWhithPonctuation(employeeType).join(" ") : null}
                 </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
            <FormGroup>
              <Label for="company" className="required">Company</Label>
              <Input autoComplete="noautoComplete"
                     type="select"
                     name="company"
                     value={company}
                     invalid={company.length === 0 && formIsUsed}
                     onChange={onEmployeeTypeChange}>
                {optionCompanyItems}
              </Input>
               <FormText><FontAwesomeIcon icon={faInfoCircle}/> If you do not found the company name, go to the companies tab to create a new one.</FormText>
            </FormGroup>
            </Col>
          </Row>

        </div>
      </div>
    );
  };
}
export default ContactEditCompany;
