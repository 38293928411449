
import { getCountry } from './SubComponent/Country-List/countriesOverrided';
import { sortRows } from '../utils/sortUtils';
const tableStyle = 'border-collapse : collapse;';
const headerStyle = 'background : #01aec2; text-align : left; padding : 15px;';
const cellStyle = 'padding : 15px;';
const rowStyle = 'border-bottom: 1px solid #dee2e6;';
const tdMultiCell = rowStyle + ' padding : 5px 10px;';

const generateTableHead = (displayedCols) => {
  displayedCols = filterDisplayedCols(displayedCols);

  var header = '<tr>';

  displayedCols.forEach(col => {
    header += '<td style="' + headerStyle + '">' + col.title + '</td>';
  });

  header += '</tr>';

  return header;
};

const generateTableBody = (rowsFilter, displayedCols, exportFormat) => {
  var rows = '';

  displayedCols = filterDisplayedCols(displayedCols);

  rowsFilter = sortRows(rowsFilter, "id", "asc");

  rowsFilter.forEach(row => {
    var tRow = '<tr style="' + rowStyle +'">';

    displayedCols.forEach(col => {
      var value = row[col.key] ? row[col.key] : '';

      if(Array.isArray(value)){
        var valueArray = '';
        value.forEach((item, index) => {
          if(item && item.id){
            if(index + 1 === value.length){
              valueArray += item.fullname ? item.fullname : item.id;
            } else {
              valueArray += item.fullname ? item.fullname + ', ' : item.id + ', ';
            }
          } else {
            valueArray = value.join(', ');
          }
        });
        value = valueArray;
      };

      if(typeof(value) === 'object' && value){
        value = value.fullname ? value.fullname : value.id;
      }

      if(col.type == "mailto"){
        value = '<a href="mailto:'+ value + '">' + value + ' </a>';
      }

      if(exportFormat == "sheet"){
        value = escapeStringForExcelphoneNumber(col, value);
      }

      if(exportFormat == "email" && col.type === "tel"){
        value = '<a href="tel:'+ value + '">' + value + ' </a>';
      }

      tRow += '<td style="' + cellStyle +'">' + value + '</td>';

    });

    tRow += '</tr>';
    rows += tRow;
  });



  return '<tbody>' + rows + '</tBody>';
}

const filterDisplayedCols = (displayedCols) => {
  displayedCols = displayedCols.filter(col => col.key !== "modifiedDate" && col.key !== "createdDate");
  return displayedCols;
};

const escapeStringForExcelphoneNumber = (col, value) => {

  var valueFormatted = value;
  if(col.key == "phone" || col.key == "mobile"){
    valueFormatted = '\'' + value;
  }
  return valueFormatted;
};

export const tableMultiRows = (rowsFilter, displayedCols) => {


  var rows = '';
  rowsFilter = sortRows(rowsFilter, "id", "asc");

  var thead = '<table style="' + tableStyle + '"><tr>';

  displayedCols.forEach(col => {
    if(col.type == 'multiCells' && col.key === "groups"){
      thead += '<td style="' + headerStyle +'">Groups</td><td style="' + headerStyle +'">Employees</td>';
    } else {
      thead += '<td style="' + headerStyle +'">' + col.title + '</td>';
    }

  });

  rows += thead + '</tr><tbody>';


  rowsFilter.forEach(row => {
    var tRow = '<tr>';
    displayedCols.forEach(col => {
      var value = row[col.key] ? row[col.key] : '';
      var rowspan = displayedCols.length > 1 && Array.isArray(row.groups) && row.groups.length ? row.groups.filter(group => group.isDisplay === undefined || group.isDisplay ).length : 1 ;


      if(col.type == 'multiCells'){
        value.forEach((item, index) => {
          if(item.isDisplay === undefined || item.isDisplay){
            var subCell = '';
            col.colsKeys.forEach(colKey => {
              if(Array.isArray(item[colKey])){
                var subItemValue = '';

                item[colKey].forEach((subItem, id) => {
                  var commas = item[colKey].length -1 !== id ? ', ' : '';
                  subItemValue += subItem.fullname + commas;
                });

                var emptyCell = index+1 === value.length ? '' : '<td  style="' + tdMultiCell +'"></td>';

                subCell += '<td  style="' + tdMultiCell +'" rowspan="1">' + subItemValue + '</td></tr>' + emptyCell;

              } else {
                subCell += '<td  style="' + tdMultiCell +'" rowspan="1">' + item[colKey] + '</td>';
              }

            });

            tRow += subCell ;
          }
        });
        if(!value || value.length === 0 ){
          tRow += ('<td  style="' + tdMultiCell +'"></td>').repeat(col.colsKeys.length);
        }
      }

      if(col.typeExport == "rowspanExport"){
        tRow += '<td  style="' + tdMultiCell +'">' + value + '</td>';
      }

    });
    rows += tRow;
  });

  return rows + '</tr></tbody></table>';

}

export const generateHtmlGroupDetail = (group) => {

  var chairsHtml = '';
  var vicechairsHtml = '';

  if(group.chairs){
    group.chairs.forEach((chair) => {
      chairsHtml += '<li><strong>Chair :</strong> <a href="mailto:' + chair.email + '"> ' + chair.fullname + '</a></li> ';
    });
  }

  if(group.viceChairs){
    group.viceChairs.forEach((vicechair) => {
      vicechairsHtml +=  '<li><strong>Vice-chair :</strong><a href="mailto:' + vicechair.email + '"> ' + vicechair.fullname + '</a></li> ';
    });
  }



  return '<ul><li><strong>Group name :</strong> ' + group.name + '</li><li><strong>Group email :</strong> '
                                    + '<a href="mailto:' + group.email + '"> ' + group.email + '</a>'
                                    + '</li> ' + chairsHtml + vicechairsHtml + '<li><strong>EFPIA contact :</strong>'
                                    + '<a href="mailto:' + group.efpiaContact.email + '"> ' + group.efpiaContact.fullname + '</a></li></ul>';
};

export const generateHtmlContactDetail  = (contact) => {

  var title = contact.title ? contact.title + ' ' : '';

  var postalAddress = contact.postalAddress ? contact.postalAddress.replace(/\\n/g, '\n').replace(/\$/g, '\n'): '';

  var countryName = getCountry(contact.country);

  var phone = contact.phone ? '<a href="tel:' + contact.phone + '">' + contact.phone + '</a>' : '';
  var mobile = contact.mobile ? '<a href="tel:' + contact.mobile + '">' + contact.mobile + '</a>' : '';
  var job = contact.employeeType ? contact.employeeType : '';

  var departmentNumber = contact.departmentNumber ? contact.departmentNumber + ' ' : '';
  var postalCode = contact.postalCode ? ' - ' + contact.postalCode : '';
  var city = contact.city ? contact.city : '';


  return '<ul><li><strong>Name :</strong> ' + title + contact.fullname + '</li><li><strong>Email :</strong> <a href="mailto:' + contact.email
                                    + '">' + contact.email
                                    + '</a></li><li><strong>Phone :</strong> '
                                    + phone + '</li><li><strong>Mobile :</strong> '
                                    + mobile + '</li><li><strong>Job title : </strong>'
                                    + job + '</li><li><strong>Company : </strong>'
                                    + contact.company + '</li><li><strong>Address : </strong>'
                                    + departmentNumber + postalAddress  + postalCode + ' ' + city + ' ' + countryName + '</li></ul>';
};

export const generateHtmlClipboard = (rowsFilter , displayedCols, exportFormat) => {

  return  '<table style= "' + tableStyle + '">' +
            generateTableHead(displayedCols) +
            generateTableBody(rowsFilter, displayedCols, exportFormat) +
          '</table>';
}
