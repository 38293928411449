import React from 'react';

import LabelWidget from './LabelWidget';

const MAX_CHAIRS = 2;

class ChairsComponent extends React.Component {

  render(){
    var labelChairsDisabled = this.props.chairs.length >= MAX_CHAIRS || this.props.viceChairs.some(chairId => chairId === this.props.currentItem);
    var labelViceChairsDisabled = this.props.viceChairs.length >= MAX_CHAIRS || this.props.chairs.some(chairId => chairId === this.props.currentItem);
    var labelChairsFeedback = this.props.chairs.length >= MAX_CHAIRS ? "Max two chairs per group." : null;
    var labelViceChairsFeedback = this.props.viceChairs.length >= MAX_CHAIRS ? "Max two vice-chairs per group." : null;

    var labelItems = [{name : "Chair",
                  labelColor : "#007bff",
                  labelDisabled: labelChairsDisabled,
                  addAction: this.props.addLabelAction,
                  removeAction : this.props.removeLabelAction,
                  feedBack : labelChairsFeedback
                },
                {name : "Vice-chair",
                  labelColor : "#ffc107",
                  labelDisabled: labelViceChairsDisabled,
                  addAction: this.props.addLabelAction,
                  removeAction : this.props.removeLabelAction,
                  feedBack : labelViceChairsFeedback
                }
              ];

    return(
      <LabelWidget currentItem={this.props.currentItem} items={labelItems}/>
    );
  }
}

export default ChairsComponent;
