import axios from "axios";
import { getAuthHeaders } from '../../utils/index';
import { logout } from '../../utils/httpErrors';

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

const API_URL = pathname + 'api/v1';

var getEnpoint = (ep) => {
  if (['people', 'group', 'company', 'config'].includes(ep)) {
    return API_URL + `/${ep}/`;
  }
  return API_URL + '/';
}


export function fetchPeople() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_PEOPLE" });
      var url = getEnpoint('people')
      axios({
        url,
        method: 'get',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "FETCH_PEOPLE_FULFILLED", payload: response.data })
        resolve()
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "FETCH_PEOPLE_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function createContact(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "CREATE_PEOPLE" });
      var url = getEnpoint('people')
      axios({
        url,
        data,
        method: 'post',
        headers: getAuthHeaders(true)
      }).then((response) => {
        dispatch({ type: "CREATE_PEOPLE_FULFILLED", payload: response.data })
        resolve(response.data)
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "CREATE_PEOPLE_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function updateContact(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "UPDATE_PEOPLE" });
      var url = getEnpoint('people')
      axios({
        url,
        data,
        method: 'put',
        headers: getAuthHeaders(true)
      }).then((response) => {
        dispatch({ type: "UPDATE_PEOPLE_FULFILLED", payload: response.data })
        resolve(response.data)
      }).catch((err) => {
        dispatch({ type: "UPDATE_PEOPLE_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err);
        logout(err)
      })
    })
  }
}

export function deleteContact(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "DELETE_PEOPLE" });
      var url = getEnpoint('people')
      axios({
        url,
        data,
        method: 'delete',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "DELETE_PEOPLE_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        dispatch({ type: "DELETE_PEOPLE_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
  }
}

export function createGroup(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "CREATE_GROUP" });
      var url = getEnpoint('group')
      axios({
        url,
        data,
        method: 'post',
        headers: getAuthHeaders(true)
      }).then((response) => {
        dispatch({ type: "CREATE_GROUP_FULFILLED", payload: response.data })
        resolve()
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "CREATE_GROUP_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function deleteGroup(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "DELETE_GROUP" });
      var url = getEnpoint('group') + '/orphans';
      axios({
        url,
        data,
        method: 'delete',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "DELETE_GROUP_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        dispatch({ type: "DELETE_GROUP_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
  }
}

export function fetchGroupById(id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_GROUPBYID" });
      var url = getEnpoint('group') + '?id=' + id;
      axios({
        url,
        method: 'get',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "FETCH_GROUPBYID_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "FETCH_GROUPBYID_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function fetchGroups() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_GROUPS" });
      var url = getEnpoint('group')
      axios({
        url,
        method: 'get',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "FETCH_GROUPS_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "FETCH_GROUPS_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function updateGroup(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "UPDATE_GROUPS" });
      var url = getEnpoint('group')
      axios({
        url,
        data,
        method: 'put',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "UPDATE_GROUPS_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        dispatch({ type: "UPDATE_GROUPS_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
  }
}

export function getOrphanContacts(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "GET_ORPHAN_CONTACTS_GROUP" });
      var url = getEnpoint('group') + "/preshot-orphans";
      axios({
        url,
        data,
        method: 'put',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "GET_ORPHAN_CONTACTS_GROUP_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        dispatch({ type: "GET_ORPHAN_CONTACTS_GROUP_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
  }
}

export function resetOrphanContacts() {
  const response = {};
  response.data = undefined;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "RESET_ORPHAN_CONTACTS_GROUP" });
      dispatch({ type: "RESET_ORPHAN_CONTACTS_GROUP_FULFILLED", payload: response.data })
      resolve()
    });
  }
}

export function fetchCompanies() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_COMPANIES" });
      var url = getEnpoint('company')
      axios({
        url,
        method: 'get',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "FETCH_COMPANIES_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "FETCH_COMPANIES_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function createCompany(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "CREATE_COMPANY" });
      var url = getEnpoint('company')
      axios({
        url,
        data,
        method: 'post',
        headers: getAuthHeaders(true)
      }).then((response) => {
        dispatch({ type: "CREATE_COMPANY_FULFILLED", payload: response.data })
        resolve()
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "CREATE_COMPANY_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

export function updateCompany(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "UPDATE_COMPANY" });
      var url = getEnpoint('company')
      axios({
        url,
        data,
        method: 'put',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "UPDATE_COMPANY_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        dispatch({ type: "UPDATE_COMPANY_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
  }
}

export function deleteCompany(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "DELETE_COMPANY" });
      var url = getEnpoint('company')
      axios({
        url,
        data,
        method: 'delete',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "DELETE_COMPANY_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        dispatch({ type: "DELETE_COMPANY_REJECTED", payload: err })
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
  }
}

export function fetchConfiguration() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: "FETCH_CONFIGURATION" });
      var url = getEnpoint('config')
      axios({
        url,
        method: 'get',
        headers: getAuthHeaders(false)
      }).then((response) => {
        dispatch({ type: "FETCH_CONFIGURATION_FULFILLED", payload: response.data })
        resolve();
      }).catch((err) => {
        console.log('ERROR! : ', err);
        dispatch({ type: "FETCH_CONFIGURATION_REJECTED", payload: err })
        reject(err)
        logout(err)
      })
    })
  }
}

// API Groups v2
export function updateGroupsV2(data) {
  return new Promise((resolve, reject) => {
      var url = pathname + 'api/v2/groups';
      axios({
        url,
        data,
        method: 'put',
        headers: getAuthHeaders(false)
      }).then((response) => {
        resolve(response.data);
      }).catch((err) => {
        console.log('ERROR! : ', err);
        reject(err)
        logout(err)
      })
    })
}


