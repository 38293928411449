import React from 'react';
import {
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from 'reactstrap';
import { compose } from 'redux';
import { connect } from "react-redux";
import {
  getOrphanContacts,
  resetOrphanContacts
} from '../../../ODD/action';
import { unlockAllResources, refreshAllUserResources } from '../../../../utils/lockService';
import { sortRows } from '../../../../utils/sortUtils';

import OrphanContactComponent from '../DeleteSubComponents/OrphanContactComponent';
import DeleteOrphanButtonComponents from '../DeleteSubComponents/DeleteOrphanButtonComponents';
import DeleteConfirmationView from '../DeleteSubComponents/DeleteConfirmationView';

const POLLING_LOCK_TIMER = 240000;

class DeleteGroupView extends React.Component {
  constructor(props) {
    super(props);

    this.deleteGroupAndOrphans = this.deleteGroupAndOrphans.bind(this);

    this.toggle = this.toggle.bind(this);
    this.deleteAction = this.deleteAction.bind(this);

    this.state = {
      deleteGroupAndOrphans: false,
      orphanContacts : undefined
    }

  }

  componentDidUpdate(prevProps, prevState){

      if(prevProps.ldap.orphanContacts !== this.props.ldap.orphanContacts){
        clearInterval(this.lockTimer);
        this.lockTimer = null;
        refreshAllUserResources();

        this.lockTimer = setInterval(() => {
          refreshAllUserResources();
        }, POLLING_LOCK_TIMER);
      }

    }

  componentDidMount(){
    var dataToSend = {
      id : this.props.groupId
    };
    this.props.onGetOrphanContacts(dataToSend);

    if(this.props.ldap.orphanContacts && this.props.ldap.orphanContacts.length > 0){
      clearInterval(this.lockTimer);
      this.lockTimer = null;
      refreshAllUserResources();

      this.lockTimer = setInterval(() => {
        refreshAllUserResources();
      }, POLLING_LOCK_TIMER);
    }
  }

  componentWillUnmount() {
    clearInterval(this.lockTimer)
    this.lockTimer = null;
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const { orphanContacts, people } = nextProps.ldap;

    if ( people && orphanContacts && orphanContacts !== prevState.orphanContacts){

      var orphanContactsfulfilled = [];

      if(orphanContacts.length > 0){

        var contactsFullData = people.filter(person => orphanContacts.some(contact => contact === person.id));
        orphanContactsfulfilled = sortRows(contactsFullData, 'fullname', 'asc');

      }

      return { orphanContacts : orphanContactsfulfilled }
    }

    return null;
  }

  deleteGroupAndOrphans(){
    this.setState({
      deleteGroupAndOrphans : true
    });
  }

  toggle(){
    this.props.onResetOrphanContacts();
    this.props.toggle();
    unlockAllResources();
    clearInterval(this.lockTimer);
    this.lockTimer = null;
    this.setState({
      deleteGroupAndOrphans : false
    });
  }

  deleteAction(){
    this.props.onResetOrphanContacts();
    this.props.deleteAction();
    unlockAllResources();
    clearInterval(this.lockTimer);
    this.lockTimer = null;
    this.setState({
      deleteGroupAndOrphans : false
    });
  }

  render() {
    var orphanContacts = this.state.orphanContacts;

    if(orphanContacts === undefined){
      return <div className="modalBodyLoading"><Spinner color="dark" /> </div>;
    }

    var deleteGroupAndOrphans = this.state.deleteGroupAndOrphans;

    return (
      <div>
        {orphanContacts.length === 0 ? <div>
          <ModalBody>
            <h2 style={{padding: "1rem"}}>Are you sure you want to delete this group of LPO?</h2>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>{' '}
            <Button color="danger" onClick={this.deleteAction}>Delete</Button>
          </ModalFooter>
        </div> : null }


        {orphanContacts.length > 0 && !deleteGroupAndOrphans ? <div>
          <ModalBody>
            <OrphanContactComponent groupId={this.props.groupId}
                                    orphanContacts={orphanContacts}/>
          </ModalBody>
          <DeleteOrphanButtonComponents toggle={this.toggle}
                                        deleteGroupAndOrphans={this.deleteGroupAndOrphans}
                                        groupName={this.props.groupId}
                                        orphanContacts={orphanContacts}/>
         </div> : null}


         {deleteGroupAndOrphans ? <DeleteConfirmationView toggle={this.toggle}
                                                          deleteGroupAndOrphans={this.deleteAction}
                                                          groupId={this.props.groupId}
                                                          orphanContacts={orphanContacts}/> : null }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap
});

const mapDispatchToProps = dispatch => ({
  onGetOrphanContacts: (data) => dispatch(getOrphanContacts(data)),
  onResetOrphanContacts : () => dispatch(resetOrphanContacts())
});

export default compose( connect(mapStateToProps, mapDispatchToProps) )(DeleteGroupView);
