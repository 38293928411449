import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

class CollapseItem extends React.Component {
  constructor(props){
    super(props);

    this.displayAllContent = this.displayAllContent.bind(this);
    this.truncateItem = React.createRef();

    this.state = {
      item : props.item,
      isItemOpen : false
    }
  }

  displayAllContent(){
    this.setState({
      isItemOpen : !this.state.isItemOpen
    });
  }

  render(){

    var icon = this.state.isItemOpen ? faMinus : faPlus;

    var isItemCollapsed = false;

    if(this.truncateItem && this.truncateItem.current && this.truncateItem.current.offsetWidth){
      var itemWidth = this.truncateItem.current.offsetWidth;
      var charactereToDisplay = (itemWidth / 9);
      var isItemCollapsed = this.props.item.length > charactereToDisplay;
    }

    return (
        <ListGroupItem>
        <div ref={this.truncateItem} className="collapseItem w-100">
          <span className={this.state.isItemOpen ? "" : "text-truncate"}>{this.props.item}</span>
          {isItemCollapsed ? <FontAwesomeIcon icon={icon} onClick={this.displayAllContent}/> : null}
          </div>
        </ListGroupItem>
    );
  }
}

export default CollapseItem;
